/**
*	Cvio - Resume/CV Template (HTML)
*	Version: 1.1
*	Author: beshleyua
*	Author URL: http://themeforest.net/user/beshleyua
*	Copyright © Cvio by beshleyua. All Rights Reserved.
**/

/* TABLE OF CONTENTS
	- Header
	- Section
	- Started
	- About
	- Resume
	- Skills
	- Service
	- Portfolio
	- Box Items
	- Contacts
	- Blog
	- Footer
	- Popups
*/

/* - Header */
.header {
  padding: 50px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -moz-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -webkit-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -o-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
}

.header.fixed {
  padding-top: 30px;
  padding-bottom: 30px;
  top: 0;
  background: #101010;
}

.header:after {
  content: "";
  position: relative;
  display: block;
  clear: both;
}

.header .head-top {
  position: relative;
}

.header .menu-btn {
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 20px;
}

.header .menu-btn:before,
.header .menu-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.header .menu-btn:after {
  top: auto;
  bottom: 0;
}

.header .menu-btn span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  margin-top: -1px;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.header .logo {
  position: absolute;
  overflow: hidden;
  top: 50%;
  width: 150px;
  font-size: 16px;
  line-height: 2.5;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.header .logo img {
  max-width: 100%;
  /* width: auto; */
  height: auto;
  display: block;
}

.header .logo a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  display: block;
}

.header .logo strong {
  color: #fff;
}

.header .top-menu {
  text-align: right;
}

.header .top-menu .menu-top-menu-container {
  display: inline-block;
}

.header .top-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header .top-menu ul li {
  margin-left: 40px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.header .top-menu ul li a {
  position: relative;
  overflow: hidden;
  display: block;
  font-size: 1em;
  line-height: 2.5;
  color: #fff;
  font-weight: 600;
  opacity: 0.5;
  text-decoration: none;
  transition: color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -moz-transition: color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -webkit-transition: color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -o-transition: color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
}

.header .top-menu ul li:hover > .sub-menu,
.header .top-menu ul li:hover > .children {
  opacity: 1;
  pointer-events: auto;
}

.header .top-menu ul li:hover > a,
.header .top-menu ul li.current-menu-item > a {
  opacity: 1;
}

.header .top-menu ul li.menu-item-has-children > a .mask-lnk:after {
  content: "\f078";
  margin-left: 5px;
  position: relative;
  top: 0;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.8em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.header .top-menu-nav .sub-menu,
.header .top-menu-nav .children {
  position: absolute;
  left: 0;
  top: 40px;
  padding: 10px 0;
  width: 190px;
  text-align: left;
  background: #1e1e1e;
  z-index: 50;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
  -khtml-box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
}

.header .top-menu ul li:last-child .sub-menu,
.header .top-menu-nav .children {
  right: 0;
  left: auto;
}

.header .top-menu-nav .sub-menu:before,
.header .top-menu-nav .children:before {
  content: "";
  position: absolute;
  left: 10px;
  top: -12px;
  border: 6px solid transparent;
  border-bottom: 6px solid #1e1e1e;
}

.header .top-menu ul li:last-child .sub-menu:before,
.header .top-menu-nav .children:before {
  right: 10px;
  left: auto;
}

.header .top-menu-nav .sub-menu li,
.header .top-menu-nav .children li {
  margin-left: 0;
  padding: 8px 20px;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  height: auto;
}

.header .top-menu-nav .sub-menu li:last-child,
.header .top-menu-nav .children li:last-child {
  border-bottom: none;
}

.header .top-menu-nav .sub-menu li a,
.header .top-menu-nav .children li a {
  margin-top: 0;
  display: block;
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
}

.header .top-menu-nav .sub-menu li .sub-menu,
.header .top-menu-nav .children li .children {
  left: 100%;
  top: -10px;
}

.header .top-menu-nav .sub-menu li .sub-menu:before,
.header .top-menu-nav .children li .children:before {
  content: "";
  position: absolute;
  left: -12px;
  top: 16px;
  border: 6px solid transparent;
  border-right: 6px solid #fff;
  border-bottom: 6px solid transparent;
}

.background-enabled .header .top-menu ul li.current-menu-item > a {
  opacity: 1;
}

.hover-masks .mask-lnk,
.hover-masks-logo .mask-lnk {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-transition: opacity 0.7s cubic-bezier(0.165, 0.85, 0.45, 1),
    color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1),
    transform 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  transition: opacity 0.7s cubic-bezier(0.165, 0.85, 0.45, 1),
    color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1),
    transform 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
}

.hover-masks .mask-lnk.mask-lnk-hover,
.hover-masks-logo .mask-lnk.mask-lnk-hover {
  position: absolute;
  opacity: 0;
  transform: translateY(32px);
  -webkit-transform: translateY(32px);
  -moz-transform: translateY(32px);
  -o-transform: translateY(32px);
}

.hover-masks a:hover .mask-lnk,
.hover-masks-logo a:hover .mask-lnk {
  opacity: 1;
  transform: translateY(-32px);
  -webkit-transform: translateY(-32px);
  -moz-transform: translateY(-32px);
  -o-transform: translateY(-32px);
}

.hover-masks a:hover .mask-lnk.mask-lnk-hover,
.hover-masks-logo a:hover .mask-lnk.mask-lnk-hover {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
}

/* header active */
.header.active {
  padding-top: 4vw;
  top: 0;
  overflow: auto;
  height: 100%;
  background: #101010;
  -webkit-overflow-scrolling: touch;
}

.header.active .menu-btn:before {
  opacity: 0;
}

.header.active .menu-btn:after {
  bottom: 9px !important;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.header.active .menu-btn span {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.header.active .top-menu {
  height: auto;
  opacity: 1;
  visibility: visible;
}

.header.active .top-menu ul li .sub-menu,
.header.active .top-menu ul li .children {
  display: block;
}

.header.active .top-menu ul > li > ul li a {
  line-height: 20px;
}

/* header tablet */
@media (max-width: 1199px) {
  .header .logo {
    position: relative;
    top: 0;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -o-transform: translateY(0%);
  }

  .header .menu-btn {
    display: block;
    transform: translateY(50%);
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -o-transform: translateY(50%);
  }

  .header .top-menu-nav {
    display: block;
  }

  .header .top-menu {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    float: none;
    width: auto;
    height: 0px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s cubic-bezier(0.165, 0.85, 0.45, 1);
    -moz-transition: opacity 0.35s cubic-bezier(0.165, 0.85, 0.45, 1);
    -webkit-transition: opacity 0.35s cubic-bezier(0.165, 0.85, 0.45, 1);
    -o-transition: opacity 0.35s cubic-bezier(0.165, 0.85, 0.45, 1);
  }

  .header.active .top-menu {
    margin-top: 20px;
  }

  .header .top-menu .menu-top-menu-container {
    display: block;
  }

  .header .top-menu ul li {
    margin-left: 0;
    display: block;
  }

  .header .top-menu-nav .sub-menu,
  .header .top-menu-nav .children {
    position: relative;
    left: 0;
    top: 0;
    margin: 20px 0;
    width: auto;
    text-align: center;
    pointer-events: none;
    display: block;
    opacity: 0;
    visibility: hidden;
  }

  .header.active .top-menu-nav .sub-menu,
  .header.active .top-menu-nav .children {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  .header .top-menu-nav .sub-menu:before,
  .header .top-menu-nav .children:before {
    margin-left: -6px;
    left: 50%;
  }

  .header .top-menu-nav .sub-menu li .sub-menu,
  .header .top-menu-nav .children li .children {
    left: 0;
    top: 0;
    margin: 0 -20px;
    background: #f4f4f4;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -khtml-box-shadow: none;
  }

  .header .top-menu-nav .sub-menu li .sub-menu:before,
  .header .top-menu-nav .children li .children:before {
    left: 50%;
    top: -12px;
    border: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
  }

  .header .top-menu ul li:last-child .sub-menu:before,
  .header .top-menu-nav .children:before {
    right: 50%;
    left: auto;
    margin-left: 0;
    margin-right: -6px;
  }

  .header .menu-btn {
    display: block;
    float: right;
  }
}

/* header mobile */
@media (max-width: 720px) {
  .header {
    margin: 0;
    padding: 0 30px;
    top: 30px;
  }

  .header .logo {
    padding: 0;
    max-width: 240px;
  }

  .header.active .top-menu {
    padding-bottom: 30px;
  }

  .wp-block-media-text {
    display: block;
  }

  .wp-block-media-text .wp-block-media-text__content {
    padding: 0;
  }
}

/* - Section */
.section {
  position: relative;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 12;
}
/* 
.section:first-child {
  padding-top: 150px;
} */

.section .content {
  position: relative;
}

.section .content .portfolio-cols .title {
  margin-left: 0;
}

.section .content-box {
  margin-right: -1px;
  padding: 35px;
  font-size: 16px;
  background: #101010;
  border: 1px solid rgba(255, 255, 255, 0.07);
}

.section .content-box .single-post-text > *:first-child {
  margin-top: 0;
}

.section .title {
  position: relative;
  margin: 0 0 35px 35px;
}

.section .title .title_inner {
  display: inline-block;
  vertical-align: top;
  position: relative;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

/* section tablet */
@media (max-width: 1199px) {
  .section {
    padding-left: 50px;
    padding-right: 50px;
  }
}

/* section mobile */
@media (max-width: 720px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .section .title {
    margin: 0 0 30px 0;
  }
}

/* - Started */
.section.started {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
  z-index: 11;
}

.section.started.personal {
  z-index: 12;
}

.section.started .started-content .logo {
  position: relative;
  display: inline-block;
  top: -100px;
  left: -300px;
  width: 740px;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-clip-path: polygon(31% 0, 100% 25%, 61% 100%, 0 93%);
  clip-path: polygon(31% 0, 100% 25%, 61% 100%, 0 93%);
}

.section.started .started-content .h-title {
  margin: 0;
  position: absolute;
  left: calc(20% + 35px);
  bottom: 50px;
  max-width: 560px;
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  word-break: break-word;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.section.started .started-content .h-title strong {
  color: #4bffa5;
}

.section.started .started-content .h-subtitles {
  font-size: 38px;
  height: 38px;
  opacity: 0;
}

.section.started .started-content .h-subtitles.ready {
  opacity: 1;
}

.section.started .started-content .h-subtitle,
.section.started .started-content .typed-subtitle,
.section.started .started-content .typed-bread {
  margin-top: -45px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  display: block;
  font-size: 90px;
  line-height: 90px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.section.started .started-content .h-subtitle strong,
.section.started .started-content .typed-subtitle strong {
  color: #4bffa5;
}

.section.started.layout-creative {
  margin: 0 auto;
  margin-bottom: 0;
  padding-top: 20%;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 70px;
  width: auto;
  height: auto !important;
}

.section.started.layout-creative .started-content {
  padding-left: 0;
  padding-right: 0;
  display: block;
  text-align: left;
}

.section.started.layout-creative .started-content .h-title {
  margin: 0 0 5px 33px;
  padding-right: 0;
  position: relative;
  left: 0;
  top: 0;
  bottom: auto;
  max-width: 800px;
  font-size: 44px;
  font-family: "Roboto";
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0em;
}

.section.started.layout-creative .started-content .h-subtitle,
.section.started.layout-creative .started-content .typed-subtitle,
.section.started.layout-creative .started-content .typed-bread,
.section.started.layout-creative .started-content .typed-cursor {
  margin-top: 0;
  margin-left: 35px;
  position: relative;
  font-size: 18px;
  line-height: 40px;
  text-align: left;
  color: #999;
}

.section.started.layout-creative .started-content .h-subtitle,
.section.started.layout-creative .started-content .typed-subtitle {
  top: 0;
}

.section.started.layout-creative .started-content .h-subtitle p {
  font-size: 18px;
  line-height: 40px;
}

.section.started.personal {
  text-align: center;
}

.section.started.personal .started-content .h-title {
  margin-left: -85px;
  margin-top: 85px;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  width: 560px;
  text-align: left;
}

.section.started.personal .started-content .typed-subtitle,
.section.started.personal .started-content .typed-bread {
  margin-top: 0;
  top: auto;
  bottom: 50px;
  left: 20%;
  line-height: 74px;
  text-align: left;
}

.section.started.section-title .started-content .h-title {
  margin-top: 0;
  padding-right: 0;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 100%;
  font-size: 7vw;
  line-height: 7vw;
  font-weight: 600;
  text-align: center;
}

.section.started.section-title .started-content .h-subtitles {
  height: auto;
}

.section.started.section-title .started-content .h-subtitle,
.section.started.section-title .started-content .typed-subtitle,
.section.started.section-title .started-content .typed-bread {
  margin: 0;
  padding-left: 35px;
  position: absolute;
  left: 20%;
  top: auto;
  bottom: 50px;
  width: auto;
  max-width: 560px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
}

.section.started.section-title .started-content .h-subtitle p {
  margin: 0;
  font-size: 20px;
  line-height: 1.5;
}

.section.started .mouse_btn {
  font-size: 24px;
  color: #4bffa5;
  text-align: center;
  position: absolute;
  width: 20px;
  height: 40px;
  left: 50%;
  bottom: 7%;
  margin-left: -10px;
  z-index: 2;
}

.section.started .mouse_btn .icon {
  position: relative;
  top: 0px;
  animation: mouse-anim 1s ease-out 0s infinite;
}

.section.started .swiper-nav {
  position: absolute;
  left: calc(20% + 35px);
  bottom: 120px;
  width: 66px;
  height: 24px;
  z-index: 3;
}

.section.started .swiper-nav .swiper-button-prev,
.section.started .swiper-nav .swiper-button-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background: none;
  font-size: 22px;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.section.started + .section,
.section.started.layout-creative + .section {
  margin-top: 70px;
}

.section.started.section-title .started-content .typed-bread,
.section.started.layout-creative .started-content .typed-bread,
.background-enabled
  .section.started.section-title
  .started-content
  .typed-bread,
.background-enabled
  .section.started.layout-creative
  .started-content
  .typed-bread {
  top: auto;
  font-size: 16px;
  line-height: 1.9;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  opacity: 1;
}

.section.started.section-title .started-content .typed-bread a,
.section.started.layout-creative .started-content .typed-bread a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  opacity: 1;
}

.section.started .started-content .typed-cursor,
.section.started.layout-creative .mouse_btn,
.section.started.layout-post-creative .mouse_btn {
  display: none !important;
}

.section.started .swiper-button-prev,
.section.started .swiper-container-rtl .swiper-button-next {
  left: 0;
  right: auto;
}

.section.started .swiper-button-next,
.section.started .swiper-container-rtl .swiper-button-prev {
  left: auto;
  right: 0;
}

.started-carousel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.started-carousel .swiper-container,
.started-carousel .swiper-wrapper {
  height: 100%;
}

.started-carousel .swiper-slide {
  opacity: 1 !important;
}

.started-carousel .swiper-slide.first {
  z-index: 2;
}

.started-carousel .main-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  clip-path: polygon(
    0 0,
    20% 0,
    20% 0,
    40% 0,
    40% 0,
    60% 0,
    60% 0,
    80% 0,
    80% 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    80% 100%,
    80% 100%,
    60% 100%,
    60% 100%,
    40% 100%,
    40% 100%,
    20% 100%,
    20% 100%,
    0 100%
  );
  -webkit-clip-path: polygon(
    0 0,
    20% 0,
    20% 0,
    40% 0,
    40% 0,
    60% 0,
    60% 0,
    80% 0,
    80% 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    80% 100%,
    80% 100%,
    60% 100%,
    60% 100%,
    40% 100%,
    40% 100%,
    20% 100%,
    20% 100%,
    0 100%
  );
  transition: 1s all cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -moz-transition: 1s all cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -webkit-transition: 1s all cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -o-transition: 1s all cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
}

.started-carousel .swiper-slide.swiper-clip-active .main-img {
  clip-path: polygon(
    0 0,
    20% 0,
    20% 100%,
    40% 100%,
    40% 0,
    60% 0,
    60% 100%,
    80% 100%,
    80% 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 0%,
    80% 0%,
    80% 100%,
    60% 100%,
    60% 0%,
    40% 0%,
    40% 100%,
    20% 100%,
    20% 0%,
    0 0%
  );
  -webkit-clip-path: polygon(
    0 0,
    20% 0,
    20% 100%,
    40% 100%,
    40% 0,
    60% 0,
    60% 100%,
    80% 100%,
    80% 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 0%,
    80% 0%,
    80% 100%,
    60% 100%,
    60% 0%,
    40% 0%,
    40% 100%,
    20% 100%,
    20% 0%,
    0 0%
  );
}

.section.started .mouse_btn {
  left: auto;
  right: calc(20% + 35px);
  margin-left: 0;
}

/* section started desktop */
@media (min-width: 1200px) {
  .section.started .started-content .logo {
    top: -11.5vh;
    left: -18.5vw;
    width: 56vw;
    height: 80vh;
  }
  .section.started.personal .started-content .h-title {
    margin-left: 0;
    margin-top: 0;
    left: 50vw;
    top: 60vh;
    bottom: auto;
  }
}

/* section started tablet */
@media (max-width: 1199px) {
  .section.started.layout-creative,
  .section.started.layout-post-creative {
    padding-top: 35vw;
    padding-left: 50px;
    padding-right: 50px;
  }

  .section.started .started-content .h-title {
    left: 50px;
  }

  .section.started .started-content .h-title br {
    display: none;
  }

  .section.started .started-content .h-subtitle,
  .section.started .started-content .typed-subtitle,
  .section.started .started-content .typed-bread {
    margin-top: -35px;
    font-size: 60px;
    line-height: 70px;
  }

  .section.started.section-title .started-content .h-subtitle,
  .section.started.section-title .started-content .typed-subtitle,
  .section.started.section-title .started-content .typed-bread {
    padding-left: 0;
    left: 50px;
  }

  .section.started.personal .started-content .typed-subtitle,
  .section.started.personal .started-content .typed-bread {
    left: 50px;
    width: auto;
  }

  .section.started .swiper-nav {
    left: 50px;
  }

  .section.started .started-content .logo {
    top: -50px;
    left: -150px;
    width: 640px;
    height: 560px;
  }

  .section.started.personal .started-content .h-title {
    margin-left: 15px;
    margin-top: 115px;
    width: 320px;
  }

  .section.started.layout-creative .started-content .h-title,
  .section.started.layout-creative .started-content .h-subtitle,
  .section.started.layout-creative .started-content .typed-subtitle,
  .section.started.layout-creative .started-content .typed-bread,
  .section.started.layout-creative .started-content .typed-cursor {
    margin-left: 0;
  }

  .section.started .mouse_btn {
    left: auto;
    right: 120px;
    margin-left: 0;
  }
}

/* section started mobile */
@media (max-width: 720px) {
  .section.started {
    padding: 0 30px;
  }

  .section.started.layout-creative,
  .section.started.layout-post-creative {
    padding-top: 50vw;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }

  .section.started .started-content .h-title {
    left: 0;
    bottom: 30px;
    padding-right: 50px;
    font-size: 17px;
  }

  .section.started .started-content .h-subtitle,
  .section.started .started-content .typed-subtitle,
  .section.started .started-content .typed-bread {
    margin-top: -20px;
    font-size: 30px;
    line-height: 40px;
  }

  .section.started.section-title .started-content .h-title {
    font-size: 10vw;
    line-height: 10vw;
  }

  .section.started.section-title .started-content .typed-bread,
  .section.started.layout-creative .started-content .typed-bread,
  .background-enabled
    .section.started.section-title
    .started-content
    .typed-bread,
  .background-enabled
    .section.started.layout-creative
    .started-content
    .typed-bread {
    font-size: 14px;
  }

  .section.started.layout-creative .started-content .h-title,
  .section.started.layout-post-creative .started-content .h-title {
    font-size: 23px;
  }

  .section.started .started-content .logo {
    position: absolute;
    top: 0;
    left: -30px;
    width: 110vw;
    height: 62vh;
    -webkit-clip-path: polygon(25% 0, 100% 25%, 80% 100%, 0 85%);
    clip-path: polygon(25% 0, 100% 25%, 80% 100%, 0 85%);
  }

  .section.started.personal .started-content .h-title {
    left: 0;
    top: 64vh;
    bottom: auto;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
  }

  .section.started.personal .started-content .typed-subtitle,
  .section.started.personal .started-content .typed-bread {
    left: 0;
    bottom: 30px;
    padding-right: 50px;
    font-size: 20px;
    line-height: 30px;
  }

  .section.started .swiper-nav {
    left: 30px;
  }

  .section.started .mouse_btn {
    right: 50%;
    margin-left: 0;
    margin-right: -10px;
    bottom: 150px;
  }

  .section.started.section-title .started-content .h-subtitle,
  .section.started.section-title .started-content .typed-subtitle,
  .section.started.section-title .started-content .typed-bread {
    left: 0;
  }

  .section.started + .section,
  .section.started.layout-creative + .section {
    margin-top: 0;
  }
}

/* section started mobile */
@media (max-width: 340px) {
  .section.started .swiper-nav {
    bottom: 145px;
  }
}

/* section started mobile landscape */
@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 1199px) {
  .section.started .started-content .logo {
    position: absolute;
    left: -30px;
    right: -30px;
    top: 0;
    width: auto;
    height: 100%;
    -webkit-clip-path: none;
    clip-path: none;
  }

  .section.started.personal .started-content .h-title {
    top: auto;
    left: 50px;
    bottom: 50px;
    width: auto;
    margin-left: 0;
    margin-top: 0;
    margin-right: 50px;
  }

  .section.started.personal .started-content .typed-subtitle,
  .section.started.personal .started-content .typed-bread {
    display: none;
  }

  .section.started .swiper-nav {
    bottom: 120px;
  }
}

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 670px) {
  .section.started.personal .started-content .h-title {
    left: 0;
    bottom: 30px;
    margin-right: 30px;
  }

  .section.started .swiper-nav {
    bottom: 100px;
  }
}

/* - About */
.section.about .image {
  float: left;
  width: 160px;
  font-size: 0;
}

.section.about .image img {
  width: 100%;
  height: auto;
}

.section.about .desc {
  margin-left: 195px;
}

.section.about .desc p {
  word-break: break-word;
}

.section.about .desc p:first-child {
  margin-top: 0;
}

.info-list {
  margin-bottom: 20px;
}

.info-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.info-list ul li {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin: 0 0 6px 0;
}

.info-list ul li strong {
  font-weight: 400;
  color: #4bffa5;
}

/* section about fullhd */
@media (min-width: 1700px) {
  .info-list ul li {
    width: 32%;
  }
}

/* section about tablet */
@media (max-width: 1199px) {
  .section.about .image {
    width: 120px;
    margin-right: 0;
    margin-bottom: 25px;
  }

  .section.about .desc {
    margin-left: 145px;
  }
}

/* section about mobile */
@media (max-width: 720px) {
  .section.about .image {
    margin: 0 auto 20px auto;
    float: none;
    width: 100px;
    text-align: center;
  }

  .section.about .desc {
    margin-left: 0;
    text-align: center;
  }

  .info-list ul li {
    width: 100%;
  }
}

/* - Resume */
.section .col:nth-child(odd) {
  padding-right: 35px;
}

.section .col:nth-child(even) {
  padding-left: 35px;
}

.section .col:nth-child(even) .content-box {
  margin-right: -1px;
}

.resume-items .resume-item {
  position: relative;
  margin: 70px 0 0 0;
}

.resume-items .resume-item:first-child {
  margin: 0;
}

.resume-items .resume-item .date {
  position: relative;
  margin: 0 0 5px 0;
  display: inline-block;
  font-size: 12px;
  color: #999;
}

.resume-items .resume-item .name {
  margin: 0 0 15px 0;
  color: #eee;
  font-weight: 500;
}

.resume-items .resume-item.active .date {
  color: #4bffa5;
  font-weight: 600;
}

/* section resume tablet */
@media (max-width: 1199px) {
  .section .col:nth-child(odd) {
    padding-right: 20px;
  }

  .section .col:nth-child(even) {
    padding-left: 20px;
  }

  .resume-items .resume-item {
    margin: 40px 0 0 0;
  }
}

/* section resume mobile */
@media (max-width: 720px) {
  .section .col:nth-child(odd) {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .section .col:nth-child(even) {
    padding-left: 0;
  }
}

/* - Skills */
.skills ul {
  margin: -35px 0 0 0;
  padding: 0;
  list-style: none;
  font-size: 0;
}

.skills ul li {
  position: relative;
  padding: 35px 0 0 0;
}

.skills ul li:after {
  display: none;
}

.skills ul li:last-child {
  padding-bottom: 0;
}

.skills ul li .name {
  margin: 0 0 10px 0;
  position: relative;
  font-size: 16px;
  color: #eee;
  font-weight: 500;
}

.skills ul li .progress {
  display: block;
  height: 4px;
  position: relative;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.skills ul li .progress .percentage {
  left: 0;
  /* top: 0; */
  position: absolute;
  height: 4px;
  width: 0%;
  background: #4bffa5;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.skills ul li .progress .percentage .percent {
  position: absolute;
  top: -34px;
  right: 0;
  font-size: 16px;
  font-weight: 400;
}

.skills.list ul {
  margin: 0 -35px;
}

.skills.list ul li {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 35px;
  padding-right: 35px;
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.skills.list ul li .name {
  padding-left: 35px;
  position: relative;
}

.skills.list ul li .name:before {
  content: "\f00c";
  position: absolute;
  left: 0;
  top: 3px;
  display: inline-block;
  color: #4bffa5;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.skills.list ul li .progress {
  display: none;
}

.skills.dotted ul {
  margin-left: -35px;
  margin-right: -35px;
}

.skills.dotted ul li {
  padding-left: 35px;
  padding-right: 35px;
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.skills.dotted ul li .progress {
  height: 14px;
  background: transparent !important;
}

.skills.dotted ul li .progress .percentage {
  position: relative;
  overflow: hidden;
  top: 0;
  height: 14px;
  background: transparent !important;
  z-index: 2;
}

.skills.dotted ul li .progress .dg,
.skills.dotted ul li .progress .da {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 14px;
}

.skills.dotted ul li .progress .dg span,
.skills.dotted ul li .progress .da span {
  display: block;
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  -khtml-border-radius: 14px;
}

.skills.dotted ul li .progress .da {
  top: 0;
}

.skills.dotted ul li .progress .da span {
  background: #4bffa5;
}

.skills.circles ul li {
  margin-top: 35px;
  padding: 0 0 60px 0;
  display: inline-block;
  vertical-align: top;
  width: 25%;
}

.skills.circles ul li:last-child {
  padding-bottom: 40px;
}

.skills.circles ul li .name {
  margin: 0 0 0 -45px;
  position: absolute;
  top: 105px;
  left: 50%;
  width: 90px;
  text-align: center;
}

.skills.circles ul li .progress {
  margin: 0 auto;
  position: relative;
  font-size: 90px;
  width: 90px;
  height: 90px;
  background: #282828;
  border-radius: 90px;
  -moz-border-radius: 90px;
  -webkit-border-radius: 90px;
  -khtml-border-radius: 90px;
}

.skills.circles ul li .progress:after {
  content: "";
  position: absolute;
  top: 0.09em;
  left: 0.09em;
  width: 0.82em;
  height: 0.82em;
  display: block;
  background-color: #101010;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
}

.skills.circles ul li .progress span {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 90px;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
}

.skills.circles ul li .progress .percentage {
  display: none;
}

.skills.circles ul li .progress .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}

.skills.circles .progress.p51 .slice,
.skills.circles .progress.p52 .slice,
.skills.circles .progress.p53 .slice,
.skills.circles .progress.p54 .slice,
.skills.circles .progress.p55 .slice,
.skills.circles .progress.p56 .slice,
.skills.circles .progress.p57 .slice,
.skills.circles .progress.p58 .slice,
.skills.circles .progress.p59 .slice,
.skills.circles .progress.p60 .slice,
.skills.circles .progress.p61 .slice,
.skills.circles .progress.p62 .slice,
.skills.circles .progress.p63 .slice,
.skills.circles .progress.p64 .slice,
.skills.circles .progress.p65 .slice,
.skills.circles .progress.p66 .slice,
.skills.circles .progress.p67 .slice,
.skills.circles .progress.p68 .slice,
.skills.circles .progress.p69 .slice,
.skills.circles .progress.p70 .slice,
.skills.circles .progress.p71 .slice,
.skills.circles .progress.p72 .slice,
.skills.circles .progress.p73 .slice,
.skills.circles .progress.p74 .slice,
.skills.circles .progress.p75 .slice,
.skills.circles .progress.p76 .slice,
.skills.circles .progress.p77 .slice,
.skills.circles .progress.p78 .slice,
.skills.circles .progress.p79 .slice,
.skills.circles .progress.p80 .slice,
.skills.circles .progress.p81 .slice,
.skills.circles .progress.p82 .slice,
.skills.circles .progress.p83 .slice,
.skills.circles .progress.p84 .slice,
.skills.circles .progress.p85 .slice,
.skills.circles .progress.p86 .slice,
.skills.circles .progress.p87 .slice,
.skills.circles .progress.p88 .slice,
.skills.circles .progress.p89 .slice,
.skills.circles .progress.p90 .slice,
.skills.circles .progress.p91 .slice,
.skills.circles .progress.p92 .slice,
.skills.circles .progress.p93 .slice,
.skills.circles .progress.p94 .slice,
.skills.circles .progress.p95 .slice,
.skills.circles .progress.p96 .slice,
.skills.circles .progress.p97 .slice,
.skills.circles .progress.p98 .slice,
.skills.circles .progress.p99 .slice,
.skills.circles .progress.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.skills.circles .progress .bar,
.skills.circles .progress.p51 .fill,
.skills.circles .progress.p52 .fill,
.skills.circles .progress.p53 .fill,
.skills.circles .progress.p54 .fill,
.skills.circles .progress.p55 .fill,
.skills.circles .progress.p56 .fill,
.skills.circles .progress.p57 .fill,
.skills.circles .progress.p58 .fill,
.skills.circles .progress.p59 .fill,
.skills.circles .progress.p60 .fill,
.skills.circles .progress.p61 .fill,
.skills.circles .progress.p62 .fill,
.skills.circles .progress.p63 .fill,
.skills.circles .progress.p64 .fill,
.skills.circles .progress.p65 .fill,
.skills.circles .progress.p66 .fill,
.skills.circles .progress.p67 .fill,
.skills.circles .progress.p68 .fill,
.skills.circles .progress.p69 .fill,
.skills.circles .progress.p70 .fill,
.skills.circles .progress.p71 .fill,
.skills.circles .progress.p72 .fill,
.skills.circles .progress.p73 .fill,
.skills.circles .progress.p74 .fill,
.skills.circles .progress.p75 .fill,
.skills.circles .progress.p76 .fill,
.skills.circles .progress.p77 .fill,
.skills.circles .progress.p78 .fill,
.skills.circles .progress.p79 .fill,
.skills.circles .progress.p80 .fill,
.skills.circles .progress.p81 .fill,
.skills.circles .progress.p82 .fill,
.skills.circles .progress.p83 .fill,
.skills.circles .progress.p84 .fill,
.skills.circles .progress.p85 .fill,
.skills.circles .progress.p86 .fill,
.skills.circles .progress.p87 .fill,
.skills.circles .progress.p88 .fill,
.skills.circles .progress.p89 .fill,
.skills.circles .progress.p90 .fill,
.skills.circles .progress.p91 .fill,
.skills.circles .progress.p92 .fill,
.skills.circles .progress.p93 .fill,
.skills.circles .progress.p94 .fill,
.skills.circles .progress.p95 .fill,
.skills.circles .progress.p96 .fill,
.skills.circles .progress.p97 .fill,
.skills.circles .progress.p98 .fill,
.skills.circles .progress.p99 .fill,
.skills.circles .progress.p100 .fill {
  position: absolute;
  width: 0.82em;
  height: 0.82em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border: 0.09em solid #4bffa5;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
}

.skills.circles .progress.p51 .bar:after,
.skills.circles .progress.p51 .fill,
.skills.circles .progress.p52 .bar:after,
.skills.circles .progress.p52 .fill,
.skills.circles .progress.p53 .bar:after,
.skills.circles .progress.p53 .fill,
.skills.circles .progress.p54 .bar:after,
.skills.circles .progress.p54 .fill,
.skills.circles .progress.p55 .bar:after,
.skills.circles .progress.p55 .fill,
.skills.circles .progress.p56 .bar:after,
.skills.circles .progress.p56 .fill,
.skills.circles .progress.p57 .bar:after,
.skills.circles .progress.p57 .fill,
.skills.circles .progress.p58 .bar:after,
.skills.circles .progress.p58 .fill,
.skills.circles .progress.p59 .bar:after,
.skills.circles .progress.p59 .fill,
.skills.circles .progress.p60 .bar:after,
.skills.circles .progress.p60 .fill,
.skills.circles .progress.p61 .bar:after,
.skills.circles .progress.p61 .fill,
.skills.circles .progress.p62 .bar:after,
.skills.circles .progress.p62 .fill,
.skills.circles .progress.p63 .bar:after,
.skills.circles .progress.p63 .fill,
.skills.circles .progress.p64 .bar:after,
.skills.circles .progress.p64 .fill,
.skills.circles .progress.p65 .bar:after,
.skills.circles .progress.p65 .fill,
.skills.circles .progress.p66 .bar:after,
.skills.circles .progress.p66 .fill,
.skills.circles .progress.p67 .bar:after,
.skills.circles .progress.p67 .fill,
.skills.circles .progress.p68 .bar:after,
.skills.circles .progress.p68 .fill,
.skills.circles .progress.p69 .bar:after,
.skills.circles .progress.p69 .fill,
.skills.circles .progress.p70 .bar:after,
.skills.circles .progress.p70 .fill,
.skills.circles .progress.p71 .bar:after,
.skills.circles .progress.p71 .fill,
.skills.circles .progress.p72 .bar:after,
.skills.circles .progress.p72 .fill,
.skills.circles .progress.p73 .bar:after,
.skills.circles .progress.p73 .fill,
.skills.circles .progress.p74 .bar:after,
.skills.circles .progress.p74 .fill,
.skills.circles .progress.p75 .bar:after,
.skills.circles .progress.p75 .fill,
.skills.circles .progress.p76 .bar:after,
.skills.circles .progress.p76 .fill,
.skills.circles .progress.p77 .bar:after,
.skills.circles .progress.p77 .fill,
.skills.circles .progress.p78 .bar:after,
.skills.circles .progress.p78 .fill,
.skills.circles .progress.p79 .bar:after,
.skills.circles .progress.p79 .fill,
.skills.circles .progress.p80 .bar:after,
.skills.circles .progress.p80 .fill,
.skills.circles .progress.p81 .bar:after,
.skills.circles .progress.p81 .fill,
.skills.circles .progress.p82 .bar:after,
.skills.circles .progress.p82 .fill,
.skills.circles .progress.p83 .bar:after,
.skills.circles .progress.p83 .fill,
.skills.circles .progress.p84 .bar:after,
.skills.circles .progress.p84 .fill,
.skills.circles .progress.p85 .bar:after,
.skills.circles .progress.p85 .fill,
.skills.circles .progress.p86 .bar:after,
.skills.circles .progress.p86 .fill,
.skills.circles .progress.p87 .bar:after,
.skills.circles .progress.p87 .fill,
.skills.circles .progress.p88 .bar:after,
.skills.circles .progress.p88 .fill,
.skills.circles .progress.p89 .bar:after,
.skills.circles .progress.p89 .fill,
.skills.circles .progress.p90 .bar:after,
.skills.circles .progress.p90 .fill,
.skills.circles .progress.p91 .bar:after,
.skills.circles .progress.p91 .fill,
.skills.circles .progress.p92 .bar:after,
.skills.circles .progress.p92 .fill,
.skills.circles .progress.p93 .bar:after,
.skills.circles .progress.p93 .fill,
.skills.circles .progress.p94 .bar:after,
.skills.circles .progress.p94 .fill,
.skills.circles .progress.p95 .bar:after,
.skills.circles .progress.p95 .fill,
.skills.circles .progress.p96 .bar:after,
.skills.circles .progress.p96 .fill,
.skills.circles .progress.p97 .bar:after,
.skills.circles .progress.p97 .fill,
.skills.circles .progress.p98 .bar:after,
.skills.circles .progress.p98 .fill,
.skills.circles .progress.p99 .bar:after,
.skills.circles .progress.p99 .fill,
.skills.circles .progress.p100 .bar:after,
.skills.circles .progress.p100 .fill {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.skills.circles .progress *,
.skills.circles .progress *:before,
.skills.circles .progress *:after {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.skills.circles .progress.p1 .bar {
  transform: rotate(3.6deg);
  -webkit-transform: rotate(3.6deg);
  -moz-transform: rotate(3.6deg);
  -o-transform: rotate(3.6deg);
}

.skills.circles .progress.p2 .bar {
  transform: rotate(7.2deg);
  -webkit-transform: rotate(7.2deg);
  -moz-transform: rotate(7.2deg);
  -o-transform: rotate(7.2deg);
}

.skills.circles .progress.p3 .bar {
  transform: rotate(10.8deg);
  -webkit-transform: rotate(10.8deg);
  -moz-transform: rotate(10.8deg);
  -o-transform: rotate(10.8deg);
}

.skills.circles .progress.p4 .bar {
  transform: rotate(14.4deg);
  -webkit-transform: rotate(14.4deg);
  -moz-transform: rotate(14.4deg);
  -o-transform: rotate(14.4deg);
}

.skills.circles .progress.p5 .bar {
  transform: rotate(18deg);
  -webkit-transform: rotate(18deg);
  -moz-transform: rotate(18deg);
  -o-transform: rotate(18deg);
}

.skills.circles .progress.p6 .bar {
  transform: rotate(21.6deg);
  -webkit-transform: rotate(21.6deg);
  -moz-transform: rotate(21.6deg);
  -o-transform: rotate(21.6deg);
}

.skills.circles .progress.p7 .bar {
  transform: rotate(25.2deg);
  -webkit-transform: rotate(25.2deg);
  -moz-transform: rotate(25.2deg);
  -o-transform: rotate(25.2deg);
}

.skills.circles .progress.p8 .bar {
  transform: rotate(28.8deg);
  -webkit-transform: rotate(28.8deg);
  -moz-transform: rotate(28.8deg);
  -o-transform: rotate(28.8deg);
}

.skills.circles .progress.p9 .bar {
  transform: rotate(32.4deg);
  -webkit-transform: rotate(32.4deg);
  -moz-transform: rotate(32.4deg);
  -o-transform: rotate(32.4deg);
}

.skills.circles .progress.p10 .bar {
  transform: rotate(36deg);
  -webkit-transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -o-transform: rotate(36deg);
}

.skills.circles .progress.p11 .bar {
  transform: rotate(39.6deg);
  -webkit-transform: rotate(39.6deg);
  -moz-transform: rotate(39.6deg);
  -o-transform: rotate(39.6deg);
}

.skills.circles .progress.p12 .bar {
  transform: rotate(43.2deg);
  -webkit-transform: rotate(43.2deg);
  -moz-transform: rotate(43.2deg);
  -o-transform: rotate(43.2deg);
}

.skills.circles .progress.p13 .bar {
  transform: rotate(46.8deg);
  -webkit-transform: rotate(46.8deg);
  -moz-transform: rotate(46.8deg);
  -o-transform: rotate(46.8deg);
}

.skills.circles .progress.p14 .bar {
  transform: rotate(50.4deg);
  -webkit-transform: rotate(50.4deg);
  -moz-transform: rotate(50.4deg);
  -o-transform: rotate(50.4deg);
}

.skills.circles .progress.p15 .bar {
  transform: rotate(54deg);
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -o-transform: rotate(54deg);
}

.skills.circles .progress.p16 .bar {
  transform: rotate(57.6deg);
  -webkit-transform: rotate(57.6deg);
  -moz-transform: rotate(57.6deg);
  -o-transform: rotate(57.6deg);
}

.skills.circles .progress.p17 .bar {
  transform: rotate(61.2deg);
  -webkit-transform: rotate(61.2deg);
  -moz-transform: rotate(61.2deg);
  -o-transform: rotate(61.2deg);
}

.skills.circles .progress.p18 .bar {
  transform: rotate(64.8deg);
  -webkit-transform: rotate(64.8deg);
  -moz-transform: rotate(64.8deg);
  -o-transform: rotate(64.8deg);
}

.skills.circles .progress.p19 .bar {
  transform: rotate(68.4deg);
  -webkit-transform: rotate(68.4deg);
  -moz-transform: rotate(68.4deg);
  -o-transform: rotate(68.4deg);
}

.skills.circles .progress.p20 .bar {
  transform: rotate(72deg);
  -webkit-transform: rotate(72deg);
  -moz-transform: rotate(72deg);
  -o-transform: rotate(72deg);
}

.skills.circles .progress.p21 .bar {
  transform: rotate(75.6deg);
  -webkit-transform: rotate(75.6deg);
  -moz-transform: rotate(75.6deg);
  -o-transform: rotate(75.6deg);
}

.skills.circles .progress.p22 .bar {
  transform: rotate(79.2deg);
  -webkit-transform: rotate(79.2deg);
  -moz-transform: rotate(79.2deg);
  -o-transform: rotate(79.2deg);
}

.skills.circles .progress.p23 .bar {
  transform: rotate(82.8deg);
  -webkit-transform: rotate(82.8deg);
  -moz-transform: rotate(82.8deg);
  -o-transform: rotate(82.8deg);
}

.skills.circles .progress.p24 .bar {
  transform: rotate(86.4deg);
  -webkit-transform: rotate(86.4deg);
  -moz-transform: rotate(86.4deg);
  -o-transform: rotate(86.4deg);
}

.skills.circles .progress.p25 .bar {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.skills.circles .progress.p26 .bar {
  transform: rotate(93.6deg);
  -webkit-transform: rotate(93.6deg);
  -moz-transform: rotate(93.6deg);
  -o-transform: rotate(93.6deg);
}

.skills.circles .progress.p27 .bar {
  transform: rotate(97.2deg);
  -webkit-transform: rotate(97.2deg);
  -moz-transform: rotate(97.2deg);
  -o-transform: rotate(97.2deg);
}

.skills.circles .progress.p28 .bar {
  transform: rotate(100.8deg);
  -webkit-transform: rotate(100.8deg);
  -moz-transform: rotate(100.8deg);
  -o-transform: rotate(100.8deg);
}

.skills.circles .progress.p29 .bar {
  transform: rotate(104.4deg);
  -webkit-transform: rotate(104.4deg);
  -moz-transform: rotate(104.4deg);
  -o-transform: rotate(104.4deg);
}

.skills.circles .progress.p30 .bar {
  transform: rotate(108deg);
  -webkit-transform: rotate(108deg);
  -moz-transform: rotate(108deg);
  -o-transform: rotate(108deg);
}

.skills.circles .progress.p31 .bar {
  transform: rotate(111.6deg);
  -webkit-transform: rotate(111.6deg);
  -moz-transform: rotate(111.6deg);
  -o-transform: rotate(111.6deg);
}

.skills.circles .progress.p32 .bar {
  transform: rotate(115.2deg);
  -webkit-transform: rotate(115.2deg);
  -moz-transform: rotate(115.2deg);
  -o-transform: rotate(115.2deg);
}

.skills.circles .progress.p33 .bar {
  transform: rotate(118.8deg);
  -webkit-transform: rotate(118.8deg);
  -moz-transform: rotate(118.8deg);
  -o-transform: rotate(118.8deg);
}

.skills.circles .progress.p34 .bar {
  transform: rotate(122.4deg);
  -webkit-transform: rotate(122.4deg);
  -moz-transform: rotate(122.4deg);
  -o-transform: rotate(122.4deg);
}

.skills.circles .progress.p35 .bar {
  transform: rotate(126deg);
  -webkit-transform: rotate(126deg);
  -moz-transform: rotate(126deg);
  -o-transform: rotate(126deg);
}

.skills.circles .progress.p36 .bar {
  transform: rotate(129.6deg);
  -webkit-transform: rotate(129.6deg);
  -moz-transform: rotate(129.6deg);
  -o-transform: rotate(129.6deg);
}

.skills.circles .progress.p37 .bar {
  transform: rotate(133.2deg);
  -webkit-transform: rotate(133.2deg);
  -moz-transform: rotate(133.2deg);
  -o-transform: rotate(133.2deg);
}

.skills.circles .progress.p38 .bar {
  transform: rotate(136.8deg);
  -webkit-transform: rotate(136.8deg);
  -moz-transform: rotate(136.8deg);
  -o-transform: rotate(136.8deg);
}

.skills.circles .progress.p39 .bar {
  transform: rotate(140.4deg);
  -webkit-transform: rotate(140.4deg);
  -moz-transform: rotate(140.4deg);
  -o-transform: rotate(140.4deg);
}

.skills.circles .progress.p40 .bar {
  transform: rotate(144deg);
  -webkit-transform: rotate(144deg);
  -moz-transform: rotate(144deg);
  -o-transform: rotate(144deg);
}

.skills.circles .progress.p41 .bar {
  transform: rotate(147.6deg);
  -webkit-transform: rotate(147.6deg);
  -moz-transform: rotate(147.6deg);
  -o-transform: rotate(147.6deg);
}

.skills.circles .progress.p42 .bar {
  transform: rotate(151.2deg);
  -webkit-transform: rotate(151.2deg);
  -moz-transform: rotate(151.2deg);
  -o-transform: rotate(151.2deg);
}

.skills.circles .progress.p43 .bar {
  transform: rotate(154.8deg);
  -webkit-transform: rotate(154.8deg);
  -moz-transform: rotate(154.8deg);
  -o-transform: rotate(154.8deg);
}

.skills.circles .progress.p44 .bar {
  transform: rotate(158.4deg);
  -webkit-transform: rotate(158.4deg);
  -moz-transform: rotate(158.4deg);
  -o-transform: rotate(158.4deg);
}

.skills.circles .progress.p45 .bar {
  transform: rotate(162deg);
  -webkit-transform: rotate(162deg);
  -moz-transform: rotate(162deg);
  -o-transform: rotate(162deg);
}

.skills.circles .progress.p46 .bar {
  transform: rotate(165.6deg);
  -webkit-transform: rotate(165.6deg);
  -moz-transform: rotate(165.6deg);
  -o-transform: rotate(165.6deg);
}

.skills.circles .progress.p47 .bar {
  transform: rotate(169.2deg);
  -webkit-transform: rotate(169.2deg);
  -moz-transform: rotate(169.2deg);
  -o-transform: rotate(169.2deg);
}

.skills.circles .progress.p48 .bar {
  transform: rotate(172.8deg);
  -webkit-transform: rotate(172.8deg);
  -moz-transform: rotate(172.8deg);
  -o-transform: rotate(172.8deg);
}

.skills.circles .progress.p49 .bar {
  transform: rotate(176.4deg);
  -webkit-transform: rotate(176.4deg);
  -moz-transform: rotate(176.4deg);
  -o-transform: rotate(176.4deg);
}

.skills.circles .progress.p50 .bar {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.skills.circles .progress.p51 .bar {
  transform: rotate(183.6deg);
  -webkit-transform: rotate(183.6deg);
  -moz-transform: rotate(183.6deg);
  -o-transform: rotate(183.6deg);
}

.skills.circles .progress.p52 .bar {
  transform: rotate(187.2deg);
  -webkit-transform: rotate(187.2deg);
  -moz-transform: rotate(187.2deg);
  -o-transform: rotate(187.2deg);
}

.skills.circles .progress.p53 .bar {
  transform: rotate(190.8deg);
  -webkit-transform: rotate(190.8deg);
  -moz-transform: rotate(190.8deg);
  -o-transform: rotate(190.8deg);
}

.skills.circles .progress.p54 .bar {
  transform: rotate(194.4deg);
  -webkit-transform: rotate(194.4deg);
  -moz-transform: rotate(194.4deg);
  -o-transform: rotate(194.4deg);
}

.skills.circles .progress.p55 .bar {
  transform: rotate(198deg);
  -webkit-transform: rotate(198deg);
  -moz-transform: rotate(198deg);
  -o-transform: rotate(198deg);
}

.skills.circles .progress.p56 .bar {
  transform: rotate(201.6deg);
  -webkit-transform: rotate(201.6deg);
  -moz-transform: rotate(201.6deg);
  -o-transform: rotate(201.6deg);
}

.skills.circles .progress.p57 .bar {
  transform: rotate(205.2deg);
  -webkit-transform: rotate(205.2deg);
  -moz-transform: rotate(205.2deg);
  -o-transform: rotate(205.2deg);
}

.skills.circles .progress.p58 .bar {
  transform: rotate(208.8deg);
  -webkit-transform: rotate(208.8deg);
  -moz-transform: rotate(208.8deg);
  -o-transform: rotate(208.8deg);
}

.skills.circles .progress.p59 .bar {
  transform: rotate(212.4deg);
  -webkit-transform: rotate(212.4deg);
  -moz-transform: rotate(212.4deg);
  -o-transform: rotate(212.4deg);
}

.skills.circles .progress.p60 .bar {
  transform: rotate(216deg);
  -webkit-transform: rotate(216deg);
  -moz-transform: rotate(216deg);
  -o-transform: rotate(216deg);
}

.skills.circles .progress.p61 .bar {
  transform: rotate(219.6deg);
  -webkit-transform: rotate(219.6deg);
  -moz-transform: rotate(219.6deg);
  -o-transform: rotate(219.6deg);
}

.skills.circles .progress.p62 .bar {
  transform: rotate(223.2deg);
  -webkit-transform: rotate(223.2deg);
  -moz-transform: rotate(223.2deg);
  -o-transform: rotate(223.2deg);
}

.skills.circles .progress.p63 .bar {
  transform: rotate(226.8deg);
  -webkit-transform: rotate(226.8deg);
  -moz-transform: rotate(226.8deg);
  -o-transform: rotate(226.8deg);
}

.skills.circles .progress.p64 .bar {
  transform: rotate(230.4deg);
  -webkit-transform: rotate(230.4deg);
  -moz-transform: rotate(230.4deg);
  -o-transform: rotate(230.4deg);
}

.skills.circles .progress.p65 .bar {
  transform: rotate(234deg);
  -webkit-transform: rotate(234deg);
  -moz-transform: rotate(234deg);
  -o-transform: rotate(234deg);
}

.skills.circles .progress.p66 .bar {
  transform: rotate(237.6deg);
  -webkit-transform: rotate(237.6deg);
  -moz-transform: rotate(237.6deg);
  -o-transform: rotate(237.6deg);
}

.skills.circles .progress.p67 .bar {
  transform: rotate(241.2deg);
  -webkit-transform: rotate(241.2deg);
  -moz-transform: rotate(241.2deg);
  -o-transform: rotate(241.2deg);
}

.skills.circles .progress.p68 .bar {
  transform: rotate(244.8deg);
  -webkit-transform: rotate(244.8deg);
  -moz-transform: rotate(244.8deg);
  -o-transform: rotate(244.8deg);
}

.skills.circles .progress.p69 .bar {
  transform: rotate(248.4deg);
  -webkit-transform: rotate(248.4deg);
  -moz-transform: rotate(248.4deg);
  -o-transform: rotate(248.4deg);
}

.skills.circles .progress.p70 .bar {
  transform: rotate(252deg);
  -webkit-transform: rotate(252deg);
  -moz-transform: rotate(252deg);
  -o-transform: rotate(252deg);
}

.skills.circles .progress.p71 .bar {
  transform: rotate(255.6deg);
  -webkit-transform: rotate(255.6deg);
  -moz-transform: rotate(255.6deg);
  -o-transform: rotate(255.6deg);
}

.skills.circles .progress.p72 .bar {
  transform: rotate(259.2deg);
  -webkit-transform: rotate(259.2deg);
  -moz-transform: rotate(259.2deg);
  -o-transform: rotate(259.2deg);
}

.skills.circles .progress.p73 .bar {
  transform: rotate(262.8deg);
  -webkit-transform: rotate(262.8deg);
  -moz-transform: rotate(262.8deg);
  -o-transform: rotate(262.8deg);
}

.skills.circles .progress.p74 .bar {
  transform: rotate(266.4deg);
  -webkit-transform: rotate(266.4deg);
  -moz-transform: rotate(266.4deg);
  -o-transform: rotate(266.4deg);
}

.skills.circles .progress.p75 .bar {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}

.skills.circles .progress.p76 .bar {
  transform: rotate(273.6deg);
  -webkit-transform: rotate(273.6deg);
  -moz-transform: rotate(273.6deg);
  -o-transform: rotate(273.6deg);
}

.skills.circles .progress.p77 .bar {
  transform: rotate(277.2deg);
  -webkit-transform: rotate(277.2deg);
  -moz-transform: rotate(277.2deg);
  -o-transform: rotate(277.2deg);
}

.skills.circles .progress.p78 .bar {
  transform: rotate(280.8deg);
  -webkit-transform: rotate(280.8deg);
  -moz-transform: rotate(280.8deg);
  -o-transform: rotate(280.8deg);
}

.skills.circles .progress.p79 .bar {
  transform: rotate(284.4deg);
  -webkit-transform: rotate(284.4deg);
  -moz-transform: rotate(284.4deg);
  -o-transform: rotate(284.4deg);
}

.skills.circles .progress.p80 .bar {
  transform: rotate(288deg);
  -webkit-transform: rotate(288deg);
  -moz-transform: rotate(288deg);
  -o-transform: rotate(288deg);
}

.skills.circles .progress.p81 .bar {
  transform: rotate(291.6deg);
  -webkit-transform: rotate(291.6deg);
  -moz-transform: rotate(291.6deg);
  -o-transform: rotate(291.6deg);
}

.skills.circles .progress.p82 .bar {
  transform: rotate(295.2deg);
  -webkit-transform: rotate(295.2deg);
  -moz-transform: rotate(295.2deg);
  -o-transform: rotate(295.2deg);
}

.skills.circles .progress.p83 .bar {
  transform: rotate(298.8deg);
  -webkit-transform: rotate(298.8deg);
  -moz-transform: rotate(298.8deg);
  -o-transform: rotate(298.8deg);
}

.skills.circles .progress.p84 .bar {
  transform: rotate(302.4deg);
  -webkit-transform: rotate(302.4deg);
  -moz-transform: rotate(302.4deg);
  -o-transform: rotate(302.4deg);
}

.skills.circles .progress.p85 .bar {
  transform: rotate(306deg);
  -webkit-transform: rotate(306deg);
  -moz-transform: rotate(306deg);
  -o-transform: rotate(306deg);
}

.skills.circles .progress.p86 .bar {
  transform: rotate(309.6deg);
  -webkit-transform: rotate(309.6deg);
  -moz-transform: rotate(309.6deg);
  -o-transform: rotate(309.6deg);
}

.skills.circles .progress.p87 .bar {
  transform: rotate(313.2deg);
  -webkit-transform: rotate(313.2deg);
  -moz-transform: rotate(313.2deg);
  -o-transform: rotate(313.2deg);
}

.skills.circles .progress.p88 .bar {
  transform: rotate(316.8deg);
  -webkit-transform: rotate(316.8deg);
  -moz-transform: rotate(316.8deg);
  -o-transform: rotate(316.8deg);
}

.skills.circles .progress.p89 .bar {
  transform: rotate(320.4deg);
  -webkit-transform: rotate(320.4deg);
  -moz-transform: rotate(320.4deg);
  -o-transform: rotate(320.4deg);
}

.skills.circles .progress.p90 .bar {
  transform: rotate(324deg);
  -webkit-transform: rotate(324deg);
  -moz-transform: rotate(324deg);
  -o-transform: rotate(324deg);
}

.skills.circles .progress.p91 .bar {
  transform: rotate(327.6deg);
  -webkit-transform: rotate(327.6deg);
  -moz-transform: rotate(327.6deg);
  -o-transform: rotate(327.6deg);
}

.skills.circles .progress.p92 .bar {
  transform: rotate(331.2deg);
  -webkit-transform: rotate(331.2deg);
  -moz-transform: rotate(331.2deg);
  -o-transform: rotate(331.2deg);
}

.skills.circles .progress.p93 .bar {
  transform: rotate(334.8deg);
  -webkit-transform: rotate(334.8deg);
  -moz-transform: rotate(334.8deg);
  -o-transform: rotate(334.8deg);
}

.skills.circles .progress.p94 .bar {
  transform: rotate(338.4deg);
  -webkit-transform: rotate(338.4deg);
  -moz-transform: rotate(338.4deg);
  -o-transform: rotate(338.4deg);
}

.skills.circles .progress.p95 .bar {
  transform: rotate(342deg);
  -webkit-transform: rotate(342deg);
  -moz-transform: rotate(342deg);
  -o-transform: rotate(342deg);
}

.skills.circles .progress.p96 .bar {
  transform: rotate(345.6deg);
  -webkit-transform: rotate(345.6deg);
  -moz-transform: rotate(345.6deg);
  -o-transform: rotate(345.6deg);
}

.skills.circles .progress.p97 .bar {
  transform: rotate(349.2deg);
  -webkit-transform: rotate(349.2deg);
  -moz-transform: rotate(349.2deg);
  -o-transform: rotate(349.2deg);
}

.skills.circles .progress.p98 .bar {
  transform: rotate(352.8deg);
  -webkit-transform: rotate(352.8deg);
  -moz-transform: rotate(352.8deg);
  -o-transform: rotate(352.8deg);
}

.skills.circles .progress.p99 .bar {
  transform: rotate(356.4deg);
  -webkit-transform: rotate(356.4deg);
  -moz-transform: rotate(356.4deg);
  -o-transform: rotate(356.4deg);
}

.skills.circles .progress.p100 .bar {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

/* section skills fullhd */
@media (min-width: 1700px) {
  .skills.list ul li {
    width: 33.333%;
  }
}

/* section skills mobile */
@media (max-width: 720px) {
  .skills ul {
    margin: -20px 0 0 0;
  }

  .skills ul li {
    padding: 20px 0 0 0;
  }

  .skills.dotted ul li {
    width: 100%;
  }

  .skills.circles ul li {
    width: 50%;
  }

  .skills.list ul li {
    width: 100%;
  }

  .skills.list ul li:last-child,
  .skills.list ul li:last-child .name {
    margin-bottom: 0;
  }
}

/* - Clients */
.clients-items {
  margin: -35px -35px 0 -35px;
  font-size: 0;
}

.clients-items .clients-col {
  padding: 35px 35px 0 35px;
  display: inline-block;
  vertical-align: top;
  width: 25%;
}

.clients-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100px;
  opacity: 0.4;
  transition: opacity 1s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -moz-transition: opacity 1s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -webkit-transition: opacity 1s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -o-transition: opacity 1s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
}

.clients-item a {
  text-decoration: none;
}

.clients-item img {
  max-width: 100%;
  max-height: 100px;
}

.clients-item:hover {
  opacity: 1;
}

/* section clients mobile */
@media (max-width: 720px) {
  .clients-items {
    margin: -20px -20px 0 -20px;
  }

  .clients-items .clients-col {
    padding: 20px 20px 0 20px;
    width: 50%;
  }
}

/* - Service */
.service-items {
  margin-top: -70px;
  font-size: 0;
  text-align: center;
}

.service-items .service-col {
  margin-top: 70px;
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.service-items .service-col:nth-child(odd) .content-box {
  margin-right: 35px;
}

.service-items .service-col:nth-child(even) .content-box {
  margin-left: 35px;
}

.service-items .service-item .icon {
  font-size: 36px;
  line-height: normal;
  color: #4bffa5;
}

.service-items .service-item .name {
  margin: 15px 0;
  color: #eee;
  font-weight: 500;
}

/* section service tablet */
@media (max-width: 1199px) {
  .service-items {
    margin-top: -40px;
  }

  .service-items .service-col {
    margin-top: 40px;
  }

  .service-items .service-col:nth-child(odd) .content-box {
    margin-right: 20px;
  }

  .service-items .service-col:nth-child(even) .content-box {
    margin-left: 20px;
  }
}

/* section service mobile */
@media (max-width: 720px) {
  .service-items .service-col {
    width: 100%;
  }

  .service-items .service-col:nth-child(odd) .content-box {
    margin-right: 0;
  }

  .service-items .service-col:nth-child(even) .content-box {
    margin-left: 0;
  }
}

/* - Portfolio */
.section.works {
  padding-bottom: 0;
}

.section.works .filter-menu {
  margin-bottom: 70px;
}

.section.works .filters {
  margin-top: -10px;
}

.section.works .filters input {
  display: none;
}

.section.works .filters .btn-group {
  display: inline-block;
  vertical-align: top;
}

.section.works .filters label {
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
  margin-top: 10px;
  font-size: 16px;
  color: #eee;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  padding-bottom: 0;
  transition: color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -moz-transition: color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -webkit-transition: color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -o-transition: color 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
}

.section.works .filters label.glitch-effect {
  color: #4bffa5;
}

.box-items {
  position: relative;
  margin-left: -50px;
  margin-right: -50px;
  font-size: 0px;
}

.box-items .box-item {
  width: 50%;
  margin: 0;
  padding: 0 50px 100px 50px;
  position: relative;
  text-align: center;
  font-size: 13px;
  float: left;
}

.box-items .box-item .image {
  position: relative;
  overflow: hidden;
}

.box-items .box-item .image a {
  display: block;
  font-size: 0;
}

.box-items .box-item .image a img {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
}

.box-items .box-item .image .info {
  text-align: center;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.box-items .box-item .image .info .icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #101010;
  font-size: 18px;
  display: inline-block;
  font-weight: normal;
  opacity: 0;
  transform: translateY(-15px);
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -moz-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -webkit-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -o-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
}

.box-items .box-item .image .desc {
  padding: 10px 20px;
  position: relative;
  display: block;
  opacity: 0;
  transform: translateY(-15px);
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -moz-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -webkit-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -o-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
}

.box-items .box-item .image .desc .name {
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: #101010;
  word-wrap: break-word;
}

.box-items .box-item .image:hover .icon {
  opacity: 0.6;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -o-transform: translateY(0px);
  transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0.35s;
  -moz-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0.35s;
  -webkit-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0.35s;
  -o-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0.35s;
}

.box-items .box-item .image:hover .desc {
  top: 0;
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -o-transform: translateY(0px);
  transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0.35s;
  -moz-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0.35s;
  -webkit-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0.35s;
  -o-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0.35s;
}

.box-items .box-item .category {
  margin: 0;
  display: block;
  font-size: 14px;
  color: #101010;
  opacity: 0.6;
}

.portfolio-cols {
  display: flex;
}

.description-col {
  width: 78%;
}

.details-col {
  padding-left: 50px;
  width: 24%;
}

.single-post-text .details-list li {
  margin-bottom: 20px;
  padding-left: 0;
  font-size: 13px;
  text-transform: uppercase;
}

.single-post-text .details-list li:before {
  display: none;
}

.single-post-text .details-list li strong {
  margin-bottom: 5px;
  display: block;
  color: #eee;
  font-weight: 500;
}

/* section portfolio tablet */
@media (max-width: 1199px) {
  .section.works .filter-menu {
    margin-bottom: 40px;
  }

  .section .content-box {
    padding: 25px;
  }

  .box-items {
    margin-left: -20px;
    margin-right: -20px;
  }

  .box-items .box-item {
    width: 50%;
    padding: 0 20px 40px 20px;
  }

  .portfolio-cols {
    display: block;
  }

  .description-col {
    width: 100%;
  }

  .details-col {
    width: 100%;
  }

  .details-col {
    padding: 40px 0 0 0;
    width: 100%;
  }

  .section.works .filters label {
    margin-right: 15px;
  }
}

/* section portfolio mobile */
@media (max-width: 720px) {
  .section.works .filters label {
    font-size: 14px;
  }

  .box-items {
    margin-left: 0;
    margin-right: 0;
  }

  .box-items .box-item {
    padding-left: 0;
    padding-right: 0;
    float: none;
    width: 100%;
  }

  .box-items .box-item:last-child {
    padding-bottom: 0;
  }
}

/* - Contacts */
.section.contacts .alert-success {
  display: none;
}

.section.contacts .alert-success p {
  margin: 0;
  font-size: 16px;
  color: #eee;
}

.map {
  position: absolute;
  left: 0;
  top: -5vh;
  width: 100vw;
  height: 110vh;
}

/* - Pricing */
.pricing-items {
  margin: 0 -35px;
  font-size: 0;
}

.pricing-items .pricing-col {
  padding: 0 35px;
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.pricing-item {
  position: relative;
  text-align: center;
}

.pricing-item .icon {
  font-size: 36px;
  line-height: normal;
  color: #4bffa5;
}

.pricing-item .name {
  margin: 15px 0;
  color: #eee;
  font-weight: 500;
}

.pricing-item .amount {
  margin: 30px 0;
}

.pricing-item .amount .number {
  margin: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 48px;
  line-height: 24px;
  font-weight: 600;
  color: #eee;
}

.pricing-item .amount .number .dollar,
.pricing-item .amount .number .period {
  position: absolute;
  left: -15px;
  top: -5px;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 14px;
  color: #999;
  font-weight: 400;
}

.pricing-item .amount .number .period {
  left: auto;
  right: -55px;
  top: auto;
  bottom: -5px;
  width: 50px;
  text-align: left;
  white-space: nowrap;
}

.pricing-item .feature-list {
  margin-bottom: 30px;
}

.pricing-item .feature-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pricing-item .feature-list ul li {
  margin: 10px 0;
}

.pricing-item .feature-list ul li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.pricing-item .feature-list ul li.disable {
  text-decoration: line-through;
  opacity: 0.5;
}

.pricing-item .feature-list ul li strong {
  margin: 0 0 0 5px;
  padding: 0 5px;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: #101010;
  background: #4bffa5;
  font-weight: 300;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -khtml-border-radius: 2px;
}

/* section pricing tablet */
/* section pricing mobile */
@media (max-width: 720px) {
  .pricing-items .pricing-col {
    margin-bottom: 40px;
    width: 100%;
  }

  .pricing-items .pricing-col:last-child {
    margin-bottom: 0;
  }
}

/* - Team */
.team-items {
  margin: 0 -35px;
  font-size: 0;
}

.team-items .team-col {
  padding: 0 35px;
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.team-item {
  position: relative;
  text-align: center;
}

.team-item .image {
  font-size: 0;
}

.team-item .image img {
  max-width: 100%;
  height: auto;
}

.team-item .name {
  margin: 30px 0 5px 0;
  color: #eee;
  font-weight: 500;
}

.team-item .category {
  margin: 0 0 5px 0;
  position: relative;
  display: inline-block;
  color: #999;
}

.team-item .soc a {
  margin: 0 5px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  color: #bbb;
  transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -moz-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -webkit-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -o-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
}

.team-item .soc a .icon {
  font-size: 14px;
  color: #bbb;
}

.team-item .soc a:hover {
  color: #fff;
  opacity: 1;
}

.team-item .soc a:hover .icon {
  color: #fff;
}

/* section team tablet */
/* section team mobile */
@media (max-width: 720px) {
  .team-items .team-col {
    margin-bottom: 40px;
    width: 100%;
  }

  .team-items .team-col:last-child {
    margin-bottom: 0;
  }
}

/* - Testimonials */
.reviews-carousel {
  position: relative;
  width: calc(100% + 1px);
}

.reviews-carousel .content-box {
  margin-right: 0;
}

.reviews-carousel .swiper-nav {
  position: absolute;
  left: auto;
  right: 35px;
  top: -58px;
  height: 24px;
  z-index: 3;
}

.reviews-carousel .swiper-nav .swiper-button-prev,
.reviews-carousel .swiper-nav .swiper-button-next {
  margin-left: 15px;
  margin-top: 0;
  position: relative;
  top: 0;
  left: 0;
  right: auto;
  width: 20px;
  height: 20px;
  background: none;
  font-size: 22px;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.reviews-item {
  position: relative;
}

.reviews-item .image {
  float: left;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 70px;
  font-size: 0;
}

.reviews-item .image img {
  width: 100%;
  height: auto;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
}

.reviews-item .info {
  margin-top: 10px;
  margin-left: 85px;
}

.reviews-item .name {
  margin: 0 0 5px 0;
  color: #eee;
  font-weight: 500;
}

.reviews-item .company {
  font-size: 14px;
  color: #999;
}

.reviews-item .text {
  clear: both;
}

/* section testimonials tablet */
/* section testimonials mobile */
@media (max-width: 720px) {
  .reviews-carousel .swiper-nav {
    right: 0;
  }
}

/* - Blog */
.started-content .date {
  margin: 0 0 10px;
  display: inline-block;
  padding: 0 5px;
  height: 20px;
  line-height: 18px;
  font-weight: 500;
  font-size: 11px;
  color: #4bffa5;
  border: 1px solid #4bffa5;
}

.single-post-text,
.comment-text {
  word-wrap: break-word;
}

.single-post-text:after {
  content: "";
  position: relative;
  clear: both;
  display: block;
}

.single-post-text .content-box *,
.comment-text .content-box * {
  margin-top: 0;
}

.single-post-text img,
.comment-text img {
  max-width: 100%;
  height: auto;
}

.single-post-text p:last-child {
  margin-bottom: 0;
}

.single-post-text p a,
.comment-text p a {
  color: #4bffa5;
}

.single-post-text p a:hover,
.comment-text p a:hover {
  text-decoration: underline;
}

blockquote {
  margin: 25px 0;
  padding: 0 0 0 20px;
  font-size: 16px;
  line-height: 28px;
  color: #999;
  font-style: italic;
  font-weight: 300;
  border-left: 2px solid #4bffa5;
}

blockquote code {
  padding: 2px;
  display: inline;
}

iframe,
object {
  max-width: 100%;
}

.single-post-text ul,
.comment-text ul {
  list-style: none;
}

.single-post-text ol,
.comment-text ol {
  list-style-position: inside;
}

.single-post-text ul > li,
.single-post-text ol > li,
.comment-text ul > li,
.comment-text ol > li {
  margin: 5px 0;
  padding: 0 0 0 18px;
  position: relative;
  font-weight: 400;
}

.single-post-text ol > li,
.comment-text ol > li {
  margin: 5px 0;
  padding: 0;
  position: relative;
  font-size: 14px;
  font-weight: 400;
}

.single-post-text ul > li:before,
.comment-text ul > li:before {
  margin: 0;
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 4px;
  height: 4px;
  background: #4bffa5;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  padding: 0;
}

.wp-block-gallery .blocks-gallery-image:before,
.wp-block-gallery .blocks-gallery-item:before {
  display: none;
}

.single-post-text figure,
.comment-text figure {
  margin: 0 0 30px 0;
  max-width: 100%;
}

.single-post-text figure .wp-caption-text,
.comment-text figure .wp-caption-text,
.gallery-caption,
.bypostauthor {
  margin-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #999;
}

.gallery {
  margin: 30px -10px;
}

.single-post-text .gallery {
  margin-bottom: 0;
}

.gallery .gallery-item {
  margin: 0;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.gallery .gallery-item img {
  width: 100%;
  height: auto;
}

.gallery.gallery-columns-1 .gallery-item {
  width: 100%;
}

.gallery.gallery-columns-2 .gallery-item {
  width: 50%;
}

.gallery.gallery-columns-3 .gallery-item {
  width: 33.333%;
}

.gallery.gallery-columns-4 .gallery-item {
  width: 25%;
}

.gallery.gallery-columns-5 .gallery-item {
  width: 20%;
}

.gallery.gallery-columns-6 .gallery-item {
  width: 16.666%;
}

.gallery.gallery-columns-7 .gallery-item {
  width: 14.28%;
}

.gallery.gallery-columns-8 .gallery-item {
  width: 12.5%;
}

.gallery.gallery-columns-9 .gallery-item {
  width: 11.111%;
}

.post-text-bottom {
  padding: 25px 0 10px 0;
}

.post-text-bottom .share-btn {
  position: relative;
  display: inline-block;
  top: 1px;
  margin-left: 7px;
  margin-right: 7px;
  font-size: 16px;
}

.post-text-bottom > span,
.post-text-bottom > .social-share {
  margin-right: 30px;
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: middle;
}

.post-text-bottom .cat-links,
.post-text-bottom .byline,
.post-text-bottom .social-share {
  margin-bottom: 20px;
}

.post-text-bottom span.cat-links a {
  color: #4bffa5;
}

.post-text-bottom span.cat-links a:hover {
  text-decoration: underline;
}

.post-text-bottom .tags-links {
  display: block;
  line-height: 18px;
}

.post-text-bottom .tags-links a,
.post-text-bottom .tags-links span {
  margin: 0 10px 10px 0;
  display: inline-block;
  padding: 0 10px;
  height: 20px;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  color: #4bffa5;
  text-decoration: none;
  border: 1px solid #4bffa5;
}

.nav-links {
  margin: 70px 0;
  min-height: 24px;
}

.page-numbers.prev,
.nav-links .nav-previous {
  position: absolute;
  top: 0;
  left: 32px;
}

.page-numbers,
.nav-links a,
.page-links a {
  margin: 0 5px;
  display: inline-block;
  vertical-align: top;
  min-height: 24px;
  text-decoration: none;
  font-weight: 500;
}

.page-numbers.prev:before,
.page-numbers.next:after,
.nav-links .nav-previous a:before,
.nav-links .nav-next a:after {
  content: "";
  margin-left: 15px;
  content: "\f30b";
  display: inline-block;
  vertical-align: middle;
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  line-height: 14px;
  font-weight: 900;
}

.page-numbers.prev:before,
.nav-links .nav-previous a:before {
  margin-left: 0;
  margin-right: 15px;
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

.page-numbers.next,
.nav-links .nav-next {
  position: absolute;
  top: 0;
  right: 32px;
}

@media (max-width: 480px) {
  .gallery .gallery-item {
    width: 100%;
  }

  .gallery.gallery-columns-1 .gallery-item {
    width: 100%;
  }

  .gallery.gallery-columns-2 .gallery-item {
    width: 100%;
  }

  .gallery.gallery-columns-3 .gallery-item {
    width: 100%;
  }

  .gallery.gallery-columns-4 .gallery-item {
    width: 100%;
  }

  .gallery.gallery-columns-5 .gallery-item {
    width: 100%;
  }

  .gallery.gallery-columns-6 .gallery-item {
    width: 100%;
  }

  .gallery.gallery-columns-7 .gallery-item {
    width: 100%;
  }

  .gallery.gallery-columns-8 .gallery-item {
    width: 100%;
  }

  .gallery.gallery-columns-9 .gallery-item {
    width: 100%;
  }

  th {
    border: 2px solid #fff;
    padding: 5px;
  }

  td {
    border: 2px solid #fff;
    padding: 5px;
  }
}

/* - Footer */
.footer {
  padding: 0;
  position: fixed;
  bottom: 50px;
  left: 50px;
  right: 50px;
  width: auto;
  height: 1px;
  z-index: 100;
}

.footer .copy {
  position: absolute;
  left: 0;
  bottom: 0;
}

.footer p,
.footer .copy {
  margin: 2px 0 0 0;
  font-size: 14px;
  line-height: 2;
  color: #bbb;
}

.footer .soc-box {
  position: absolute;
  right: 0;
  bottom: 0;
  float: right;
  width: 24px;
}

.footer .soc a {
  display: block;
  margin-bottom: 20px;
  text-align: center;
  color: #bbb;
  transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -moz-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -webkit-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
  -o-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1);
}

.footer .soc a:last-child {
  margin-bottom: 0;
}

.footer .soc a .icon {
  font-size: 18px;
  color: #bbb;
}

.footer .soc a:hover {
  color: #fff;
  opacity: 1;
}

.footer .soc a:hover .icon {
  color: #fff;
}

.follow-label {
  padding-right: 75px;
  position: absolute;
  bottom: 100%;
  right: 0;
  color: #bbb;
  font-size: 14px;
  line-height: 24px;
  height: 24px;
  white-space: nowrap;
  transform-origin: 100% 0;
  text-align: right;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.follow-label:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 50px;
  height: 1px;
  background: #fff;
}

/* section footer tablet */
@media (max-width: 1199px) {
  .footer .copy {
    display: none;
  }

  .footer .follow-label {
    display: none;
  }

  .footer {
    padding: 50px 0;
    position: relative;
    top: 0;
    bottom: auto;
    left: 0;
    right: auto;
    height: auto;
  }

  .footer .copy {
    margin-bottom: 10px;
    display: block;
    position: relative;
    text-align: center;
  }

  .footer .soc-box {
    position: relative;
    right: auto;
    left: 0;
    top: 0;
    bottom: auto;
    float: none;
    width: auto;
    text-align: center;
  }

  .footer .soc a {
    margin: 0 10px;
    display: inline-block;
  }

  .home .footer {
    padding: 0;
    position: fixed;
    bottom: 50px;
    top: auto;
    left: 50px;
    right: 50px;
    height: 1px;
  }

  .home .footer .copy {
    display: none;
  }

  .home .footer .soc-box {
    position: absolute;
    right: 0;
    left: auto;
    top: auto;
    bottom: 0;
    float: right;
    width: 20px;
  }

  .home .footer .soc a {
    margin: 10px 0 0 0;
    display: block;
  }

  .home .footer .soc a .icon {
    font-size: 17px;
  }
}

/* section footer mobile */
@media (max-width: 720px) {
  .home .footer {
    bottom: 30px;
    top: auto;
    left: 30px;
    right: 30px;
  }
}

/* Sidebar */

.sticky {
  background-color: #fdfbf2;
}

/* - Popups */
.popup-box {
  margin: 30px auto;
  width: 520px;
  background: #101010;
  position: relative;
  padding: 0;
}

@media (max-width: 720px) {
  .popup-box {
    width: 440px;
  }
}

@media (max-width: 480px) {
  .popup-box {
    width: 320px;
  }
}

.popup-box .image {
  height: 560px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.popup-box .image img {
  width: 100%;
  height: auto;
  transform: none !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -o-transform: none !important;
}

.popup-box .desc {
  padding: 35px;
}

.popup-box .category {
  margin: 0 0 5px 0;
  display: block;
  font-size: 14px;
  color: #bbb;
}

.popup-box .desc h4 {
  margin: 0 0 15px 0;
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-close-btn-in .mfp-close {
  top: 40px !important;
  right: 0 !important;
  padding-right: 0 !important;
  width: 54px !important;
  height: 54px !important;
  line-height: 54px !important;
  opacity: 1 !important;
  font-weight: 300;
  color: #fff;
  font-size: 24px;
  text-align: center !important;
  font-family: "Verdana" !important;
}

.mfp-close-btn-in .mfp-close {
  color: #fff !important;
}

.mfp-close-btn-in .popup-box .mfp-close,
.mfp-close-btn-in .mfp-iframe-holder .mfp-close {
  top: 0 !important;
}

/* Background Option */
.video-bg {
  margin-left: -50vw;
  position: absolute !important;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* z-index: 3; */
}

.jarallax .jarallax-img {
  max-width: 100%;
}

.background-enabled .header .top-menu ul li a,
.background-enabled .sidebar_btn,
.background-enabled .footer .soc a .ion,
.background-enabled .footer .copy,
.background-enabled-footer .footer .soc a .ion,
.background-enabled-footer .footer .copy,
.background-enabled .section.started .started-content .h-subtitle,
.background-enabled .section.started .started-content .typed-bread,
.background-enabled .section.started .started-content .typed-bread a,
.background-enabled .section.started .started-content .typed-subtitle,
.background-enabled .section.started .started-content .typed-cursor,
.background-enabled .section.started .mouse_btn {
  color: #fff;
}

.background-enabled .header .logo img {
  /* filter: brightness(0) invert(1); */
}

.background-enabled .header .logo {
  color: rgba(255, 255, 255, 0.5);
}

.background-enabled .header .logo strong {
  color: #fff;
}

.background-enabled .header .top-menu ul li a:hover {
  color: #fff;
}

.section.started.background-enabled .started-content .h-title {
  color: #fff;
}

.background-enabled .header .top-menu-nav .sub-menu li a {
  color: #fff;
}

.background-enabled .header .top-menu-nav .sub-menu li a:hover {
  color: #fff;
}

.background-enabled .header,
.background-enabled .footer,
.background-enabled-footer .footer,
.background-enabled .glitch-effect:before,
.background-enabled .glitch-effect:after,
.background-enabled .section.started {
  background: transparent;
  border-bottom-color: transparent;
}

@media (max-width: 480px) {
  .video-bg {
    margin-left: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-bg-mask {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #101010;
  opacity: 0.4;
  z-index: 2;
}

.video-bg-texture {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.grained-off .video-bg-texture {
  display: none;
}

.grained-on .video-bg-texture {
  display: block;
}

.disable-default-mask:before {
  display: none;
}

.embed-container {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
** Blog
*/

.blog-items {
  float: left;
  width: 66.666%;
}

.no-sidebar .blog-items {
  float: none;
  width: auto;
}

.blog-items.cols {
  margin-left: -50px;
  margin-right: -50px;
  float: none;
  width: auto;
  font-size: 0;
}

.blog-items.cols .blog-col {
  padding: 0 50px;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  font-size: 16px;
}

.blog-items.cols .blog-col .blog-item.content-box {
  padding: 0 !important;
  border: none !important;
  background: none !important;
  text-align: center;
}

.blog-items.cols .blog-col .blog-item .image {
  margin-bottom: 30px;
}

.blog-items.cols .blog-col .blog-item .image img {
  margin-bottom: 0;
}

.blog-items .blog-item {
  margin-bottom: 70px;
}

.blog-items .blog-item .image {
  position: relative;
  overflow: hidden;
}

.blog-items .blog-item .image a {
  display: block;
  font-size: 0;
}

.blog-items .blog-item .image a img {
  margin-bottom: 30px;
  max-width: 100%;
  height: auto;
  position: relative;
  top: 0;
}

.blog-items .blog-item .desc {
  position: relative;
  display: block;
}

.blog-items .blog-item .desc .name {
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  word-wrap: break-word;
  transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -moz-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -webkit-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
  -o-transition: all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1) 0s;
}

.blog-items .blog-item .category,
.blog-items .blog-item .date {
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #bbb;
  opacity: 0.65;
}

.blog-items .blog-item .category a,
.blog-items .blog-item .date a {
  color: #bbb;
  text-decoration: none;
}

.blog-items .blog-item .single-post-text {
  margin-top: 15px;
}

.blog-items .blog-item .single-post-text p {
  margin-top: 0;
  margin-bottom: 0;
}

/* section portfolio tablet */

@media (max-width: 1199px) {
  .blog-items {
    width: 68.5%;
  }
}

/* section portfolio mobile */

@media (max-width: 720px) {
  .blog-items {
    float: none;
    width: 100%;
  }
  .blog-items.cols .blog-col {
    width: 100%;
  }
}

/* pagination */

.section .pager,
.page-links,
.nav-links {
  position: relative;
  clear: both;
  padding: 0;
  text-align: center;
}

.section.works .pager {
  padding-bottom: 70px;
}

.nav-links {
  margin-top: 70px;
  min-height: 24px;
}

.page-links {
  margin-top: 30px;
  min-height: 24px;
}

.page-numbers,
.nav-links a,
.page-links a {
  margin: 0 5px;
  display: inline-block;
  vertical-align: top;
  min-height: 24px;
  text-decoration: none;
  font-weight: 500;
}

.page-numbers.current,
.page-links a.current {
  opacity: 0.5;
}

.page-numbers.prev,
.nav-links .nav-previous {
  position: absolute;
  top: 0;
  left: 32px;
}

.page-numbers.next,
.nav-links .nav-next {
  position: absolute;
  top: 0;
  right: 32px;
}

.page-numbers.prev:before,
.page-numbers.next:after,
.nav-links .nav-previous a:before,
.nav-links .nav-next a:after {
  content: "";
  margin-left: 15px;
  content: "\f30b";
  display: inline-block;
  vertical-align: middle;
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  line-height: 14px;
  font-weight: 900;
}

.page-numbers.prev:before,
.nav-links .nav-previous a:before {
  margin-left: 0;
  margin-right: 15px;
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

/*
** Sidebar
*/

.content-sidebar {
  padding: 0 30px 0 60px;
  position: relative;
  float: right;
  width: 33.333%;
}

.content-sidebar .widget {
  margin-bottom: 50px;
}

.content-sidebar .search-form {
  position: relative;
}

.content-sidebar .search-form .search-form-label {
  display: block;
  position: relative;
}

.content-sidebar .search-form input[type="text"],
.content-sidebar .search-form input[type="email"],
.content-sidebar .search-form input[type="password"],
.content-sidebar .search-form input[type="datetime"],
.content-sidebar .search-form input[type="date"],
.content-sidebar .search-form input[type="month"],
.content-sidebar .search-form input[type="time"],
.content-sidebar .search-form input[type="week"],
.content-sidebar .search-form input[type="search"],
.content-sidebar .search-form textarea,
.content-sidebar .search-form textarea.form-control {
  margin-bottom: 0;
  padding: 0;
  width: 100%;
  border: none;
  background: 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
}

.content-sidebar .search-form .btn_search,
.content-sidebar .search-form .search-submit {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
  z-index: 2;
}

.content-sidebar .search-form .search-submit {
  width: 14px;
  height: 31px;
  /* background: url(../images/search.svg) no-repeat center center !important; */
  background-size: contain !important;
  font-size: 0;
  opacity: 0.7;
  filter: brightness(0) invert(1);
}

.content-sidebar .widget-title {
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
  font-size: 20px;
  line-height: normal;
  color: #fff;
}

.content-sidebar .widget-title:before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
}

.content-sidebar ul {
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  list-style: none;
}

.content-sidebar ul ul {
  margin-left: 0;
}

.content-sidebar ul li {
  margin-bottom: 10px;
  position: relative;
  font-size: 14px;
}

.content-sidebar .widget_tags a {
  margin: 4px;
  display: inline-block;
  vertical-align: bottom;
  padding: 0 5px;
  font-weight: 500;
  font-size: 14px;
  color: #4bffa5;
  border: 1px solid #4bffa5;
  text-decoration: none;
}

.wp-block-categories-list li ul,
.wp-block-archives-list li ul {
  margin-left: 0;
}

.wp-block-categories-list li,
.wp-block-archives-list li,
.widget.widget_nav_menu ul li,
.widget.widget_pages ul li,
.widget_categories ul li {
  margin-bottom: 10px;
  padding-left: 0;
  position: relative;
  font-size: 14px;
}

.wp-block-categories-list li ul li:before,
.wp-block-archives-list li ul li:before,
.widget.widget_nav_menu ul li ul li:before,
.widget.widget_pages ul li ul li:before,
.widget_categories ul li ul li:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 5px;
  height: 1px;
  background: #999;
  opacity: 0.8;
}

/* tablet */

@media (max-width: 1199px) {
  .content-sidebar {
    padding: 0 35px;
    width: 31.5%;
  }
  .sticky:before {
    left: -26px;
    top: -26px;
    bottom: -26px;
  }
}

/* mobile */

@media (max-width: 720px) {
  .content-sidebar {
    margin-top: 70px;
    padding: 0;
    float: none;
    width: 100%;
  }
  .page-numbers.next,
  .nav-links .nav-next {
    right: -5px;
  }
  .page-numbers.prev,
  .nav-links .nav-previous {
    left: -5px;
  }
}
