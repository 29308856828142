html {
    margin-right: 0 !important
}

body {
    margin: 0;
    padding: 0;
    border: none;
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.5;
    color: #bbb;
    background: #101010;
    letter-spacing: 0;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility
}

body.scroll_hidden {
    overflow: hidden;
    height: 100vh
}

* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box
}

.clear {
    clear: both
}

.cursor-follower {
    margin: -13px 0 0 -13px;
    position: absolute;
    background: #4bffa5;
    width: 26px;
    height: 26px;
    -webkit-transition: transform 1s cubic-bezier(.02, 1.2, .8, 1), opacity .25s ease;
    transition: transform 1s cubic-bezier(.02, 1.2, .8, 1), opacity .25s ease;
    opacity: .4;
    z-index: 1111;
    pointer-events: none;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%
}

.cursor-follower.hide {
    opacity: 0 !important
}

@media (max-width:1199px) {
    .cursor-follower {
        display: none
    }
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 11111
}

.preloader .spinner {
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 100%
}

.preloader .spinner .double-bounce1,
.preloader .spinner .double-bounce2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.preloader .spinner .double-bounce2 {
    background: rgba(255, 255, 255, .5);
    height: 0;
    -webkit-animation: loading 2s infinite ease-in-out;
    animation: loading 2s infinite ease-in-out
}

.container {
    position: relative;
    margin: 0;
    overflow: hidden
}

.container:before {
    pointer-events: none;
    z-index: 12
}

.lines {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    font-size: 0;
    pointer-events: none;
    z-index: 1111
}

.lines.no-lines .line-col:before {
    opacity: 0
}

.lines .line-col {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20%;
    height: 100vh
}

.lines .line-col:first-child:before {
    background: 0 0
}

.lines .line-col:first-child:after {
    left: auto;
    right: 0;
    width: 1000%
}

.lines .line-col:first-child .line {
    display: none
}

.lines .line-col:last-child:after {
    left: 0;
    right: auto;
    width: 1000%
}

.lines .line-col:nth-child(1):before {
    -o-transition-delay: 2.9s;
    -webkit-transition-delay: 2.9s;
    transition-delay: 2.9s
}

.lines .line-col:nth-child(1):after {
    top: 0;
    bottom: auto;
    -webkit-transition-delay: .9s;
    -o-transition-delay: .9s;
    transition-delay: .9s
}

.lines .line-col:nth-child(2):before {
    -o-transition-delay: 2.7s;
    -webkit-transition-delay: 2.7s;
    transition-delay: 2.7s
}

.lines .line-col:nth-child(2):after {
    top: auto;
    bottom: 0;
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s
}

.lines .line-col:nth-child(3):before {
    -o-transition-delay: 2.2s;
    -webkit-transition-delay: 2.2s;
    transition-delay: 2.2s
}

.lines .line-col:nth-child(3):after {
    top: 0;
    bottom: auto;
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s
}

.lines .line-col:nth-child(4):before {
    -o-transition-delay: 2.5s;
    -webkit-transition-delay: 2.5s;
    transition-delay: 2.5s
}

.lines .line-col:nth-child(4):after {
    top: auto;
    bottom: 0;
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s
}

.lines .line-col:nth-child(5):before {
    -o-transition-delay: 3s;
    -webkit-transition-delay: 3s;
    transition-delay: 3s
}

.lines .line-col:nth-child(5):after {
    top: 0;
    bottom: auto;
    -webkit-transition-delay: 1s;
    -o-transition-delay: 1s;
    transition-delay: 1s
}

.lines .line-col:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 0%;
    background: rgba(255, 255, 255, .07);
    transition: height 1s cubic-bezier(.165, .85, .45, 1) 0s;
    -moz-transition: height 1s cubic-bezier(.165, .85, .45, 1) 0s;
    -webkit-transition: height 1s cubic-bezier(.165, .85, .45, 1) 0s;
    -o-transition: height 1s cubic-bezier(.165, .85, .45, 1) 0s
}

.lines .line-col:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1e1e1e;
    z-index: 12;
    transition: height 1s cubic-bezier(.165, .85, .45, 1) 0s;
    -moz-transition: height 1s cubic-bezier(.165, .85, .45, 1) 0s;
    -webkit-transition: height 1s cubic-bezier(.165, .85, .45, 1) 0s;
    -o-transition: height 1s cubic-bezier(.165, .85, .45, 1) 0s
}

.lines.finish .line-col:before {
    height: 100%
}

.lines.finish .line-col:after {
    height: 0%
}

.lines.ready {
    z-index: 11
}

.wrapper {
    position: relative
}

.breadcrumbs {
    margin: 0
}

.lines-off .lines .line-col:before {
    display: none
}

.lines-on .lines .line-col:before {
    display: block
}

@media (max-width:1199px) {
    .lines .line-col {
        width: 33.333%
    }
    .lines .line-col:nth-child(4),
    .lines .line-col:nth-child(5) {
        display: none
    }
}

@media (max-width:720px) {
    .lines .line-col {
        width: 50%
    }
    .lines .line-col:nth-child(3),
    .lines .line-col:nth-child(4),
    .lines .line-col:nth-child(5) {
        display: none
    }
}

.cols {
    position: relative
}

.cols .col.col-sm {
    float: left;
    width: 25%
}

@media (max-width:720px) {
    .cols .col.col-sm {
        width: 100%
    }
}

.cols .col.col-lg {
    float: left;
    width: 75%
}

@media (max-width:720px) {
    .cols .col.col-lg {
        width: 100%
    }
}

.cols .col.col-md {
    float: left;
    width: 50%
}

@media (max-width:720px) {
    .cols .col.col-md {
        width: 100%
    }
}

.cols:after {
    content: '';
    display: block;
    clear: both
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    font-family: Roboto;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #141414
}

h1 {
    font-size: 34px
}

h2 {
    font-size: 30px
}

h3 {
    font-size: 26px
}

h4 {
    font-size: 22px
}

h5 {
    font-size: 18px
}

h6 {
    font-size: 14px
}

p {
    font-size: 16px;
    padding: 0;
    margin: 30px 0
}

strong {
    font-weight: 700
}

@media screen and (max-width:720px) {
    h1 {
        font-size: 24px
    }
    h2 {
        font-size: 20px
    }
    h3 {
        font-size: 18px
    }
    h4 {
        font-size: 16px
    }
    h5 {
        font-size: 14px
    }
    h6 {
        font-size: 13px
    }
    p {
        margin: 20px 0
    }
}

a {
    color: #eee;
    text-decoration: underline;
    outline: 0
}

a:hover {
    text-decoration: none;
    color: #4bffa5
}

.ink {
    display: block;
    position: absolute;
    background: #4bffa5;
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transition: transform 2s cubic-bezier(.165, .85, .45, 1) 0s;
    -moz-transition: transform 2s cubic-bezier(.165, .85, .45, 1) 0s;
    -webkit-transition: transform 2s cubic-bezier(.165, .85, .45, 1) 0s;
    -o-transition: transform 2s cubic-bezier(.165, .85, .45, 1) 0s;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -khtml-border-radius: 100%
}

.ink.ink-animate {
    transform: scale(3);
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -o-transform: scale(3)
}

.hover-animated .circle {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block
}

.bts-center {
    text-align: center
}

.bts-list {
    font-size: 0
}

.bts-list .btn {
    margin-right: 20px;
    margin-top: 20px
}

.bts-list .btn:last-child {
    margin-right: 0
}

.btn,
.single-post-text input[type=submit],
a.btn {
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    /* height: 44px; */
    /* line-height: 40px; */
    text-align: center;
    font-size: 16px;
    color: #4bffa5;
    font-weight: 500;
    text-decoration: none;
    background: 0 0;
    border: 2px solid #4bffa5;
    cursor: pointer;
    transition: 1s all cubic-bezier(.165, .85, .45, 1) 0s;
    -moz-transition: 1s all cubic-bezier(.165, .85, .45, 1) 0s;
    -webkit-transition: 1s all cubic-bezier(.165, .85, .45, 1) 0s;
    -o-transition: 1s all cubic-bezier(.165, .85, .45, 1) 0s
}

.btn .lnk,
a.btn .lnk {
    padding: 0 30px;
    position: relative;
    display: block;
    z-index: 2;
    pointer-events: none
}

.btn:hover,
a.btn:hover {
    color: #101010;
    background-color: #4bffa5;
    
}

@media screen and (max-width:720px) {
    .btn,
    .single-post-text input[type=submit],
    a.btn {
        font-size: 14px
    }
}

form.post-password-form {
    max-width: 400px;
    margin: 0 auto;
    text-align: center
}

button,
input,
textarea {
    display: block;
    font-family: Roboto;
    font-size: 16px;
    height: 50px;
    width: 100%;
    color: #eee;
    margin-bottom: 30px;
    padding: 0;
    background: 0 0 !important;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, .07);
    resize: none;
    outline: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
    transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s
}

button:focus,
input:focus,
textarea:focus {
    color: #eee;
    border-bottom: 1px solid #eee
}

textarea {
    height: 120px;
    padding: 0;
    padding-top: 15px
}

button {
    width: auto;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    color: #eee
}

button:hover {
    border-bottom: 1px solid #4bffa5
}

label,
legend {
    display: block;
    padding-bottom: 10px;
    font-family: Roboto;
    font-size: 13px
}

fieldset {
    border-width: 0;
    padding: 0
}

input[type=checkbox],
input[type=radio] {
    display: inline
}

::-webkit-input-placeholder {
    color: #999
}

:-moz-placeholder {
    color: #999
}

::-moz-placeholder {
    color: #999
}

:-ms-input-placeholder {
    color: #999
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    color: #eee
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
    color: #eee
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
    color: #eee
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
    color: #eee
}

input.error,
input.wpcf7-not-valid,
textarea.error,
textarea.wpcf7-not-valid {
    border-bottom: 1px solid red !important
}

.wpcf7-not-valid-tip,
label.error {
    display: none !important
}

input.error::-moz-placeholder,
input.wpcf7-not-valid::-moz-placeholder,
textarea.error::-moz-placeholder,
textarea.wpcf7-not-valid::-moz-placeholder {
    color: red
}

input.error:-moz-placeholder,
input.wpcf7-not-valid:-moz-placeholder,
textarea.error:-moz-placeholder,
textarea.wpcf7-not-valid:-moz-placeholder {
    color: red
}

input.error:-ms-input-placeholder,
input.wpcf7-not-valid:-ms-input-placeholder,
textarea.error:-ms-input-placeholder,
textarea.wpcf7-not-valid:-ms-input-placeholder {
    color: red
}

input.error::-webkit-input-placeholder,
input.wpcf7-not-valid::-webkit-input-placeholder,
textarea.error::-webkit-input-placeholder,
textarea.wpcf7-not-valid::-webkit-input-placeholder {
    color: red
}

div.wpcf7-response-output {
    margin: 0
}

input[type=checkbox],
input[type=radio] {
    display: inline
}

input[type=checkbox],
input[type=radio] {
    width: auto;
    height: auto;
    margin-bottom: 10px;
    margin-right: 0;
    margin-left: 0
}

input[type=checkbox] {
    -webkit-appearance: checkbox
}

input[type=radio] {
    -webkit-appearance: radio
}

button,
input[type=email],
input[type=password],
input[type=submit],
input[type=tel],
input[type=text],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

input[type=file] {
    margin-bottom: 10px;
    border: none;
    height: auto
}

select {
    margin-bottom: 10px;
    width: 100%;
    height: 30px
}

span.wpcf7-list-item {
    display: inline-block;
    margin: 0 1em 0 0
}

ul {
    list-style: disc;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 0;
    list-style-position: inside
}

ol {
    list-style: decimal;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 0;
    list-style-position: inside
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px
}

code {
    font-family: Roboto;
    padding: 0 4px;
    font-style: italic;
    color: #999;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    overflow: auto;
    max-width: 100%;
    white-space: nowrap
}

pre {
    background: #f6f6f6;
    font-family: Roboto;
    margin: 30px 0;
    padding: 30px;
    max-width: 100%;
    overflow: auto;
    white-space: pre
}

ins,
mark {
    background: #eee;
    text-decoration: none
}

table {
    width: 100%;
    margin: 30px 0;
    padding: 0;
    border-collapse: collapse;
    background: #fcfcfc
}

table caption {
    padding: 6px 0;
    background: #f6f6f6;
    font-size: 12px
}

th {
    color: #999;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    border: 4px solid #fff;
    text-transform: uppercase
}

th a {
    color: #999
}

td {
    border: 4px solid #fff;
    padding: 10px 20px;
    color: #363636;
    font-size: 13px;
    background: #f6f6f6
}

td.pad {
    background: 0 0
}

dt {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700
}

dd {
    margin-top: 0;
    margin-bottom: 30px;
    margin-left: 30px
}

.align-center {
    text-align: center !important
}

.align-right {
    text-align: right !important
}

.align-left {
    text-align: left !important
}

.pull-right {
    float: right !important
}

.pull-left {
    float: left !important
}

.pull-none {
    float: none !important
}

.alignright {
    margin-left: 20px !important;
    float: right !important;
    max-width: 50% !important
}

.alignleft {
    margin-right: 20px !important;
    float: left !important;
    max-width: 50% !important
}

.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important
}

.full-width {
    max-width: 100% !important;
    width: 100% !important
}

.full-max-width {
    max-width: 100% !important;
    width: auto !important
}

.centrize {
    display: table !important;
    table-layout: fixed !important;
    height: 100% !important;
    position: relative !important;
    top: 0;
    left: 0;
    z-index: 2
}

.vertical-center {
    display: table-cell !important;
    vertical-align: middle !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-regular {
    font-weight: 400 !important
}

.text-bold {
    font-weight: 700 !important
}

.text-italic {
    font-style: italic !important
}

blockquote {
    font-family: Roboto
}

@-webkit-keyframes loading {
    0% {
        height: 0;
        top: 0;
        bottom: auto
    }
    50% {
        height: 100%;
        top: 0;
        bottom: auto
    }
    51% {
        height: 100%;
        top: 0;
        bottom: auto
    }
    52% {
        height: 100%;
        top: auto;
        bottom: 0
    }
    100% {
        height: 0;
        top: auto;
        bottom: 0
    }
}

@keyframes loading {
    0% {
        height: 0;
        top: 0;
        bottom: auto
    }
    50% {
        height: 100%;
        top: 0;
        bottom: auto
    }
    51% {
        height: 100%;
        top: 0;
        bottom: auto
    }
    52% {
        height: 100%;
        top: auto;
        bottom: 0
    }
    100% {
        height: 0;
        top: auto;
        bottom: 0
    }
}

@keyframes mouse-anim {
    0% {
        top: 0
    }
    50% {
        top: 10px
    }
    100% {
        top: 0
    }
}

.header {
    padding: 50px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: all .7s cubic-bezier(.165, .85, .45, 1);
    -moz-transition: all .7s cubic-bezier(.165, .85, .45, 1);
    -webkit-transition: all .7s cubic-bezier(.165, .85, .45, 1);
    -o-transition: all .7s cubic-bezier(.165, .85, .45, 1)
}

.header.fixed {
    padding-top: 30px;
    padding-bottom: 30px;
    top: 0;
    background: #101010
}

.header:after {
    content: '';
    position: relative;
    display: block;
    clear: both
}

.header .head-top {
    position: relative
}

.header .menu-btn {
    position: relative;
    margin: 0 auto;
    width: 30px;
    height: 20px
}

.header .menu-btn:after,
.header .menu-btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s
}

.header .menu-btn:after {
    top: auto;
    bottom: 0
}

.header .menu-btn span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: -1px;
    transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s
}

.header .logo {
    position: absolute;
    overflow: hidden;
    top: 50%;
    width: 150px;
    font-size: 16px;
    line-height: 2.5;
    font-weight: 600;
    color: rgba(255, 255, 255, .5);
    white-space: nowrap;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%)
}

.header .logo img {
    max-width: 100%;
    /* width: auto; */
    height: auto;
    display: block
}

.header .logo a {
    color: rgba(255, 255, 255, .5);
    text-decoration: none;
    display: block
}

.header .logo strong {
    color: #fff
}

.header .top-menu {
    text-align: right
}

.header .top-menu .menu-top-menu-container {
    display: inline-block
}

.header .top-menu ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.header .top-menu ul li {
    margin-left: 40px;
    display: inline-block;
    vertical-align: top;
    position: relative
}

.header .top-menu ul li a {
    position: relative;
    overflow: hidden;
    display: block;
    font-size: 1em;
    line-height: 2.5;
    color: #fff;
    font-weight: 600;
    opacity: .5;
    text-decoration: none;
    transition: color .7s cubic-bezier(.165, .85, .45, 1);
    -moz-transition: color .7s cubic-bezier(.165, .85, .45, 1);
    -webkit-transition: color .7s cubic-bezier(.165, .85, .45, 1);
    -o-transition: color .7s cubic-bezier(.165, .85, .45, 1)
}

.header .top-menu ul li:hover>.children,
.header .top-menu ul li:hover>.sub-menu {
    opacity: 1;
    pointer-events: auto
}

.header .top-menu ul li.current-menu-item>a,
.header .top-menu ul li:hover>a {
    opacity: 1
}

.header .top-menu ul li.menu-item-has-children>a .mask-lnk:after {
    content: "\f078";
    margin-left: 5px;
    position: relative;
    top: 0;
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: .8em;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1
}

.header .top-menu-nav .children,
.header .top-menu-nav .sub-menu {
    position: absolute;
    left: 0;
    top: 40px;
    padding: 10px 0;
    width: 190px;
    text-align: left;
    background: #1e1e1e;
    z-index: 50;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    box-shadow: 0 5px 50px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 5px 50px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 5px 50px rgba(0, 0, 0, .1);
    -khtml-box-shadow: 0 5px 50px rgba(0, 0, 0, .1)
}

.header .top-menu ul li:last-child .sub-menu,
.header .top-menu-nav .children {
    right: 0;
    left: auto
}

.header .top-menu-nav .children:before,
.header .top-menu-nav .sub-menu:before {
    content: '';
    position: absolute;
    left: 10px;
    top: -12px;
    border: 6px solid transparent;
    border-bottom: 6px solid #1e1e1e
}

.header .top-menu ul li:last-child .sub-menu:before,
.header .top-menu-nav .children:before {
    right: 10px;
    left: auto
}

.header .top-menu-nav .children li,
.header .top-menu-nav .sub-menu li {
    margin-left: 0;
    padding: 8px 20px;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, .07);
    height: auto
}

.header .top-menu-nav .children li:last-child,
.header .top-menu-nav .sub-menu li:last-child {
    border-bottom: none
}

.header .top-menu-nav .children li a,
.header .top-menu-nav .sub-menu li a {
    margin-top: 0;
    display: block;
    font-size: 12px;
    color: #fff;
    opacity: .5
}

.header .top-menu-nav .children li .children,
.header .top-menu-nav .sub-menu li .sub-menu {
    left: 100%;
    top: -10px
}

.header .top-menu-nav .children li .children:before,
.header .top-menu-nav .sub-menu li .sub-menu:before {
    content: '';
    position: absolute;
    left: -12px;
    top: 16px;
    border: 6px solid transparent;
    border-right: 6px solid #fff;
    border-bottom: 6px solid transparent
}

.background-enabled .header .top-menu ul li.current-menu-item>a {
    opacity: 1
}

.hover-masks .mask-lnk,
.hover-masks-logo .mask-lnk {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transition: opacity .7s cubic-bezier(.165, .85, .45, 1), color .7s cubic-bezier(.165, .85, .45, 1), transform .7s cubic-bezier(.165, .85, .45, 1);
    transition: opacity .7s cubic-bezier(.165, .85, .45, 1), color .7s cubic-bezier(.165, .85, .45, 1), transform .7s cubic-bezier(.165, .85, .45, 1)
}

.hover-masks .mask-lnk.mask-lnk-hover,
.hover-masks-logo .mask-lnk.mask-lnk-hover {
    position: absolute;
    opacity: 0;
    transform: translateY(32px);
    -webkit-transform: translateY(32px);
    -moz-transform: translateY(32px);
    -o-transform: translateY(32px)
}

.hover-masks a:hover .mask-lnk,
.hover-masks-logo a:hover .mask-lnk {
    opacity: 1;
    transform: translateY(-32px);
    -webkit-transform: translateY(-32px);
    -moz-transform: translateY(-32px);
    -o-transform: translateY(-32px)
}

.hover-masks a:hover .mask-lnk.mask-lnk-hover,
.hover-masks-logo a:hover .mask-lnk.mask-lnk-hover {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0)
}

.header.active {
    padding-top: 4vw;
    top: 0;
    overflow: auto;
    height: 100%;
    background: #101010;
    -webkit-overflow-scrolling: touch
}

.header.active .menu-btn:before {
    opacity: 0
}

.header.active .menu-btn:after {
    bottom: 9px !important;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg)
}

.header.active .menu-btn span {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg)
}

.header.active .top-menu {
    height: auto;
    opacity: 1;
    visibility: visible
}

.header.active .top-menu ul li .children,
.header.active .top-menu ul li .sub-menu {
    display: block
}

.header.active .top-menu ul>li>ul li a {
    line-height: 20px
}

@media (max-width:1199px) {
    .header .logo {
        position: relative;
        top: 0;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0)
    }
    .header .menu-btn {
        display: block;
        transform: translateY(50%);
        -webkit-transform: translateY(50%);
        -moz-transform: translateY(50%);
        -o-transform: translateY(50%)
    }
    .header .top-menu-nav {
        display: block
    }
    .header .top-menu {
        position: relative;
        top: 0;
        left: 0;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        float: none;
        width: auto;
        height: 0;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity .35s cubic-bezier(.165, .85, .45, 1);
        -moz-transition: opacity .35s cubic-bezier(.165, .85, .45, 1);
        -webkit-transition: opacity .35s cubic-bezier(.165, .85, .45, 1);
        -o-transition: opacity .35s cubic-bezier(.165, .85, .45, 1)
    }
    .header.active .top-menu {
        margin-top: 20px
    }
    .header .top-menu .menu-top-menu-container {
        display: block
    }
    .header .top-menu ul li {
        margin-left: 0;
        display: block
    }
    .header .top-menu-nav .children,
    .header .top-menu-nav .sub-menu {
        position: relative;
        left: 0;
        top: 0;
        margin: 20px 0;
        width: auto;
        text-align: center;
        pointer-events: none;
        display: block;
        opacity: 0;
        visibility: hidden
    }
    .header.active .top-menu-nav .children,
    .header.active .top-menu-nav .sub-menu {
        opacity: 1;
        visibility: visible;
        pointer-events: auto
    }
    .header .top-menu-nav .children:before,
    .header .top-menu-nav .sub-menu:before {
        margin-left: -6px;
        left: 50%
    }
    .header .top-menu-nav .children li .children,
    .header .top-menu-nav .sub-menu li .sub-menu {
        left: 0;
        top: 0;
        margin: 0 -20px;
        background: #f4f4f4;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        -khtml-box-shadow: none
    }
    .header .top-menu-nav .children li .children:before,
    .header .top-menu-nav .sub-menu li .sub-menu:before {
        left: 50%;
        top: -12px;
        border: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff
    }
    .header .top-menu ul li:last-child .sub-menu:before,
    .header .top-menu-nav .children:before {
        right: 50%;
        left: auto;
        margin-left: 0;
        margin-right: -6px
    }
    .header .menu-btn {
        display: block;
        float: right
    }
}

@media (max-width:720px) {
    .header {
        margin: 0;
        padding: 0 30px;
        top: 30px
    }
    .header .logo {
        padding: 0;
        max-width: 240px
    }
    .header.active .top-menu {
        padding-bottom: 30px
    }
    .wp-block-media-text {
        display: block
    }
    .wp-block-media-text .wp-block-media-text__content {
        padding: 0
    }
}

.section {
    position: relative;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 70px;
    padding-bottom: 70px;
    z-index: 12
}

.section:first-child {
    padding-top: 150px
}

.section .content {
    position: relative
}

.section .content .portfolio-cols .title {
    margin-left: 0
}

.section .content-box {
    margin-right: -1px;
    padding: 35px;
    font-size: 16px;
    background: #101010;
    border: 1px solid rgba(255, 255, 255, .07)
}

.section .content-box .single-post-text>:first-child {
    margin-top: 0
}

.section .title {
    position: relative;
    margin: 0 0 35px 35px
}

.section .title .title_inner {
    display: inline-block;
    vertical-align: top;
    position: relative;
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s
}

@media (max-width:1199px) {
    .section {
        padding-left: 50px;
        padding-right: 50px
    }
}

@media (max-width:720px) {
    .section {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 30px;
        padding-right: 30px
    }
    .section .title {
        margin: 0 0 30px 0
    }
}

.section.started {
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    height: 100vh;
    z-index: 11
}

.section.started.personal {
    z-index: 12
}

.section.started .started-content .logo {
    position: relative;
    display: inline-block;
    top: -100px;
    left: -300px;
    width: 740px;
    height: 600px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-clip-path: polygon(31% 0, 100% 25%, 61% 100%, 0 93%);
    clip-path: polygon(31% 0, 100% 25%, 61% 100%, 0 93%)
}

.section.started .started-content .h-title {
    margin: 0;
    position: absolute;
    left: calc(20% + 35px);
    bottom: 50px;
    max-width: 560px;
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    word-break: break-word;
    -ms-word-wrap: break-word;
    word-wrap: break-word
}

.section.started .started-content .h-title strong {
    color: #4bffa5
}

.section.started .started-content .h-subtitles {
    font-size: 38px;
    height: 38px;
    opacity: 0
}

.section.started .started-content .h-subtitles.ready {
    opacity: 1
}

.section.started .started-content .h-subtitle,
.section.started .started-content .typed-bread,
.section.started .started-content .typed-subtitle {
    margin-top: -45px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display: block;
    font-size: 90px;
    line-height: 90px;
    font-weight: 500;
    color: #fff;
    text-align: center
}

.section.started .started-content .h-subtitle strong,
.section.started .started-content .typed-subtitle strong {
    color: #4bffa5
}

.section.started.layout-creative {
    margin: 0 auto;
    margin-bottom: 0;
    padding-top: 20%;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 70px;
    width: auto;
    height: auto !important
}

.section.started.layout-creative .started-content {
    padding-left: 0;
    padding-right: 0;
    display: block;
    text-align: left
}

.section.started.layout-creative .started-content .h-title {
    margin: 0 0 5px 33px;
    padding-right: 0;
    position: relative;
    left: 0;
    top: 0;
    bottom: auto;
    max-width: 800px;
    font-size: 44px;
    font-family: Roboto;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0
}

.section.started.layout-creative .started-content .h-subtitle,
.section.started.layout-creative .started-content .typed-bread,
.section.started.layout-creative .started-content .typed-cursor,
.section.started.layout-creative .started-content .typed-subtitle {
    margin-top: 0;
    margin-left: 35px;
    position: relative;
    font-size: 18px;
    line-height: 40px;
    text-align: left;
    color: #999
}

.section.started.layout-creative .started-content .h-subtitle,
.section.started.layout-creative .started-content .typed-subtitle {
    top: 0
}

.section.started.layout-creative .started-content .h-subtitle p {
    font-size: 18px;
    line-height: 40px
}

.section.started.personal {
    text-align: center
}

.section.started.personal .started-content .h-title {
    margin-left: -85px;
    margin-top: 85px;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    width: 560px;
    text-align: left
}

.section.started.personal .started-content .typed-bread,
.section.started.personal .started-content .typed-subtitle {
    margin-top: 0;
    top: auto;
    bottom: 50px;
    left: 20%;
    line-height: 74px;
    text-align: left
}

.section.started.section-title .started-content .h-title {
    margin-top: 0;
    padding-right: 0;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 100%;
    font-size: 7vw;
    line-height: 7vw;
    font-weight: 600;
    text-align: center
}

.section.started.section-title .started-content .h-subtitles {
    height: auto
}

.section.started.section-title .started-content .h-subtitle,
.section.started.section-title .started-content .typed-bread,
.section.started.section-title .started-content .typed-subtitle {
    margin: 0;
    padding-left: 35px;
    position: absolute;
    left: 20%;
    top: auto;
    bottom: 50px;
    width: auto;
    max-width: 560px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    text-align: left
}

.section.started.section-title .started-content .h-subtitle p {
    margin: 0;
    font-size: 20px;
    line-height: 1.5
}

.section.started .mouse_btn {
    font-size: 24px;
    color: #4bffa5;
    text-align: center;
    position: absolute;
    width: 20px;
    height: 40px;
    left: 50%;
    bottom: 7%;
    margin-left: -10px;
    z-index: 2
}

.section.started .mouse_btn .icon {
    position: relative;
    top: 0;
    animation: mouse-anim 1s ease-out 0s infinite
}

.section.started .swiper-nav {
    position: absolute;
    left: calc(20% + 35px);
    bottom: 120px;
    width: 66px;
    height: 24px;
    z-index: 3
}

.section.started .swiper-nav .swiper-button-next,
.section.started .swiper-nav .swiper-button-prev {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background: 0 0;
    font-size: 22px;
    color: #fff;
    text-decoration: none;
    transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s
}

.section.started+.section,
.section.started.layout-creative+.section {
    margin-top: 70px
}

.background-enabled .section.started.layout-creative .started-content .typed-bread,
.background-enabled .section.started.section-title .started-content .typed-bread,
.section.started.layout-creative .started-content .typed-bread,
.section.started.section-title .started-content .typed-bread {
    top: auto;
    font-size: 16px;
    line-height: 1.9;
    color: rgba(255, 255, 255, .5);
    font-weight: 400;
    opacity: 1
}

.section.started.layout-creative .started-content .typed-bread a,
.section.started.section-title .started-content .typed-bread a {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    opacity: 1
}

.section.started .started-content .typed-cursor,
.section.started.layout-creative .mouse_btn,
.section.started.layout-post-creative .mouse_btn {
    display: none !important
}

.section.started .swiper-button-prev,
.section.started .swiper-container-rtl .swiper-button-next {
    left: 0;
    right: auto
}

.section.started .swiper-button-next,
.section.started .swiper-container-rtl .swiper-button-prev {
    left: auto;
    right: 0
}

.started-carousel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.started-carousel .swiper-container,
.started-carousel .swiper-wrapper {
    height: 100%
}

.started-carousel .swiper-slide {
    opacity: 1 !important
}

.started-carousel .swiper-slide.first {
    z-index: 2
}

.started-carousel .main-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: polygon(0 0, 20% 0, 20% 0, 40% 0, 40% 0, 60% 0, 60% 0, 80% 0, 80% 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 80% 100%, 80% 100%, 60% 100%, 60% 100%, 40% 100%, 40% 100%, 20% 100%, 20% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, 20% 0, 20% 0, 40% 0, 40% 0, 60% 0, 60% 0, 80% 0, 80% 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 80% 100%, 80% 100%, 60% 100%, 60% 100%, 40% 100%, 40% 100%, 20% 100%, 20% 100%, 0 100%);
    transition: 1s all cubic-bezier(.165, .85, .45, 1) 0s;
    -moz-transition: 1s all cubic-bezier(.165, .85, .45, 1) 0s;
    -webkit-transition: 1s all cubic-bezier(.165, .85, .45, 1) 0s;
    -o-transition: 1s all cubic-bezier(.165, .85, .45, 1) 0s
}

.started-carousel .swiper-slide.swiper-clip-active .main-img {
    clip-path: polygon(0 0, 20% 0, 20% 100%, 40% 100%, 40% 0, 60% 0, 60% 100%, 80% 100%, 80% 0, 100% 0, 100% 0, 100% 100%, 100% 0, 80% 0, 80% 100%, 60% 100%, 60% 0, 40% 0, 40% 100%, 20% 100%, 20% 0, 0 0);
    -webkit-clip-path: polygon(0 0, 20% 0, 20% 100%, 40% 100%, 40% 0, 60% 0, 60% 100%, 80% 100%, 80% 0, 100% 0, 100% 0, 100% 100%, 100% 0, 80% 0, 80% 100%, 60% 100%, 60% 0, 40% 0, 40% 100%, 20% 100%, 20% 0, 0 0)
}

.section.started .mouse_btn {
    left: auto;
    right: calc(20% + 35px);
    margin-left: 0
}

@media (max-width:1199px) {
    .section.started.layout-creative,
    .section.started.layout-post-creative {
        padding-top: 35vw;
        padding-left: 50px;
        padding-right: 50px
    }
    .section.started .started-content .h-title {
        left: 50px
    }
    .section.started .started-content .h-title br {
        display: none
    }
    .section.started .started-content .h-subtitle,
    .section.started .started-content .typed-bread,
    .section.started .started-content .typed-subtitle {
        margin-top: -35px;
        font-size: 60px;
        line-height: 70px
    }
    .section.started.section-title .started-content .h-subtitle,
    .section.started.section-title .started-content .typed-bread,
    .section.started.section-title .started-content .typed-subtitle {
        padding-left: 0;
        left: 50px
    }
    .section.started.personal .started-content .typed-bread,
    .section.started.personal .started-content .typed-subtitle {
        left: 50px;
        width: auto
    }
    .section.started .swiper-nav {
        left: 50px
    }
    .section.started .started-content .logo {
        top: -50px;
        left: -150px;
        width: 640px;
        height: 560px
    }
    .section.started.personal .started-content .h-title {
        margin-left: 15px;
        margin-top: 115px;
        width: 320px
    }
    .section.started.layout-creative .started-content .h-subtitle,
    .section.started.layout-creative .started-content .h-title,
    .section.started.layout-creative .started-content .typed-bread,
    .section.started.layout-creative .started-content .typed-cursor,
    .section.started.layout-creative .started-content .typed-subtitle {
        margin-left: 0
    }
    .section.started .mouse_btn {
        left: auto;
        right: 120px;
        margin-left: 0
    }
}

@media (max-width:720px) {
    .section.started {
        padding: 0 30px
    }
    .section.started.layout-creative,
    .section.started.layout-post-creative {
        padding-top: 50vw;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px
    }
    .section.started .started-content .h-title {
        left: 0;
        bottom: 30px;
        padding-right: 50px;
        font-size: 17px
    }
    .section.started .started-content .h-subtitle,
    .section.started .started-content .typed-bread,
    .section.started .started-content .typed-subtitle {
        margin-top: -20px;
        font-size: 30px;
        line-height: 40px
    }
    .section.started.section-title .started-content .h-title {
        font-size: 10vw;
        line-height: 10vw
    }
    .background-enabled .section.started.layout-creative .started-content .typed-bread,
    .background-enabled .section.started.section-title .started-content .typed-bread,
    .section.started.layout-creative .started-content .typed-bread,
    .section.started.section-title .started-content .typed-bread {
        font-size: 14px
    }
    .section.started.layout-creative .started-content .h-title,
    .section.started.layout-post-creative .started-content .h-title {
        font-size: 23px
    }
    .section.started .started-content .logo {
        position: absolute;
        top: 0;
        left: -30px;
        width: 110vw;
        height: 62vh;
        -webkit-clip-path: polygon(25% 0, 100% 25%, 80% 100%, 0 85%);
        clip-path: polygon(25% 0, 100% 25%, 80% 100%, 0 85%)
    }
    .section.started.personal .started-content .h-title {
        left: 0;
        top: 64vh;
        bottom: auto;
        width: 100%;
        margin-top: 0;
        margin-left: 0
    }
    .section.started.personal .started-content .typed-bread,
    .section.started.personal .started-content .typed-subtitle {
        left: 0;
        bottom: 30px;
        padding-right: 50px;
        font-size: 20px;
        line-height: 30px
    }
    .section.started .swiper-nav {
        left: 30px
    }
    .section.started .mouse_btn {
        right: 50%;
        margin-left: 0;
        margin-right: -10px;
        bottom: 150px
    }
    .section.started.section-title .started-content .h-subtitle,
    .section.started.section-title .started-content .typed-bread,
    .section.started.section-title .started-content .typed-subtitle {
        left: 0
    }
    .section.started+.section,
    .section.started.layout-creative+.section {
        margin-top: 0
    }
}

@media (max-width:340px) {
    .section.started .swiper-nav {
        bottom: 145px
    }
}

@media screen and (orientation:landscape) and (min-device-width:319px) and (max-device-width:1199px) {
    .section.started .started-content .logo {
        position: absolute;
        left: -30px;
        right: -30px;
        top: 0;
        width: auto;
        height: 100%;
        -webkit-clip-path: none;
        clip-path: none
    }
    .section.started.personal .started-content .h-title {
        top: auto;
        left: 50px;
        bottom: 50px;
        width: auto;
        margin-left: 0;
        margin-top: 0;
        margin-right: 50px
    }
    .section.started.personal .started-content .typed-bread,
    .section.started.personal .started-content .typed-subtitle {
        display: none
    }
    .section.started .swiper-nav {
        bottom: 120px
    }
}

@media screen and (orientation:landscape) and (min-device-width:319px) and (max-device-width:670px) {
    .section.started.personal .started-content .h-title {
        left: 0;
        bottom: 30px;
        margin-right: 30px
    }
    .section.started .swiper-nav {
        bottom: 100px
    }
}

.section.about .image {
    float: left;
    width: 160px;
    font-size: 0
}

.section.about .image img {
    width: 100%;
    height: auto
}

.section.about .desc {
    margin-left: 195px
}

.section.about .desc p {
    word-break: break-word
}

.section.about .desc p:first-child {
    margin-top: 0
}

.info-list {
    margin-bottom: 20px
}

.info-list ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.info-list ul li {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin: 0 0 6px 0
}

.info-list ul li strong {
    font-weight: 400;
    color: #4bffa5
}

@media (min-width:1700px) {
    .info-list ul li {
        width: 32%
    }
}

@media (max-width:1199px) {
    .section.about .image {
        width: 120px;
        margin-right: 0;
        margin-bottom: 25px
    }
    .section.about .desc {
        margin-left: 145px
    }
}

@media (max-width:720px) {
    .section.about .image {
        margin: 0 auto 20px auto;
        float: none;
        width: 100px;
        text-align: center
    }
    .section.about .desc {
        margin-left: 0;
        text-align: center
    }
    .info-list ul li {
        width: 100%
    }
}

.section .col:nth-child(odd) {
    padding-right: 35px
}

.section .col:nth-child(even) {
    padding-left: 35px
}

.section .col:nth-child(even) .content-box {
    margin-right: -1px
}

.resume-items .resume-item {
    position: relative;
    margin: 70px 0 0 0
}

.resume-items .resume-item:first-child {
    margin: 0
}

.resume-items .resume-item .date {
    position: relative;
    margin: 0 0 5px 0;
    display: inline-block;
    font-size: 12px;
    color: #999
}

.resume-items .resume-item .name {
    margin: 0 0 15px 0;
    color: #eee;
    font-weight: 500
}

.resume-items .resume-item.active .date {
    color: #4bffa5;
    font-weight: 600
}

@media (max-width:1199px) {
    .section .col:nth-child(odd) {
        padding-right: 20px
    }
    .section .col:nth-child(even) {
        padding-left: 20px
    }
    .resume-items .resume-item {
        margin: 40px 0 0 0
    }
}

@media (max-width:720px) {
    .section .col:nth-child(odd) {
        padding-right: 0;
        margin-bottom: 40px
    }
    .section .col:nth-child(even) {
        padding-left: 0
    }
}

.skills ul {
    margin: -35px 0 0 0;
    padding: 0;
    list-style: none;
    font-size: 0
}

.skills ul li {
    position: relative;
    padding: 35px 0 0 0
}

.skills ul li:after {
    display: none
}

.skills ul li:last-child {
    padding-bottom: 0
}

.skills ul li .name {
    margin: 0 0 10px 0;
    position: relative;
    font-size: 16px;
    color: #eee;
    font-weight: 500
}

.skills ul li .progress {
    display: block;
    height: 4px;
	position: relative;
    width: 100%;
    background: rgba(255, 255, 255, .1)
}

.skills ul li .progress .percentage {
    left: 0;
    position: absolute;
    height: 4px;
    width: 0%;
    background: #4bffa5;
    transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s
}

.skills ul li .progress .percentage .percent {
    position: absolute;
    top: -34px;
    right: 0;
    font-size: 16px;
    font-weight: 400
}

.skills.list ul {
    margin: 0 -35px
}

.skills.list ul li {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 35px;
    padding-right: 35px;
    display: inline-block;
    vertical-align: top;
    width: 50%
}

.skills.list ul li .name {
    padding-left: 35px;
    position: relative
}

.skills.list ul li .name:before {
    content: "\f00c";
    position: absolute;
    left: 0;
    top: 3px;
    display: inline-block;
    color: #4bffa5;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1
}

.skills.list ul li .progress {
    display: none
}

.skills.dotted ul {
    margin-left: -35px;
    margin-right: -35px
}

.skills.dotted ul li {
    padding-left: 35px;
    padding-right: 35px;
    width: 50%;
    display: inline-block;
    vertical-align: top
}

.skills.dotted ul li .progress {
    height: 14px;
    background: 0 0 !important
}

.skills.dotted ul li .progress .percentage {
    position: relative;
    overflow: hidden;
    top: 0;
    height: 14px;
    background: 0 0 !important;
    z-index: 2
}

.skills.dotted ul li .progress .da,
.skills.dotted ul li .progress .dg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 14px
}

.skills.dotted ul li .progress .da span,
.skills.dotted ul li .progress .dg span {
    display: block;
    width: 14px;
    height: 14px;
    background: rgba(255, 255, 255, .1);
    border-radius: 14px;
    -moz-border-radius: 14px;
    -webkit-border-radius: 14px;
    -khtml-border-radius: 14px
}

.skills.dotted ul li .progress .da {
    top: 0
}

.skills.dotted ul li .progress .da span {
    background: #4bffa5
}

.skills.circles ul li {
    margin-top: 35px;
    padding: 0 0 60px 0;
    display: inline-block;
    vertical-align: top;
    width: 25%
}

.skills.circles ul li:last-child {
    padding-bottom: 40px
}

.skills.circles ul li .name {
    margin: 0 0 0 -45px;
    position: absolute;
    top: 105px;
    left: 50%;
    width: 90px;
    text-align: center
}

.skills.circles ul li .progress {
    margin: 0 auto;
    position: relative;
    font-size: 90px;
    width: 90px;
    height: 90px;
    background: #282828;
    border-radius: 90px;
    -moz-border-radius: 90px;
    -webkit-border-radius: 90px;
    -khtml-border-radius: 90px
}

.skills.circles ul li .progress:after {
    content: '';
    position: absolute;
    top: .09em;
    left: .09em;
    width: .82em;
    height: .82em;
    display: block;
    background-color: #101010;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%
}

.skills.circles ul li .progress span {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 16px;
    line-height: 90px;
    text-align: center;
    white-space: nowrap;
    z-index: 2
}

.skills.circles ul li .progress .percentage {
    display: none
}

.skills.circles ul li .progress .slice {
    position: absolute;
    width: 1em;
    height: 1em;
    clip: rect(0, 1em, 1em, .5em)
}

.skills.circles .progress.p100 .slice,
.skills.circles .progress.p51 .slice,
.skills.circles .progress.p52 .slice,
.skills.circles .progress.p53 .slice,
.skills.circles .progress.p54 .slice,
.skills.circles .progress.p55 .slice,
.skills.circles .progress.p56 .slice,
.skills.circles .progress.p57 .slice,
.skills.circles .progress.p58 .slice,
.skills.circles .progress.p59 .slice,
.skills.circles .progress.p60 .slice,
.skills.circles .progress.p61 .slice,
.skills.circles .progress.p62 .slice,
.skills.circles .progress.p63 .slice,
.skills.circles .progress.p64 .slice,
.skills.circles .progress.p65 .slice,
.skills.circles .progress.p66 .slice,
.skills.circles .progress.p67 .slice,
.skills.circles .progress.p68 .slice,
.skills.circles .progress.p69 .slice,
.skills.circles .progress.p70 .slice,
.skills.circles .progress.p71 .slice,
.skills.circles .progress.p72 .slice,
.skills.circles .progress.p73 .slice,
.skills.circles .progress.p74 .slice,
.skills.circles .progress.p75 .slice,
.skills.circles .progress.p76 .slice,
.skills.circles .progress.p77 .slice,
.skills.circles .progress.p78 .slice,
.skills.circles .progress.p79 .slice,
.skills.circles .progress.p80 .slice,
.skills.circles .progress.p81 .slice,
.skills.circles .progress.p82 .slice,
.skills.circles .progress.p83 .slice,
.skills.circles .progress.p84 .slice,
.skills.circles .progress.p85 .slice,
.skills.circles .progress.p86 .slice,
.skills.circles .progress.p87 .slice,
.skills.circles .progress.p88 .slice,
.skills.circles .progress.p89 .slice,
.skills.circles .progress.p90 .slice,
.skills.circles .progress.p91 .slice,
.skills.circles .progress.p92 .slice,
.skills.circles .progress.p93 .slice,
.skills.circles .progress.p94 .slice,
.skills.circles .progress.p95 .slice,
.skills.circles .progress.p96 .slice,
.skills.circles .progress.p97 .slice,
.skills.circles .progress.p98 .slice,
.skills.circles .progress.p99 .slice {
    clip: rect(auto, auto, auto, auto)
}

.skills.circles .progress .bar,
.skills.circles .progress.p100 .fill,
.skills.circles .progress.p51 .fill,
.skills.circles .progress.p52 .fill,
.skills.circles .progress.p53 .fill,
.skills.circles .progress.p54 .fill,
.skills.circles .progress.p55 .fill,
.skills.circles .progress.p56 .fill,
.skills.circles .progress.p57 .fill,
.skills.circles .progress.p58 .fill,
.skills.circles .progress.p59 .fill,
.skills.circles .progress.p60 .fill,
.skills.circles .progress.p61 .fill,
.skills.circles .progress.p62 .fill,
.skills.circles .progress.p63 .fill,
.skills.circles .progress.p64 .fill,
.skills.circles .progress.p65 .fill,
.skills.circles .progress.p66 .fill,
.skills.circles .progress.p67 .fill,
.skills.circles .progress.p68 .fill,
.skills.circles .progress.p69 .fill,
.skills.circles .progress.p70 .fill,
.skills.circles .progress.p71 .fill,
.skills.circles .progress.p72 .fill,
.skills.circles .progress.p73 .fill,
.skills.circles .progress.p74 .fill,
.skills.circles .progress.p75 .fill,
.skills.circles .progress.p76 .fill,
.skills.circles .progress.p77 .fill,
.skills.circles .progress.p78 .fill,
.skills.circles .progress.p79 .fill,
.skills.circles .progress.p80 .fill,
.skills.circles .progress.p81 .fill,
.skills.circles .progress.p82 .fill,
.skills.circles .progress.p83 .fill,
.skills.circles .progress.p84 .fill,
.skills.circles .progress.p85 .fill,
.skills.circles .progress.p86 .fill,
.skills.circles .progress.p87 .fill,
.skills.circles .progress.p88 .fill,
.skills.circles .progress.p89 .fill,
.skills.circles .progress.p90 .fill,
.skills.circles .progress.p91 .fill,
.skills.circles .progress.p92 .fill,
.skills.circles .progress.p93 .fill,
.skills.circles .progress.p94 .fill,
.skills.circles .progress.p95 .fill,
.skills.circles .progress.p96 .fill,
.skills.circles .progress.p97 .fill,
.skills.circles .progress.p98 .fill,
.skills.circles .progress.p99 .fill {
    position: absolute;
    width: .82em;
    height: .82em;
    clip: rect(0, .5em, 1em, 0);
    border: .09em solid #4bffa5;
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%
}

.skills.circles .progress.p100 .bar:after,
.skills.circles .progress.p100 .fill,
.skills.circles .progress.p51 .bar:after,
.skills.circles .progress.p51 .fill,
.skills.circles .progress.p52 .bar:after,
.skills.circles .progress.p52 .fill,
.skills.circles .progress.p53 .bar:after,
.skills.circles .progress.p53 .fill,
.skills.circles .progress.p54 .bar:after,
.skills.circles .progress.p54 .fill,
.skills.circles .progress.p55 .bar:after,
.skills.circles .progress.p55 .fill,
.skills.circles .progress.p56 .bar:after,
.skills.circles .progress.p56 .fill,
.skills.circles .progress.p57 .bar:after,
.skills.circles .progress.p57 .fill,
.skills.circles .progress.p58 .bar:after,
.skills.circles .progress.p58 .fill,
.skills.circles .progress.p59 .bar:after,
.skills.circles .progress.p59 .fill,
.skills.circles .progress.p60 .bar:after,
.skills.circles .progress.p60 .fill,
.skills.circles .progress.p61 .bar:after,
.skills.circles .progress.p61 .fill,
.skills.circles .progress.p62 .bar:after,
.skills.circles .progress.p62 .fill,
.skills.circles .progress.p63 .bar:after,
.skills.circles .progress.p63 .fill,
.skills.circles .progress.p64 .bar:after,
.skills.circles .progress.p64 .fill,
.skills.circles .progress.p65 .bar:after,
.skills.circles .progress.p65 .fill,
.skills.circles .progress.p66 .bar:after,
.skills.circles .progress.p66 .fill,
.skills.circles .progress.p67 .bar:after,
.skills.circles .progress.p67 .fill,
.skills.circles .progress.p68 .bar:after,
.skills.circles .progress.p68 .fill,
.skills.circles .progress.p69 .bar:after,
.skills.circles .progress.p69 .fill,
.skills.circles .progress.p70 .bar:after,
.skills.circles .progress.p70 .fill,
.skills.circles .progress.p71 .bar:after,
.skills.circles .progress.p71 .fill,
.skills.circles .progress.p72 .bar:after,
.skills.circles .progress.p72 .fill,
.skills.circles .progress.p73 .bar:after,
.skills.circles .progress.p73 .fill,
.skills.circles .progress.p74 .bar:after,
.skills.circles .progress.p74 .fill,
.skills.circles .progress.p75 .bar:after,
.skills.circles .progress.p75 .fill,
.skills.circles .progress.p76 .bar:after,
.skills.circles .progress.p76 .fill,
.skills.circles .progress.p77 .bar:after,
.skills.circles .progress.p77 .fill,
.skills.circles .progress.p78 .bar:after,
.skills.circles .progress.p78 .fill,
.skills.circles .progress.p79 .bar:after,
.skills.circles .progress.p79 .fill,
.skills.circles .progress.p80 .bar:after,
.skills.circles .progress.p80 .fill,
.skills.circles .progress.p81 .bar:after,
.skills.circles .progress.p81 .fill,
.skills.circles .progress.p82 .bar:after,
.skills.circles .progress.p82 .fill,
.skills.circles .progress.p83 .bar:after,
.skills.circles .progress.p83 .fill,
.skills.circles .progress.p84 .bar:after,
.skills.circles .progress.p84 .fill,
.skills.circles .progress.p85 .bar:after,
.skills.circles .progress.p85 .fill,
.skills.circles .progress.p86 .bar:after,
.skills.circles .progress.p86 .fill,
.skills.circles .progress.p87 .bar:after,
.skills.circles .progress.p87 .fill,
.skills.circles .progress.p88 .bar:after,
.skills.circles .progress.p88 .fill,
.skills.circles .progress.p89 .bar:after,
.skills.circles .progress.p89 .fill,
.skills.circles .progress.p90 .bar:after,
.skills.circles .progress.p90 .fill,
.skills.circles .progress.p91 .bar:after,
.skills.circles .progress.p91 .fill,
.skills.circles .progress.p92 .bar:after,
.skills.circles .progress.p92 .fill,
.skills.circles .progress.p93 .bar:after,
.skills.circles .progress.p93 .fill,
.skills.circles .progress.p94 .bar:after,
.skills.circles .progress.p94 .fill,
.skills.circles .progress.p95 .bar:after,
.skills.circles .progress.p95 .fill,
.skills.circles .progress.p96 .bar:after,
.skills.circles .progress.p96 .fill,
.skills.circles .progress.p97 .bar:after,
.skills.circles .progress.p97 .fill,
.skills.circles .progress.p98 .bar:after,
.skills.circles .progress.p98 .fill,
.skills.circles .progress.p99 .bar:after,
.skills.circles .progress.p99 .fill {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg)
}

.skills.circles .progress *,
.skills.circles .progress :after,
.skills.circles .progress :before {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

.skills.circles .progress.p1 .bar {
    transform: rotate(3.6deg);
    -webkit-transform: rotate(3.6deg);
    -moz-transform: rotate(3.6deg);
    -o-transform: rotate(3.6deg)
}

.skills.circles .progress.p2 .bar {
    transform: rotate(7.2deg);
    -webkit-transform: rotate(7.2deg);
    -moz-transform: rotate(7.2deg);
    -o-transform: rotate(7.2deg)
}

.skills.circles .progress.p3 .bar {
    transform: rotate(10.8deg);
    -webkit-transform: rotate(10.8deg);
    -moz-transform: rotate(10.8deg);
    -o-transform: rotate(10.8deg)
}

.skills.circles .progress.p4 .bar {
    transform: rotate(14.4deg);
    -webkit-transform: rotate(14.4deg);
    -moz-transform: rotate(14.4deg);
    -o-transform: rotate(14.4deg)
}

.skills.circles .progress.p5 .bar {
    transform: rotate(18deg);
    -webkit-transform: rotate(18deg);
    -moz-transform: rotate(18deg);
    -o-transform: rotate(18deg)
}

.skills.circles .progress.p6 .bar {
    transform: rotate(21.6deg);
    -webkit-transform: rotate(21.6deg);
    -moz-transform: rotate(21.6deg);
    -o-transform: rotate(21.6deg)
}

.skills.circles .progress.p7 .bar {
    transform: rotate(25.2deg);
    -webkit-transform: rotate(25.2deg);
    -moz-transform: rotate(25.2deg);
    -o-transform: rotate(25.2deg)
}

.skills.circles .progress.p8 .bar {
    transform: rotate(28.8deg);
    -webkit-transform: rotate(28.8deg);
    -moz-transform: rotate(28.8deg);
    -o-transform: rotate(28.8deg)
}

.skills.circles .progress.p9 .bar {
    transform: rotate(32.4deg);
    -webkit-transform: rotate(32.4deg);
    -moz-transform: rotate(32.4deg);
    -o-transform: rotate(32.4deg)
}

.skills.circles .progress.p10 .bar {
    transform: rotate(36deg);
    -webkit-transform: rotate(36deg);
    -moz-transform: rotate(36deg);
    -o-transform: rotate(36deg)
}

.skills.circles .progress.p11 .bar {
    transform: rotate(39.6deg);
    -webkit-transform: rotate(39.6deg);
    -moz-transform: rotate(39.6deg);
    -o-transform: rotate(39.6deg)
}

.skills.circles .progress.p12 .bar {
    transform: rotate(43.2deg);
    -webkit-transform: rotate(43.2deg);
    -moz-transform: rotate(43.2deg);
    -o-transform: rotate(43.2deg)
}

.skills.circles .progress.p13 .bar {
    transform: rotate(46.8deg);
    -webkit-transform: rotate(46.8deg);
    -moz-transform: rotate(46.8deg);
    -o-transform: rotate(46.8deg)
}

.skills.circles .progress.p14 .bar {
    transform: rotate(50.4deg);
    -webkit-transform: rotate(50.4deg);
    -moz-transform: rotate(50.4deg);
    -o-transform: rotate(50.4deg)
}

.skills.circles .progress.p15 .bar {
    transform: rotate(54deg);
    -webkit-transform: rotate(54deg);
    -moz-transform: rotate(54deg);
    -o-transform: rotate(54deg)
}

.skills.circles .progress.p16 .bar {
    transform: rotate(57.6deg);
    -webkit-transform: rotate(57.6deg);
    -moz-transform: rotate(57.6deg);
    -o-transform: rotate(57.6deg)
}

.skills.circles .progress.p17 .bar {
    transform: rotate(61.2deg);
    -webkit-transform: rotate(61.2deg);
    -moz-transform: rotate(61.2deg);
    -o-transform: rotate(61.2deg)
}

.skills.circles .progress.p18 .bar {
    transform: rotate(64.8deg);
    -webkit-transform: rotate(64.8deg);
    -moz-transform: rotate(64.8deg);
    -o-transform: rotate(64.8deg)
}

.skills.circles .progress.p19 .bar {
    transform: rotate(68.4deg);
    -webkit-transform: rotate(68.4deg);
    -moz-transform: rotate(68.4deg);
    -o-transform: rotate(68.4deg)
}

.skills.circles .progress.p20 .bar {
    transform: rotate(72deg);
    -webkit-transform: rotate(72deg);
    -moz-transform: rotate(72deg);
    -o-transform: rotate(72deg)
}

.skills.circles .progress.p21 .bar {
    transform: rotate(75.6deg);
    -webkit-transform: rotate(75.6deg);
    -moz-transform: rotate(75.6deg);
    -o-transform: rotate(75.6deg)
}

.skills.circles .progress.p22 .bar {
    transform: rotate(79.2deg);
    -webkit-transform: rotate(79.2deg);
    -moz-transform: rotate(79.2deg);
    -o-transform: rotate(79.2deg)
}

.skills.circles .progress.p23 .bar {
    transform: rotate(82.8deg);
    -webkit-transform: rotate(82.8deg);
    -moz-transform: rotate(82.8deg);
    -o-transform: rotate(82.8deg)
}

.skills.circles .progress.p24 .bar {
    transform: rotate(86.4deg);
    -webkit-transform: rotate(86.4deg);
    -moz-transform: rotate(86.4deg);
    -o-transform: rotate(86.4deg)
}

.skills.circles .progress.p25 .bar {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg)
}

.skills.circles .progress.p26 .bar {
    transform: rotate(93.6deg);
    -webkit-transform: rotate(93.6deg);
    -moz-transform: rotate(93.6deg);
    -o-transform: rotate(93.6deg)
}

.skills.circles .progress.p27 .bar {
    transform: rotate(97.2deg);
    -webkit-transform: rotate(97.2deg);
    -moz-transform: rotate(97.2deg);
    -o-transform: rotate(97.2deg)
}

.skills.circles .progress.p28 .bar {
    transform: rotate(100.8deg);
    -webkit-transform: rotate(100.8deg);
    -moz-transform: rotate(100.8deg);
    -o-transform: rotate(100.8deg)
}

.skills.circles .progress.p29 .bar {
    transform: rotate(104.4deg);
    -webkit-transform: rotate(104.4deg);
    -moz-transform: rotate(104.4deg);
    -o-transform: rotate(104.4deg)
}

.skills.circles .progress.p30 .bar {
    transform: rotate(108deg);
    -webkit-transform: rotate(108deg);
    -moz-transform: rotate(108deg);
    -o-transform: rotate(108deg)
}

.skills.circles .progress.p31 .bar {
    transform: rotate(111.6deg);
    -webkit-transform: rotate(111.6deg);
    -moz-transform: rotate(111.6deg);
    -o-transform: rotate(111.6deg)
}

.skills.circles .progress.p32 .bar {
    transform: rotate(115.2deg);
    -webkit-transform: rotate(115.2deg);
    -moz-transform: rotate(115.2deg);
    -o-transform: rotate(115.2deg)
}

.skills.circles .progress.p33 .bar {
    transform: rotate(118.8deg);
    -webkit-transform: rotate(118.8deg);
    -moz-transform: rotate(118.8deg);
    -o-transform: rotate(118.8deg)
}

.skills.circles .progress.p34 .bar {
    transform: rotate(122.4deg);
    -webkit-transform: rotate(122.4deg);
    -moz-transform: rotate(122.4deg);
    -o-transform: rotate(122.4deg)
}

.skills.circles .progress.p35 .bar {
    transform: rotate(126deg);
    -webkit-transform: rotate(126deg);
    -moz-transform: rotate(126deg);
    -o-transform: rotate(126deg)
}

.skills.circles .progress.p36 .bar {
    transform: rotate(129.6deg);
    -webkit-transform: rotate(129.6deg);
    -moz-transform: rotate(129.6deg);
    -o-transform: rotate(129.6deg)
}

.skills.circles .progress.p37 .bar {
    transform: rotate(133.2deg);
    -webkit-transform: rotate(133.2deg);
    -moz-transform: rotate(133.2deg);
    -o-transform: rotate(133.2deg)
}

.skills.circles .progress.p38 .bar {
    transform: rotate(136.8deg);
    -webkit-transform: rotate(136.8deg);
    -moz-transform: rotate(136.8deg);
    -o-transform: rotate(136.8deg)
}

.skills.circles .progress.p39 .bar {
    transform: rotate(140.4deg);
    -webkit-transform: rotate(140.4deg);
    -moz-transform: rotate(140.4deg);
    -o-transform: rotate(140.4deg)
}

.skills.circles .progress.p40 .bar {
    transform: rotate(144deg);
    -webkit-transform: rotate(144deg);
    -moz-transform: rotate(144deg);
    -o-transform: rotate(144deg)
}

.skills.circles .progress.p41 .bar {
    transform: rotate(147.6deg);
    -webkit-transform: rotate(147.6deg);
    -moz-transform: rotate(147.6deg);
    -o-transform: rotate(147.6deg)
}

.skills.circles .progress.p42 .bar {
    transform: rotate(151.2deg);
    -webkit-transform: rotate(151.2deg);
    -moz-transform: rotate(151.2deg);
    -o-transform: rotate(151.2deg)
}

.skills.circles .progress.p43 .bar {
    transform: rotate(154.8deg);
    -webkit-transform: rotate(154.8deg);
    -moz-transform: rotate(154.8deg);
    -o-transform: rotate(154.8deg)
}

.skills.circles .progress.p44 .bar {
    transform: rotate(158.4deg);
    -webkit-transform: rotate(158.4deg);
    -moz-transform: rotate(158.4deg);
    -o-transform: rotate(158.4deg)
}

.skills.circles .progress.p45 .bar {
    transform: rotate(162deg);
    -webkit-transform: rotate(162deg);
    -moz-transform: rotate(162deg);
    -o-transform: rotate(162deg)
}

.skills.circles .progress.p46 .bar {
    transform: rotate(165.6deg);
    -webkit-transform: rotate(165.6deg);
    -moz-transform: rotate(165.6deg);
    -o-transform: rotate(165.6deg)
}

.skills.circles .progress.p47 .bar {
    transform: rotate(169.2deg);
    -webkit-transform: rotate(169.2deg);
    -moz-transform: rotate(169.2deg);
    -o-transform: rotate(169.2deg)
}

.skills.circles .progress.p48 .bar {
    transform: rotate(172.8deg);
    -webkit-transform: rotate(172.8deg);
    -moz-transform: rotate(172.8deg);
    -o-transform: rotate(172.8deg)
}

.skills.circles .progress.p49 .bar {
    transform: rotate(176.4deg);
    -webkit-transform: rotate(176.4deg);
    -moz-transform: rotate(176.4deg);
    -o-transform: rotate(176.4deg)
}

.skills.circles .progress.p50 .bar {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg)
}

.skills.circles .progress.p51 .bar {
    transform: rotate(183.6deg);
    -webkit-transform: rotate(183.6deg);
    -moz-transform: rotate(183.6deg);
    -o-transform: rotate(183.6deg)
}

.skills.circles .progress.p52 .bar {
    transform: rotate(187.2deg);
    -webkit-transform: rotate(187.2deg);
    -moz-transform: rotate(187.2deg);
    -o-transform: rotate(187.2deg)
}

.skills.circles .progress.p53 .bar {
    transform: rotate(190.8deg);
    -webkit-transform: rotate(190.8deg);
    -moz-transform: rotate(190.8deg);
    -o-transform: rotate(190.8deg)
}

.skills.circles .progress.p54 .bar {
    transform: rotate(194.4deg);
    -webkit-transform: rotate(194.4deg);
    -moz-transform: rotate(194.4deg);
    -o-transform: rotate(194.4deg)
}

.skills.circles .progress.p55 .bar {
    transform: rotate(198deg);
    -webkit-transform: rotate(198deg);
    -moz-transform: rotate(198deg);
    -o-transform: rotate(198deg)
}

.skills.circles .progress.p56 .bar {
    transform: rotate(201.6deg);
    -webkit-transform: rotate(201.6deg);
    -moz-transform: rotate(201.6deg);
    -o-transform: rotate(201.6deg)
}

.skills.circles .progress.p57 .bar {
    transform: rotate(205.2deg);
    -webkit-transform: rotate(205.2deg);
    -moz-transform: rotate(205.2deg);
    -o-transform: rotate(205.2deg)
}

.skills.circles .progress.p58 .bar {
    transform: rotate(208.8deg);
    -webkit-transform: rotate(208.8deg);
    -moz-transform: rotate(208.8deg);
    -o-transform: rotate(208.8deg)
}

.skills.circles .progress.p59 .bar {
    transform: rotate(212.4deg);
    -webkit-transform: rotate(212.4deg);
    -moz-transform: rotate(212.4deg);
    -o-transform: rotate(212.4deg)
}

.skills.circles .progress.p60 .bar {
    transform: rotate(216deg);
    -webkit-transform: rotate(216deg);
    -moz-transform: rotate(216deg);
    -o-transform: rotate(216deg)
}

.skills.circles .progress.p61 .bar {
    transform: rotate(219.6deg);
    -webkit-transform: rotate(219.6deg);
    -moz-transform: rotate(219.6deg);
    -o-transform: rotate(219.6deg)
}

.skills.circles .progress.p62 .bar {
    transform: rotate(223.2deg);
    -webkit-transform: rotate(223.2deg);
    -moz-transform: rotate(223.2deg);
    -o-transform: rotate(223.2deg)
}

.skills.circles .progress.p63 .bar {
    transform: rotate(226.8deg);
    -webkit-transform: rotate(226.8deg);
    -moz-transform: rotate(226.8deg);
    -o-transform: rotate(226.8deg)
}

.skills.circles .progress.p64 .bar {
    transform: rotate(230.4deg);
    -webkit-transform: rotate(230.4deg);
    -moz-transform: rotate(230.4deg);
    -o-transform: rotate(230.4deg)
}

.skills.circles .progress.p65 .bar {
    transform: rotate(234deg);
    -webkit-transform: rotate(234deg);
    -moz-transform: rotate(234deg);
    -o-transform: rotate(234deg)
}

.skills.circles .progress.p66 .bar {
    transform: rotate(237.6deg);
    -webkit-transform: rotate(237.6deg);
    -moz-transform: rotate(237.6deg);
    -o-transform: rotate(237.6deg)
}

.skills.circles .progress.p67 .bar {
    transform: rotate(241.2deg);
    -webkit-transform: rotate(241.2deg);
    -moz-transform: rotate(241.2deg);
    -o-transform: rotate(241.2deg)
}

.skills.circles .progress.p68 .bar {
    transform: rotate(244.8deg);
    -webkit-transform: rotate(244.8deg);
    -moz-transform: rotate(244.8deg);
    -o-transform: rotate(244.8deg)
}

.skills.circles .progress.p69 .bar {
    transform: rotate(248.4deg);
    -webkit-transform: rotate(248.4deg);
    -moz-transform: rotate(248.4deg);
    -o-transform: rotate(248.4deg)
}

.skills.circles .progress.p70 .bar {
    transform: rotate(252deg);
    -webkit-transform: rotate(252deg);
    -moz-transform: rotate(252deg);
    -o-transform: rotate(252deg)
}

.skills.circles .progress.p71 .bar {
    transform: rotate(255.6deg);
    -webkit-transform: rotate(255.6deg);
    -moz-transform: rotate(255.6deg);
    -o-transform: rotate(255.6deg)
}

.skills.circles .progress.p72 .bar {
    transform: rotate(259.2deg);
    -webkit-transform: rotate(259.2deg);
    -moz-transform: rotate(259.2deg);
    -o-transform: rotate(259.2deg)
}

.skills.circles .progress.p73 .bar {
    transform: rotate(262.8deg);
    -webkit-transform: rotate(262.8deg);
    -moz-transform: rotate(262.8deg);
    -o-transform: rotate(262.8deg)
}

.skills.circles .progress.p74 .bar {
    transform: rotate(266.4deg);
    -webkit-transform: rotate(266.4deg);
    -moz-transform: rotate(266.4deg);
    -o-transform: rotate(266.4deg)
}

.skills.circles .progress.p75 .bar {
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg)
}

.skills.circles .progress.p76 .bar {
    transform: rotate(273.6deg);
    -webkit-transform: rotate(273.6deg);
    -moz-transform: rotate(273.6deg);
    -o-transform: rotate(273.6deg)
}

.skills.circles .progress.p77 .bar {
    transform: rotate(277.2deg);
    -webkit-transform: rotate(277.2deg);
    -moz-transform: rotate(277.2deg);
    -o-transform: rotate(277.2deg)
}

.skills.circles .progress.p78 .bar {
    transform: rotate(280.8deg);
    -webkit-transform: rotate(280.8deg);
    -moz-transform: rotate(280.8deg);
    -o-transform: rotate(280.8deg)
}

.skills.circles .progress.p79 .bar {
    transform: rotate(284.4deg);
    -webkit-transform: rotate(284.4deg);
    -moz-transform: rotate(284.4deg);
    -o-transform: rotate(284.4deg)
}

.skills.circles .progress.p80 .bar {
    transform: rotate(288deg);
    -webkit-transform: rotate(288deg);
    -moz-transform: rotate(288deg);
    -o-transform: rotate(288deg)
}

.skills.circles .progress.p81 .bar {
    transform: rotate(291.6deg);
    -webkit-transform: rotate(291.6deg);
    -moz-transform: rotate(291.6deg);
    -o-transform: rotate(291.6deg)
}

.skills.circles .progress.p82 .bar {
    transform: rotate(295.2deg);
    -webkit-transform: rotate(295.2deg);
    -moz-transform: rotate(295.2deg);
    -o-transform: rotate(295.2deg)
}

.skills.circles .progress.p83 .bar {
    transform: rotate(298.8deg);
    -webkit-transform: rotate(298.8deg);
    -moz-transform: rotate(298.8deg);
    -o-transform: rotate(298.8deg)
}

.skills.circles .progress.p84 .bar {
    transform: rotate(302.4deg);
    -webkit-transform: rotate(302.4deg);
    -moz-transform: rotate(302.4deg);
    -o-transform: rotate(302.4deg)
}

.skills.circles .progress.p85 .bar {
    transform: rotate(306deg);
    -webkit-transform: rotate(306deg);
    -moz-transform: rotate(306deg);
    -o-transform: rotate(306deg)
}

.skills.circles .progress.p86 .bar {
    transform: rotate(309.6deg);
    -webkit-transform: rotate(309.6deg);
    -moz-transform: rotate(309.6deg);
    -o-transform: rotate(309.6deg)
}

.skills.circles .progress.p87 .bar {
    transform: rotate(313.2deg);
    -webkit-transform: rotate(313.2deg);
    -moz-transform: rotate(313.2deg);
    -o-transform: rotate(313.2deg)
}

.skills.circles .progress.p88 .bar {
    transform: rotate(316.8deg);
    -webkit-transform: rotate(316.8deg);
    -moz-transform: rotate(316.8deg);
    -o-transform: rotate(316.8deg)
}

.skills.circles .progress.p89 .bar {
    transform: rotate(320.4deg);
    -webkit-transform: rotate(320.4deg);
    -moz-transform: rotate(320.4deg);
    -o-transform: rotate(320.4deg)
}

.skills.circles .progress.p90 .bar {
    transform: rotate(324deg);
    -webkit-transform: rotate(324deg);
    -moz-transform: rotate(324deg);
    -o-transform: rotate(324deg)
}

.skills.circles .progress.p91 .bar {
    transform: rotate(327.6deg);
    -webkit-transform: rotate(327.6deg);
    -moz-transform: rotate(327.6deg);
    -o-transform: rotate(327.6deg)
}

.skills.circles .progress.p92 .bar {
    transform: rotate(331.2deg);
    -webkit-transform: rotate(331.2deg);
    -moz-transform: rotate(331.2deg);
    -o-transform: rotate(331.2deg)
}

.skills.circles .progress.p93 .bar {
    transform: rotate(334.8deg);
    -webkit-transform: rotate(334.8deg);
    -moz-transform: rotate(334.8deg);
    -o-transform: rotate(334.8deg)
}

.skills.circles .progress.p94 .bar {
    transform: rotate(338.4deg);
    -webkit-transform: rotate(338.4deg);
    -moz-transform: rotate(338.4deg);
    -o-transform: rotate(338.4deg)
}

.skills.circles .progress.p95 .bar {
    transform: rotate(342deg);
    -webkit-transform: rotate(342deg);
    -moz-transform: rotate(342deg);
    -o-transform: rotate(342deg)
}

.skills.circles .progress.p96 .bar {
    transform: rotate(345.6deg);
    -webkit-transform: rotate(345.6deg);
    -moz-transform: rotate(345.6deg);
    -o-transform: rotate(345.6deg)
}

.skills.circles .progress.p97 .bar {
    transform: rotate(349.2deg);
    -webkit-transform: rotate(349.2deg);
    -moz-transform: rotate(349.2deg);
    -o-transform: rotate(349.2deg)
}

.skills.circles .progress.p98 .bar {
    transform: rotate(352.8deg);
    -webkit-transform: rotate(352.8deg);
    -moz-transform: rotate(352.8deg);
    -o-transform: rotate(352.8deg)
}

.skills.circles .progress.p99 .bar {
    transform: rotate(356.4deg);
    -webkit-transform: rotate(356.4deg);
    -moz-transform: rotate(356.4deg);
    -o-transform: rotate(356.4deg)
}

.skills.circles .progress.p100 .bar {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg)
}

@media (min-width:1700px) {
    .skills.list ul li {
        width: 33.333%
    }
}

@media (max-width:720px) {
    .skills ul {
        margin: -20px 0 0 0
    }
    .skills ul li {
        padding: 20px 0 0 0
    }
    .skills.dotted ul li {
        width: 100%
    }
    .skills.circles ul li {
        width: 50%
    }
    .skills.list ul li {
        width: 100%
    }
    .skills.list ul li:last-child,
    .skills.list ul li:last-child .name {
        margin-bottom: 0
    }
}

.clients-items {
    margin: -35px -35px 0 -35px;
    font-size: 0
}

.clients-items .clients-col {
    padding: 35px 35px 0 35px;
    display: inline-block;
    vertical-align: top;
    width: 25%
}

.clients-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100px;
    opacity: .4;
    transition: opacity 1s cubic-bezier(.165, .85, .45, 1) 0s;
    -moz-transition: opacity 1s cubic-bezier(.165, .85, .45, 1) 0s;
    -webkit-transition: opacity 1s cubic-bezier(.165, .85, .45, 1) 0s;
    -o-transition: opacity 1s cubic-bezier(.165, .85, .45, 1) 0s
}

.clients-item a {
    text-decoration: none
}

.clients-item img {
    max-width: 100%;
    max-height: 100px
}

.clients-item:hover {
    opacity: 1
}

@media (max-width:720px) {
    .clients-items {
        margin: -20px -20px 0 -20px
    }
    .clients-items .clients-col {
        padding: 20px 20px 0 20px;
        width: 50%
    }
}

.service-items {
    margin-top: -70px;
    font-size: 0;
    text-align: center
}

.service-items .service-col {
    margin-top: 70px;
    display: inline-block;
    vertical-align: top;
    width: 50%
}

.service-items .service-col:nth-child(odd) .content-box {
    margin-right: 35px
}

.service-items .service-col:nth-child(even) .content-box {
    margin-left: 35px
}

.service-items .service-item .icon {
    font-size: 36px;
    line-height: normal;
    color: #4bffa5
}

.service-items .service-item .name {
    margin: 15px 0;
    color: #eee;
    font-weight: 500
}

@media (max-width:1199px) {
    .service-items {
        margin-top: -40px
    }
    .service-items .service-col {
        margin-top: 40px
    }
    .service-items .service-col:nth-child(odd) .content-box {
        margin-right: 20px
    }
    .service-items .service-col:nth-child(even) .content-box {
        margin-left: 20px
    }
}

@media (max-width:720px) {
    .service-items .service-col {
        width: 100%
    }
    .service-items .service-col:nth-child(odd) .content-box {
        margin-right: 0
    }
    .service-items .service-col:nth-child(even) .content-box {
        margin-left: 0
    }
}

.section.works {
    padding-bottom: 0
}

.section.works .filter-menu {
    margin-bottom: 70px
}

.section.works .filters {
    margin-top: -10px
}

.section.works .filters input {
    display: none
}

.section.works .filters .btn-group {
    display: inline-block;
    vertical-align: top
}

.section.works .filters label {
    display: inline-block;
    vertical-align: top;
    margin-right: 25px;
    margin-top: 10px;
    font-size: 16px;
    color: #eee;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    padding-bottom: 0;
    transition: color .7s cubic-bezier(.165, .85, .45, 1);
    -moz-transition: color .7s cubic-bezier(.165, .85, .45, 1);
    -webkit-transition: color .7s cubic-bezier(.165, .85, .45, 1);
    -o-transition: color .7s cubic-bezier(.165, .85, .45, 1)
}

.section.works .filters label.glitch-effect {
    color: #4bffa5
}

.box-items {
    position: relative;
    margin-left: -50px;
    margin-right: -50px;
    font-size: 0
}

.box-items .box-item {
    width: 50%;
    margin: 0;
    padding: 0 50px 100px 50px;
    position: relative;
    text-align: center;
    font-size: 13px;
    float: left
}

.box-items .box-item .image {
    position: relative;
    overflow: hidden
}

.box-items .box-item .image a {
    display: block;
    font-size: 0
}

.box-items .box-item .image a img {
    width: 100%;
    height: auto;
    position: relative;
    top: 0
}

.box-items .box-item .image .info {
    text-align: center;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0
}

.box-items .box-item .image .info .icon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #101010;
    font-size: 18px;
    display: inline-block;
    font-weight: 400;
    opacity: 0;
    transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transition: all .7s cubic-bezier(.165, .85, .45, 1) 0s;
    -moz-transition: all .7s cubic-bezier(.165, .85, .45, 1) 0s;
    -webkit-transition: all .7s cubic-bezier(.165, .85, .45, 1) 0s;
    -o-transition: all .7s cubic-bezier(.165, .85, .45, 1) 0s
}

.box-items .box-item .image .desc {
    padding: 10px 20px;
    position: relative;
    display: block;
    opacity: 0;
    transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transition: all .7s cubic-bezier(.165, .85, .45, 1) 0s;
    -moz-transition: all .7s cubic-bezier(.165, .85, .45, 1) 0s;
    -webkit-transition: all .7s cubic-bezier(.165, .85, .45, 1) 0s;
    -o-transition: all .7s cubic-bezier(.165, .85, .45, 1) 0s
}

.box-items .box-item .image .desc .name {
    display: block;
    font-size: 24px;
    font-weight: 600;
    color: #101010;
    word-wrap: break-word
}

.box-items .box-item .image:hover .icon {
    opacity: .6;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transition: all .7s cubic-bezier(.165, .85, .45, 1) .35s;
    -moz-transition: all .7s cubic-bezier(.165, .85, .45, 1) .35s;
    -webkit-transition: all .7s cubic-bezier(.165, .85, .45, 1) .35s;
    -o-transition: all .7s cubic-bezier(.165, .85, .45, 1) .35s
}

.box-items .box-item .image:hover .desc {
    top: 0;
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transition: all .7s cubic-bezier(.165, .85, .45, 1) .35s;
    -moz-transition: all .7s cubic-bezier(.165, .85, .45, 1) .35s;
    -webkit-transition: all .7s cubic-bezier(.165, .85, .45, 1) .35s;
    -o-transition: all .7s cubic-bezier(.165, .85, .45, 1) .35s
}

.box-items .box-item .category {
    margin: 0;
    display: block;
    font-size: 14px;
    color: #101010;
    opacity: .6
}

.portfolio-cols {
    display: flex
}

.description-col {
    width: 78%
}

.details-col {
    padding-left: 50px;
    width: 24%
}

.single-post-text .details-list li {
    margin-bottom: 20px;
    padding-left: 0;
    font-size: 13px;
    text-transform: uppercase
}

.single-post-text .details-list li:before {
    display: none
}

.single-post-text .details-list li strong {
    margin-bottom: 5px;
    display: block;
    color: #eee;
    font-weight: 500
}

@media (max-width:1199px) {
    .section.works .filter-menu {
        margin-bottom: 40px
    }
    .section .content-box {
        padding: 25px
    }
    .box-items {
        margin-left: -20px;
        margin-right: -20px
    }
    .box-items .box-item {
        width: 50%;
        padding: 0 20px 40px 20px
    }
    .portfolio-cols {
        display: block
    }
    .description-col {
        width: 100%
    }
    .details-col {
        width: 100%
    }
    .details-col {
        padding: 40px 0 0 0;
        width: 100%
    }
    .section.works .filters label {
        margin-right: 15px
    }
}

@media (max-width:720px) {
    .section.works .filters label {
        font-size: 14px
    }
    .box-items {
        margin-left: 0;
        margin-right: 0
    }
    .box-items .box-item {
        padding-left: 0;
        padding-right: 0;
        float: none;
        width: 100%
    }
    .box-items .box-item:last-child {
        padding-bottom: 0
    }
}

.section.contacts .alert-success {
    display: none
}

.section.contacts .alert-success p {
    margin: 0;
    font-size: 16px;
    color: #eee
}

.map {
    position: absolute;
    left: 0;
    top: -5vh;
    width: 100vw;
    height: 110vh
}

.pricing-items {
    margin: 0 -35px;
    font-size: 0
}

.pricing-items .pricing-col {
    padding: 0 29px;
    display: inline-block;
    vertical-align: top;
    width: 50%
}

.pricing-item {
    position: relative;
    text-align: center
}

.pricing-item .icon {
    font-size: 36px;
    line-height: normal;
    color: #4bffa5
}

.pricing-item .name {
    margin: 15px 0;
    color: #eee;
    font-weight: 500
}

.pricing-item .amount {
    margin: 30px 0
}

.pricing-item .amount .number {
    margin: 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 48px;
    line-height: 24px;
    font-weight: 600;
    color: #eee
}

.pricing-item .amount .number .dollar,
.pricing-item .amount .number .period {
    position: absolute;
    left: -15px;
    top: -5px;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 14px;
    color: #999;
    font-weight: 400
}

.pricing-item .amount .number .period {
    left: auto;
    right: -55px;
    top: auto;
    bottom: -5px;
    width: 50px;
    text-align: left;
    white-space: nowrap
}

.pricing-item .feature-list {
    margin-bottom: 30px
}

.pricing-item .feature-list ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.pricing-item .feature-list ul li {
    margin: 10px 0
}

.pricing-item .feature-list ul li:last-child {
    margin-bottom: 0;
    border-bottom: none
}

.pricing-item .feature-list ul li.disable {
    text-decoration: line-through;
    opacity: .5
}

.pricing-item .feature-list ul li strong {
    margin: 0 0 0 5px;
    padding: 0 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: #101010;
    background: #4bffa5;
    font-weight: 300;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -khtml-border-radius: 2px
}

@media (max-width:720px) {
    .pricing-items .pricing-col {
        margin-bottom: 40px;
        width: 100%
    }
    .pricing-items .pricing-col:last-child {
        margin-bottom: 0
    }
}

.team-items {
    margin: 0 -35px;
    font-size: 0
}

.team-items .team-col {
    padding: 0 33px;
    display: inline-block;
    vertical-align: top;
    width: 50%
}

.team-item {
    position: relative;
    text-align: center
}

.team-item .image {
    font-size: 0
}

.team-item .image img {
    max-width: 100%;
    height: auto
}

.team-item .name {
    margin: 30px 0 5px 0;
    color: #eee;
    font-weight: 500
}

.team-item .category {
    margin: 0 0 5px 0;
    position: relative;
    display: inline-block;
    color: #999
}

.team-item .soc a {
    margin: 0 5px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    color: #bbb;
    transition: all .7s cubic-bezier(.165, .85, .45, 1);
    -moz-transition: all .7s cubic-bezier(.165, .85, .45, 1);
    -webkit-transition: all .7s cubic-bezier(.165, .85, .45, 1);
    -o-transition: all .7s cubic-bezier(.165, .85, .45, 1)
}

.team-item .soc a .icon {
    font-size: 14px;
    color: #bbb
}

.team-item .soc a:hover {
    color: #fff;
    opacity: 1
}

.team-item .soc a:hover .icon {
    color: #fff
}

@media (max-width:720px) {
    .team-items .team-col {
        margin-bottom: 40px;
        width: 100%
    }
    .team-items .team-col:last-child {
        margin-bottom: 0
    }
}

.reviews-carousel {
    position: relative;
    width: calc(100% + 1px)
}

.reviews-carousel .content-box {
    margin-right: 0
}

.reviews-carousel .swiper-nav {
    position: absolute;
    left: auto;
    right: 35px;
    top: -58px;
    height: 24px;
    z-index: 3
}

.reviews-carousel .swiper-nav .swiper-button-next,
.reviews-carousel .swiper-nav .swiper-button-prev {
    margin-left: 15px;
    margin-top: 0;
    position: relative;
    top: 0;
    left: 0;
    right: auto;
    width: 20px;
    height: 20px;
    background: 0 0;
    font-size: 22px;
    color: #fff;
    text-decoration: none;
    transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s
}

.reviews-item {
    position: relative
}

.reviews-item .image {
    float: left;
    margin-bottom: 15px;
    margin-right: 15px;
    width: 70px;
    font-size: 0
}

.reviews-item .image img {
    width: 100%;
    height: auto;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -khtml-border-radius: 100%
}

.reviews-item .info {
    margin-top: 10px;
    margin-left: 85px
}

.reviews-item .name {
    margin: 0 0 5px 0;
    color: #eee;
    font-weight: 500
}

.reviews-item .company {
    font-size: 14px;
    color: #999
}

.reviews-item .text {
    clear: both
}

@media (max-width:720px) {
    .reviews-carousel .swiper-nav {
        right: 0
    }
}

.started-content .date {
    margin: 0 0 10px;
    display: inline-block;
    padding: 0 5px;
    height: 20px;
    line-height: 18px;
    font-weight: 500;
    font-size: 11px;
    color: #4bffa5;
    border: 1px solid #4bffa5
}

.comment-text,
.single-post-text {
    word-wrap: break-word
}

.single-post-text:after {
    content: '';
    position: relative;
    clear: both;
    display: block
}

.comment-text .content-box *,
.single-post-text .content-box * {
    margin-top: 0
}

.comment-text img,
.single-post-text img {
    max-width: 100%;
    height: auto
}

.single-post-text p:last-child {
    margin-bottom: 0
}

.comment-text p a,
.single-post-text p a {
    color: #4bffa5
}

.comment-text p a:hover,
.single-post-text p a:hover {
    text-decoration: underline
}

blockquote {
    margin: 25px 0;
    padding: 0 0 0 20px;
    font-size: 14px;
    line-height: 24px;
    color: #999;
    font-style: italic;
    font-weight: 300;
    border-left: 2px solid #4bffa5
}

blockquote code {
    padding: 2px;
    display: inline
}

iframe,
object {
    max-width: 100%
}

.comment-text ul,
.single-post-text ul {
    list-style: none
}

.comment-text ol,
.single-post-text ol {
    list-style-position: inside
}

.comment-text ol>li,
.comment-text ul>li,
.single-post-text ol>li,
.single-post-text ul>li {
    margin: 5px 0;
    padding: 0 0 0 18px;
    position: relative;
    font-weight: 400
}

.comment-text ol>li,
.single-post-text ol>li {
    margin: 5px 0;
    padding: 0;
    position: relative;
    font-size: 14px;
    font-weight: 400
}

.comment-text ul>li:before,
.single-post-text ul>li:before {
    margin: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 4px;
    height: 4px;
    background: #4bffa5;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    padding: 0
}

.wp-block-gallery .blocks-gallery-image:before,
.wp-block-gallery .blocks-gallery-item:before {
    display: none
}

.comment-text figure,
.single-post-text figure {
    margin: 0 0 30px 0;
    max-width: 100%
}

.bypostauthor,
.comment-text figure .wp-caption-text,
.gallery-caption,
.single-post-text figure .wp-caption-text {
    margin-top: 10px;
    font-size: 12px;
    font-style: italic;
    color: #999
}

.gallery {
    margin: 30px -10px
}

.single-post-text .gallery {
    margin-bottom: 0
}

.gallery .gallery-item {
    margin: 0;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    width: 100%
}

.gallery .gallery-item img {
    width: 100%;
    height: auto
}

.gallery.gallery-columns-1 .gallery-item {
    width: 100%
}

.gallery.gallery-columns-2 .gallery-item {
    width: 50%
}

.gallery.gallery-columns-3 .gallery-item {
    width: 33.333%
}

.gallery.gallery-columns-4 .gallery-item {
    width: 25%
}

.gallery.gallery-columns-5 .gallery-item {
    width: 20%
}

.gallery.gallery-columns-6 .gallery-item {
    width: 16.666%
}

.gallery.gallery-columns-7 .gallery-item {
    width: 14.28%
}

.gallery.gallery-columns-8 .gallery-item {
    width: 12.5%
}

.gallery.gallery-columns-9 .gallery-item {
    width: 11.111%
}

.post-comments {
    margin-top: 60px
}

.post-comments .children,
.post-comments .comments {
    margin: 0;
    padding: 0;
    list-style: none
}

.post-comments .post-comment {
    padding: 20px 0;
    border-top: 1px solid #ddd
}

.post-comments .post-comment:first-child {
    padding-top: 0;
    border-top: none
}

.post-comments .post-comment .image {
    float: left;
    width: 80px;
    height: 80px
}

.post-comments .post-comment .image img {
    width: 100%;
    height: 100%
}

.post-comments .post-comment .desc {
    margin-left: 100px;
    position: relative
}

.post-comments .post-comment .desc .name {
    font-size: 13px;
    color: #141414;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px 0 0 0;
    margin: 0 0 10px 0
}

.post-comments .post-comment .desc p {
    margin: 0
}

.post-comments .form-comment .title {
    margin: 30px 0 15px 0
}

.post-comment.pingback .image {
    display: none
}

.post-comment.pingback .desc {
    margin-left: 0
}

.post-text-bottom {
    padding: 25px 0 10px 0
}

.post-text-bottom .share-btn {
    position: relative;
    display: inline-block;
    top: 1px;
    margin-left: 7px;
    margin-right: 7px;
    font-size: 16px
}

.post-text-bottom>.social-share,
.post-text-bottom>span {
    margin-right: 30px;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle
}

.post-text-bottom .byline,
.post-text-bottom .social-share,
.post-text-bottom span.cat-links {
    font-size: 12px;
    color: #999
}

.post-text-bottom span.cat-links a {
    color: #4bffa5
}

.post-text-bottom span.cat-links a:hover {
    text-decoration: underline
}

.post-text-bottom .tags-links {
    display: block;
    line-height: 18px
}

.post-text-bottom .tags-links a,
.post-text-bottom .tags-links span {
    margin: 0 10px 10px 0;
    display: inline-block;
    padding: 0 5px;
    height: 20px;
    line-height: 18px;
    font-weight: 500;
    font-size: 11px;
    color: #4bffa5;
    border: 1px solid #4bffa5
}

.post-comments ul.children {
    padding: 20px 20px 0 100px;
    border-top: none;
    background: #f6f6f6
}

.post-comments ul.children ul.children {
    padding: 20px 0 0 40px;
    border-top: none;
    background: #f6f6f6
}

.comment-info {
    padding: 10px 0 5px 0
}

.comment-info span.comment-time {
    font-size: 11px;
    color: #999
}

.comment-info span.comment-date {
    font-size: 11px;
    color: #999
}

.comment-info span.comment-reply {
    position: absolute;
    top: 3px;
    right: 0
}

.comment-respond {
    position: relative;
    margin-top: 40px
}

.comment-respond .title_inner small {
    position: absolute;
    top: 0;
    left: 120%;
    display: block;
    white-space: nowrap;
    color: #999
}

.comment-respond .title_inner small a {
    color: #999
}

.nav-links,
.page-links,
.section .pager {
    clear: both;
    padding: 20px 0 60px 0;
    text-align: center
}

.page-numbers {
    margin: 0 5px;
    display: inline-block;
    vertical-align: top
}

.page-numbers.current {
    color: #999
}

.nav-links,
.page-links {
    color: #999
}

.nav-links {
    padding: 0
}

@media (max-width:1160px) {
    .post-comments .post-comment .image {
        width: 60px;
        height: 60px
    }
    .post-comments .post-comment .desc {
        margin-left: 80px
    }
    .post-comments .post-comment.pingback .desc {
        margin-left: 0
    }
    .post-comments ul.children {
        padding: 20px 20px 0 80px
    }
    .post-comments ul.children ul.children {
        padding: 20px 0 0 20px
    }
    .post-comments ul.children ul.children ul.children ul.children {
        padding: 20px 0 0 10px
    }
}

@media (max-width:840px) {
    .post-comments ul.children {
        padding: 20px 20px 0 20px
    }
    .post-comments ul.children ul.children {
        padding: 20px 0 0 20px
    }
    .post-comments ul.children ul.children ul.children {
        padding: 20px 0 0 20px
    }
    .post-comments ul.children ul.children ul.children ul.children {
        padding: 20px 0 0 10px
    }
}

@media (max-width:480px) {
    .section .content .comment-respond .title.comment-reply-title {
        text-align: left
    }
    .post-comments ul.children {
        padding: 15px 15px 15px 15px
    }
    .post-comments ul.children ul.children {
        padding: 15px 0 0 10px
    }
    .post-comments ul.children ul.children ul.children {
        padding: 15px 0 0 5px
    }
    .post-comments ul.children ul.children ul.children ul.children {
        padding: 15px 0 0 5px
    }
    .post-comments .post-comment .image {
        width: 40px;
        height: 40px
    }
    .post-comments .post-comment .desc {
        margin-left: 55px
    }
    .post-comments .post-comment.pingback .desc {
        margin-left: 0
    }
    .gallery .gallery-item {
        width: 100%
    }
    .gallery.gallery-columns-1 .gallery-item {
        width: 100%
    }
    .gallery.gallery-columns-2 .gallery-item {
        width: 100%
    }
    .gallery.gallery-columns-3 .gallery-item {
        width: 100%
    }
    .gallery.gallery-columns-4 .gallery-item {
        width: 100%
    }
    .gallery.gallery-columns-5 .gallery-item {
        width: 100%
    }
    .gallery.gallery-columns-6 .gallery-item {
        width: 100%
    }
    .gallery.gallery-columns-7 .gallery-item {
        width: 100%
    }
    .gallery.gallery-columns-8 .gallery-item {
        width: 100%
    }
    .gallery.gallery-columns-9 .gallery-item {
        width: 100%
    }
    th {
        border: 2px solid #fff;
        padding: 5px
    }
    td {
        border: 2px solid #fff;
        padding: 5px
    }
}

.footer {
    padding: 0;
    position: fixed;
    bottom: 50px;
    left: 50px;
    right: 50px;
    width: auto;
    height: 1px;
    z-index: 100
}

.footer .copy {
    position: absolute;
    left: 0;
    bottom: 0
}

.footer .copy,
.footer p {
    margin: 2px 0 0 0;
    font-size: 14px;
    line-height: 2;
    color: #bbb
}

.footer .soc-box {
    position: absolute;
    right: 0;
    bottom: 0;
    float: right;
    width: 24px
}

.footer .soc a {
    display: block;
    margin-bottom: 20px;
    text-align: center;
    color: #bbb;
    transition: all .7s cubic-bezier(.165, .85, .45, 1);
    -moz-transition: all .7s cubic-bezier(.165, .85, .45, 1);
    -webkit-transition: all .7s cubic-bezier(.165, .85, .45, 1);
    -o-transition: all .7s cubic-bezier(.165, .85, .45, 1)
}

.footer .soc a:last-child {
    margin-bottom: 0
}

.footer .soc a .icon {
    font-size: 18px;
    color: #bbb
}

.footer .soc a:hover {
    color: #fff;
    opacity: 1
}

.footer .soc a:hover .icon {
    color: #fff
}

.follow-label {
    padding-right: 75px;
    position: absolute;
    bottom: 100%;
    right: 0;
    color: #bbb;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    white-space: nowrap;
    transform-origin: 100% 0;
    text-align: right;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg)
}

.follow-label:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 50px;
    height: 1px;
    background: #fff
}

@media (max-width:1199px) {
    .footer .copy {
        display: none
    }
    .footer .follow-label {
        display: none
    }
    .footer {
        padding: 50px 0;
        position: relative;
        top: 0;
        bottom: auto;
        left: 0;
        right: auto;
        height: auto
    }
    .footer .copy {
        margin-bottom: 10px;
        display: block;
        position: relative;
        text-align: center
    }
    .footer .soc-box {
        position: relative;
        right: auto;
        left: 0;
        top: 0;
        bottom: auto;
        float: none;
        width: auto;
        text-align: center
    }
    .footer .soc a {
        margin: 0 10px;
        display: inline-block
    }
    .home .footer {
        padding: 0;
        position: fixed;
        bottom: 50px;
        top: auto;
        left: 50px;
        right: 50px;
        height: 1px
    }
    .home .footer .copy {
        display: none
    }
    .home .footer .soc-box {
        position: absolute;
        right: 0;
        left: auto;
        top: auto;
        bottom: 0;
        float: right;
        width: 20px
    }
    .home .footer .soc a {
        margin: 10px 0 0 0;
        display: block
    }
    .home .footer .soc a .icon {
        font-size: 17px
    }
}

@media (max-width:720px) {
    .home .footer {
        bottom: 30px;
        top: auto;
        left: 30px;
        right: 30px
    }
}

.content-sidebar .widget-area {
    position: relative;
    overflow: auto;
    height: 100%
}

.content-sidebar .widget {
    padding: 25px
}

.content-sidebar .widget tfoot {
    display: none
}

.content-sidebar .widget th {
    color: #000;
    padding: 5px;
    text-align: center
}

.content-sidebar .widget td {
    padding: 5px;
    text-align: center
}

.content-sidebar .widget label {
    padding-bottom: 0
}

.content-sidebar .widget ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.content-sidebar .widget ul ul {
    margin-top: 10px;
    margin-left: 0;
    margin-bottom: 10px;
    padding: 15px 10px;
    background: #f6f6f6;
    font-size: 12px
}

.content-sidebar .widget ul ul ul {
    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0
}

.content-sidebar .widget ul li {
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #999;
    border-bottom: 1px solid #f2f2f2
}

.content-sidebar .widget ul li li {
    padding-bottom: 0;
    border-bottom: none
}

.content-sidebar .widget ul li:last-child {
    margin-bottom: 0
}

.content-sidebar .widget ul li a {
    padding-bottom: 5px
}

.content-sidebar .widget ul li a:hover {
    color: #4bffa5
}

.content-sidebar .widget.widget_recent_comments a,
.content-sidebar .widget.widget_recent_entries a,
.content-sidebar .widget.widget_rss a {
    padding-top: 5px;
    font-size: 14px;
    font-weight: 500;
    display: block !important
}

.content-sidebar .widget ul li .post-date {
    margin: 0;
    display: inline-block;
    padding: 0;
    height: 20px;
    line-height: 18px;
    font-weight: 400;
    font-size: 11px;
    color: #999
}

.content-sidebar .widget img {
    max-width: 100%;
    height: auto
}

.content-sidebar .widget .rss-date,
.content-sidebar .widget cite {
    margin: 5px 0 10px 0;
    padding: 0;
    display: block;
    height: 20px;
    line-height: 18px;
    font-weight: 400;
    font-size: 11px;
    color: #999
}

.content-sidebar .widget cite {
    margin: 10px 0 0 0
}

.content-sidebar .widget .rssSummary {
    color: #363636
}

.content-sidebar span.screen-reader-text {
    display: none
}

.search-form input.search-field {
    margin: 0;
    padding: 0 50px 0 15px;
    border: solid 1px #ddd;
    width: 100%;
    height: 50px;
    font-weight: 400;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -khtml-box-shadow: none
}

.search-form input.search-submit {
    margin-top: -34px;
    margin-right: 12px;
    float: right;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    font-size: 0;
    border: none
}

.content-sidebar h2.widget-title {
    padding-bottom: 20px;
    margin: -26px -25px 25px -25px;
    border-bottom: 1px solid #ddd;
    padding: 25px 30px;
    display: block;
    line-height: 18px;
    font-size: 13px;
    color: #141414;
    text-transform: uppercase;
    letter-spacing: .04em;
    font-weight: 400
}

.content-sidebar h2.widget-title span {
    position: relative;
    left: -5px
}

.content-sidebar .close {
    position: absolute;
    top: 0;
    left: -68px;
    width: 68px;
    height: 68px;
    background: #f9f9f9;
    cursor: pointer
}

.content-sidebar .close:after,
.content-sidebar .close:before {
    content: '';
    margin: 0 0 0 -10px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 1px;
    background: #363636;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg)
}

.content-sidebar .close:after {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg)
}

.content-sidebar .recentcomments {
    font-size: 11px;
    color: #999
}

.content-sidebar .recentcomments a {
    padding-top: 6px;
    display: block !important;
    font-size: 13px
}

.content-sidebar .recentcomments a.url {
    margin: 0;
    padding: 0;
    display: inline-block;
    height: 20px;
    line-height: 18px;
    font-weight: 400;
    font-size: 11px;
    color: #999
}

.content-sidebar .recentcomments .comment-author-link {
    margin: 0;
    padding: 0;
    display: inline-block;
    height: 20px;
    line-height: 18px;
    font-weight: 400;
    font-size: 11px;
    color: #999
}

.content-sidebar .tagcloud a {
    margin: 4px 2px;
    display: inline-block;
    vertical-align: bottom;
    padding: 0 5px;
    font-weight: 500;
    font-size: 11px;
    color: #4bffa5;
    border: 1px solid #4bffa5
}

.content-sidebar .screen-reader-text {
    display: none
}

.content-sidebar .widget select {
    width: 100%;
    height: 34px
}

.content-sidebar table {
    margin: 0
}

.content-sidebar .widget.widget_media_image {
    max-width: 100%
}

.navigation.post-navigation {
    height: 18px
}

.navigation.post-navigation .nav-links a {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: #aca3a3;
    line-height: 20px;
    position: relative;
    padding: 5px 10px;
    display: inline-block;
    transition: all .2s ease-out 0s;
    -moz-transition: all .2s ease-out 0s;
    -webkit-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s
}

.navigation.post-navigation .nav-links .nav-previous a {
    float: left;
    padding-left: 34px
}

.navigation.post-navigation .nav-links .nav-next a:after,
.navigation.post-navigation .nav-links .nav-previous a:before {
    margin-top: -10px;
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1)
}

.navigation.post-navigation .nav-links .nav-next a {
    float: right;
    padding-right: 34px
}

.navigation.post-navigation .nav-links .nav-next a:after {
    left: auto;
    right: 0;
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -o-transform: scaleX(1)
}

.post-edit-link {
    font-size: 12px;
    color: #999;
    text-decoration: underline
}

.post-edit-link:hover {
    text-decoration: none
}

.sticky {
    background-color: #fdfbf2
}

.popup-box {
    margin: 30px auto;
    width: 520px;
    background: #101010;
    position: relative;
    padding: 0
}

@media (max-width:720px) {
    .popup-box {
        width: 440px
    }
}

@media (max-width:480px) {
    .popup-box {
        width: 320px
    }
}

.popup-box .image {
    height: 560px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
}

.popup-box .image img {
    width: 100%;
    height: auto;
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important
}

.popup-box .desc {
    padding: 35px
}

.popup-box .category {
    margin: 0 0 5px 0;
    display: block;
    font-size: 14px;
    color: #bbb
}

.popup-box .desc h4 {
    margin: 0 0 15px 0;
    display: block;
    font-size: 24px;
    font-weight: 600;
    color: #fff
}

.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -webkit-transition: all .15s ease-out;
    -o-transition: all .15s ease-out
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: .8
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -webkit-transition: all .15s ease-out;
    -o-transition: all .15s ease-out
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0
}

.mfp-close-btn-in .mfp-close {
    top: 40px !important;
    right: 0 !important;
    padding-right: 0 !important;
    width: 54px !important;
    height: 54px !important;
    line-height: 54px !important;
    opacity: 1 !important;
    font-weight: 300;
    color: #fff;
    font-size: 24px;
    text-align: center !important;
    font-family: Verdana !important
}

.mfp-close-btn-in .mfp-close {
    color: #fff !important
}

.mfp-close-btn-in .mfp-iframe-holder .mfp-close,
.mfp-close-btn-in .popup-box .mfp-close {
    top: 0 !important
}
/* 
.video-bg {
    margin-left: -50vw;
    position: absolute !important;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
} */

.jarallax .jarallax-img {
    max-width: 100%
}

.background-enabled .footer .copy,
.background-enabled .footer .soc a .ion,
.background-enabled .header .top-menu ul li a,
.background-enabled .section.started .mouse_btn,
.background-enabled .section.started .started-content .h-subtitle,
.background-enabled .section.started .started-content .typed-bread,
.background-enabled .section.started .started-content .typed-bread a,
.background-enabled .section.started .started-content .typed-cursor,
.background-enabled .section.started .started-content .typed-subtitle,
.background-enabled .sidebar_btn,
.background-enabled-footer .footer .copy,
.background-enabled-footer .footer .soc a .ion {
    color: #fff
}

.background-enabled .header .logo img {
    /* filter: brightness(0) invert(1) */
}

.background-enabled .header .logo {
    color: rgba(255, 255, 255, .5)
}

.background-enabled .header .logo strong {
    color: #fff
}

.background-enabled .header .top-menu ul li a:hover {
    color: #fff
}

.section.started.background-enabled .started-content .h-title {
    color: #fff
}

.background-enabled .header .top-menu-nav .sub-menu li a {
    color: #fff
}

.background-enabled .header .top-menu-nav .sub-menu li a:hover {
    color: #fff
}

.background-enabled .footer,
.background-enabled .glitch-effect:after,
.background-enabled .glitch-effect:before,
.background-enabled .header,
.background-enabled .section.started,
.background-enabled-footer .footer {
    background: 0 0;
    border-bottom-color: transparent
}
/* 
@media (max-width:480px) {
    .video-bg {
        margin-left: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
} */
/* 
.video-bg-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #101010;
    opacity: .4;
    z-index: 2
}

.video-bg-texture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2
}

.grained-off .video-bg-texture {
    display: none
}

.grained-on .video-bg-texture {
    display: block
} */

.disable-default-mask:before {
    display: none
}

.embed-container {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%
}

.embed-container embed,
.embed-container iframe,
.embed-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: .8
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: 0 !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #ccc
}

.mfp-preloader a:hover {
    color: #fff
}

.mfp-s-ready .mfp-preloader {
    display: none
}

.mfp-s-error .mfp-content {
    display: none
}

button.mfp-arrow,
button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace
}

.mfp-close:focus,
.mfp-close:hover {
    opacity: 1
}

.mfp-close:active {
    top: 1px
}

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:focus,
.mfp-arrow:hover {
    opacity: 1
}

.mfp-arrow .mfp-a,
.mfp-arrow .mfp-b,
.mfp-arrow:after,
.mfp-arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent
}

.mfp-arrow .mfp-a,
.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}

.mfp-arrow .mfp-b,
.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: .7
}

.mfp-arrow-left {
    left: 0
}

.mfp-arrow-left .mfp-a,
.mfp-arrow-left:after {
    border-right: 17px solid #fff;
    margin-left: 31px
}

.mfp-arrow-left .mfp-b,
.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f
}

.mfp-arrow-right {
    right: 0
}

.mfp-arrow-right .mfp-a,
.mfp-arrow-right:after {
    border-left: 17px solid #fff;
    margin-left: 39px
}

.mfp-arrow-right .mfp-b,
.mfp-arrow-right:before {
    border-left: 27px solid #3f3f3f
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #000
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto
}

.mfp-figure {
    line-height: 0
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #444
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-width:800px) and (orientation:landscape),
screen and (max-height:300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }
    .mfp-img-mobile img.mfp-img {
        padding: 0
    }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }
    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width:900px) {
    .mfp-arrow {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }
    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }
    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }
    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.mfp-ie7 .mfp-img {
    padding: 0
}

.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px
}

.mfp-ie7 .mfp-container {
    padding: 0
}

.mfp-ie7 .mfp-content {
    padding-top: 44px
}

.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0)
    }
    50% {
        -webkit-transform: scale(1)
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0)
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}

@-webkit-keyframes blinker {
    0% {
        opacity: 1
    }
    40% {
        opacity: 0
    }
    80% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s
}

.animated.bounceIn,
.animated.bounceOut {
    -webkit-animation-duration: .75s;
    animation-duration: .75s
}

.animated.flipOutX,
.animated.flipOutY {
    -webkit-animation-duration: .75s;
    animation-duration: .75s
}

@-webkit-keyframes bounce {
    100%,
    20%,
    53%,
    80%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0)
    }
    70% {
        -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

@keyframes bounce {
    100%,
    20%,
    53%,
    80%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0)
    }
    70% {
        -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
}

@-webkit-keyframes flash {
    100%,
    50%,
    from {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

@keyframes flash {
    100%,
    50%,
    from {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

.flash {
    -webkit-animation-name: flash;
    animation-name: flash
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse
}

@-webkit-keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }
    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }
    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }
    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }
    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }
    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }
    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }
    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }
    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand
}

@-webkit-keyframes shake {
    100%,
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

@keyframes shake {
    100%,
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake
}

@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg)
    }
    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg)
    }
    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg)
    }
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg)
    }
    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg)
    }
    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg)
    }
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

.swing {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing
}

@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada
}

@-webkit-keyframes wobble {
    from {
        -webkit-transform: none;
        transform: none
    }
    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }
    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }
    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }
    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }
    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }
    100% {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes wobble {
    from {
        -webkit-transform: none;
        transform: none
    }
    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }
    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }
    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }
    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }
    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }
    100% {
        -webkit-transform: none;
        transform: none
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble
}

@-webkit-keyframes jello {
    100%,
    11.1%,
    from {
        -webkit-transform: none;
        transform: none
    }
    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }
    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg)
    }
    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }
    66.6% {
        -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
        transform: skewX(-.78125deg) skewY(-.78125deg)
    }
    77.7% {
        -webkit-transform: skewX(.390625deg) skewY(.390625deg);
        transform: skewX(.390625deg) skewY(.390625deg)
    }
    88.8% {
        -webkit-transform: skewX(-.1953125deg) skewY(-.1953125deg);
        transform: skewX(-.1953125deg) skewY(-.1953125deg)
    }
}

@keyframes jello {
    100%,
    11.1%,
    from {
        -webkit-transform: none;
        transform: none
    }
    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }
    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg)
    }
    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }
    66.6% {
        -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
        transform: skewX(-.78125deg) skewY(-.78125deg)
    }
    77.7% {
        -webkit-transform: skewX(.390625deg) skewY(.390625deg);
        transform: skewX(.390625deg) skewY(.390625deg)
    }
    88.8% {
        -webkit-transform: skewX(-.1953125deg) skewY(-.1953125deg);
        transform: skewX(-.1953125deg) skewY(-.1953125deg)
    }
}

.jello {
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes bounceIn {
    100%,
    20%,
    40%,
    60%,
    80%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }
    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes bounceIn {
    100%,
    20%,
    40%,
    60%,
    80%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }
    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
}

@-webkit-keyframes bounceInDown {
    100%,
    60%,
    75%,
    90%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }
    100% {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInDown {
    100%,
    60%,
    75%,
    90%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }
    100% {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown
}

@-webkit-keyframes bounceInLeft {
    100%,
    60%,
    75%,
    90%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0)
    }
    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }
    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0)
    }
    100% {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInLeft {
    100%,
    60%,
    75%,
    90%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0)
    }
    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }
    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0)
    }
    100% {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft
}

@-webkit-keyframes bounceInRight {
    100%,
    60%,
    75%,
    90%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0)
    }
    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0)
    }
    100% {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInRight {
    100%,
    60%,
    75%,
    90%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0)
    }
    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0)
    }
    100% {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight
}

@-webkit-keyframes bounceInUp {
    100%,
    60%,
    75%,
    90%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes bounceInUp {
    100%,
    60%,
    75%,
    90%,
    from {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp
}

@-webkit-keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }
    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
}

@keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }
    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
}

.bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut
}

@-webkit-keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

@keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

.bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown
}

@-webkit-keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft
}

@-webkit-keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

@keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

.bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight
}

@-webkit-keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

@-webkit-keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}

@-webkit-keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

@-webkit-keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

@-webkit-keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    from {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown
}

@-webkit-keyframes fadeOutDownBig {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

@keyframes fadeOutDownBig {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

.fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft
}

@-webkit-keyframes fadeOutLeftBig {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

@keyframes fadeOutLeftBig {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

.fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight
}

@-webkit-keyframes fadeOutRightBig {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

@keyframes fadeOutRightBig {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

.fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp
}

@-webkit-keyframes fadeOutUpBig {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes fadeOutUpBig {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

.fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig
}

@-webkit-keyframes flip {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    80% {
        -webkit-transform: perspective(400px) scale3d(.95, .95, .95);
        transform: perspective(400px) scale3d(.95, .95, .95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes flip {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    80% {
        -webkit-transform: perspective(400px) scale3d(.95, .95, .95);
        transform: perspective(400px) scale3d(.95, .95, .95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

.animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip
}

@-webkit-keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }
    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }
    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

.flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX
}

@-webkit-keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg)
    }
    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg)
    }
    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

.flipInY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    animation-name: flipInY
}

@-webkit-keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }
    100% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

@keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }
    100% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

.flipOutX {
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important
}

@-webkit-keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1
    }
    100% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0
    }
}

@keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1
    }
    100% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0
    }
}

.flipOutY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY
}

@-webkit-keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0
    }
    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1
    }
    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1
    }
    100% {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0
    }
    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1
    }
    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1
    }
    100% {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
}

@-webkit-keyframes lightSpeedOut {
    from {
        opacity: 1
    }
    100% {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0
    }
}

@keyframes lightSpeedOut {
    from {
        opacity: 1
    }
    100% {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0
    }
}

.lightSpeedOut {
    -webkit-animation-name: lightSpeedOut;
    animation-name: lightSpeedOut;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
}

@-webkit-keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn
}

@-webkit-keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft
}

@-webkit-keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight
}

@-webkit-keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft
}

@-webkit-keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0
    }
    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInUpRight {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight
}

@-webkit-keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0
    }
}

@keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0
    }
}

.rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut
}

@-webkit-keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
}

@keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
}

.rotateOutDownLeft {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft
}

@-webkit-keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

@keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

.rotateOutDownRight {
    -webkit-animation-name: rotateOutDownRight;
    animation-name: rotateOutDownRight
}

@-webkit-keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

@keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

.rotateOutUpLeft {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft
}

@-webkit-keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0
    }
}

@keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }
    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0
    }
}

.rotateOutUpRight {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight
}

@-webkit-keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }
    100% {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0
    }
}

@keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }
    100% {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0
    }
}

.hinge {
    -webkit-animation-name: hinge;
    animation-name: hinge
}

@-webkit-keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.rollIn {
    -webkit-animation-name: rollIn;
    animation-name: rollIn
}

@-webkit-keyframes rollOut {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)
    }
}

@keyframes rollOut {
    from {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)
    }
}

.rollOut {
    -webkit-animation-name: rollOut;
    animation-name: rollOut
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    50% {
        opacity: 1
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    50% {
        opacity: 1
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn
}

@-webkit-keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown
}

@-webkit-keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft
}

@-webkit-keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight
}

@-webkit-keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp
}

@-webkit-keyframes zoomOut {
    from {
        opacity: 1
    }
    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    100% {
        opacity: 0
    }
}

@keyframes zoomOut {
    from {
        opacity: 1
    }
    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    100% {
        opacity: 0
    }
}

.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut
}

@-webkit-keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown
}

@-webkit-keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
        transform: scale(.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center
    }
}

@keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
        transform: scale(.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center
    }
}

.zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft
}

@-webkit-keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
        transform: scale(.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center
    }
}

@keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
        transform: scale(.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center
    }
}

.zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight
}

@-webkit-keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp
}

@-webkit-keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown
}

@-webkit-keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft
}

@-webkit-keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight
}

@-webkit-keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp
}

.jarallax {
    position: relative;
    z-index: 0
}

.jarallax>.jarallax-img {
    position: absolute;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1
}

.swiper-container-no-flexbox .swiper-slide {
    float: left
}

.swiper-container-vertical>.swiper-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.swiper-container-multirow>.swiper-wrapper {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.swiper-container-free-mode>.swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto
}

.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
}

.swiper-slide-invisible-blank {
    visibility: hidden
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto
}

.swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transition-property: height, -webkit-transform;
    transition-property: height, -webkit-transform;
    -o-transition-property: transform, height;
    transition-property: transform, height;
    transition-property: transform, height, -webkit-transform
}

.swiper-container-3d {
    -webkit-perspective: 1200px;
    perspective: 1200px
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10
}

.swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal>.swiper-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical>.swiper-wrapper {
    -ms-touch-action: pan-x;
    touch-action: pan-x
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    left: 10px;
    right: auto
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    right: 10px;
    left: auto
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-lock {
    display: none
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: .3s opacity;
    -o-transition: .3s opacity;
    transition: .3s opacity;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10
}

.swiper-pagination.swiper-pagination-hidden {
    opacity: 0
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%
}

.swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    -webkit-transform: scale(.33);
    -ms-transform: scale(.33);
    transform: scale(.33);
    position: relative
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    -webkit-transform: scale(.66);
    -ms-transform: scale(.66);
    transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    -webkit-transform: scale(.33);
    -ms-transform: scale(.33);
    transform: scale(.33)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    -webkit-transform: scale(.66);
    -ms-transform: scale(.66);
    transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    -webkit-transform: scale(.33);
    -ms-transform: scale(.33);
    transform: scale(.33)
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: .2
}

button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff
}

.swiper-container-vertical>.swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0)
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 8px
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    -webkit-transition: .2s top, .2s -webkit-transform;
    transition: .2s top, .2s -webkit-transform;
    -o-transition: .2s transform, .2s top;
    transition: .2s transform, .2s top;
    transition: .2s transform, .2s top, .2s -webkit-transform
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    white-space: nowrap
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    -webkit-transition: .2s left, .2s -webkit-transform;
    transition: .2s left, .2s -webkit-transform;
    -o-transition: .2s transform, .2s left;
    transition: .2s transform, .2s left;
    transition: .2s transform, .2s left, .2s -webkit-transform
}

.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    -webkit-transition: .2s right, .2s -webkit-transform;
    transition: .2s right, .2s -webkit-transform;
    -o-transition: .2s transform, .2s right;
    transition: .2s transform, .2s right;
    transition: .2s transform, .2s right, .2s -webkit-transform
}

.swiper-pagination-progressbar {
    background: rgba(0, 0, 0, .25);
    position: absolute
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top
}

.swiper-container-horizontal>.swiper-pagination-progressbar,
.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0
}

.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical>.swiper-pagination-progressbar {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0
}

.swiper-pagination-white .swiper-pagination-bullet-active {
    background: #fff
}

.swiper-pagination-progressbar.swiper-pagination-white {
    background: rgba(255, 255, 255, .25)
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
    background: #fff
}

.swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000
}

.swiper-pagination-progressbar.swiper-pagination-black {
    background: rgba(0, 0, 0, .25)
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
    background: #000
}

.swiper-pagination-lock {
    display: none
}

.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, .1)
}

.swiper-container-horizontal>.swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%
}

.swiper-container-vertical>.swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%
}

.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    left: 0;
    top: 0
}

.swiper-scrollbar-cursor-drag {
    cursor: move
}

.swiper-scrollbar-lock {
    display: none
}

.swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.swiper-zoom-container>canvas,
.swiper-zoom-container>img,
.swiper-zoom-container>svg {
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.swiper-slide-zoomed {
    cursor: move
}

.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    animation: swiper-preloader-spin 1s steps(12, end) infinite
}

.swiper-lazy-preloader:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat
}

.swiper-lazy-preloader-white:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E")
}

@-webkit-keyframes swiper-preloader-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes swiper-preloader-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out
}

.swiper-container-fade .swiper-slide {
    pointer-events: none;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity
}

.swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-container-cube {
    overflow: visible
}

.swiper-container-cube .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
    visibility: hidden;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 100%;
    height: 100%
}

.swiper-container-cube .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next+.swiper-slide,
.swiper-container-cube .swiper-slide-prev {
    pointer-events: auto;
    visibility: visible
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0
}

.swiper-container-flip {
    overflow: visible
}

.swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1
}

.swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.swiper-container-coverflow .swiper-wrapper {
    -ms-perspective: 1200px
}

/* section started desktop */

@media (min-width: 1200px) {
    .section.started .started-content .logo {
        top: -11.5vh;
        left: -18.5vw;
        width: 56vw;
        height: 80vh;
    }
    .section.started.personal .started-content .h-title {
        margin-left: 0;
        margin-top: 0;
        left: 50vw;
        top: 60vh;
        bottom: auto;
    }
}